@import '../../node_modules/@fullcalendar/core/main.css';
@import '../../node_modules/@fullcalendar/daygrid/main.css';
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-treegrid/styles/material.css';

* {
    font-family: Roboto !important;
}
/*highcharts-chart, .highcharts-container, .highcharts-container svg {
    height: 100% !important;
    width: 100% !important;
    display: inline-block;
    vertical-align: middle;
}*/
html {
    transition: all 1s;
}

.TextOurGrey {
    color: #889098 !important;
}

img.imagelogo {
    width: auto !important;
    height: inherit !important;
    /* max-height: 21px !important; */
    max-width: 160px !important;
    line-height: 40px !important;
}

html[dir="rtl"] * {
    text-align: right;
}

html[dir="rtl"] .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-leftTop > ul.ant-menu.ant-menu-sub.ant-menu-vertical > li.ant-menu-item.MenuItemsSub.BonusMenuTitle {
    padding-right: 30px !important;
}

svg.svg-inline--fa.iconShortcuts {
    color: #00a0df !important;
    font-size: 40px;
}

svg.svg-inline--fa.fa-question-circle.questionIconTemplate {
    font-size: 22px;
}

.ant-tabs.ant-tabs-bottom.TabPools.ant-tabs-line > .ant-tabs-bar.ant-tabs-bottom-bar {
    text-align: center !important;
}

    .ant-tabs.ant-tabs-bottom.TabPools.ant-tabs-line > .ant-tabs-bar.ant-tabs-bottom-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > div > .ant-tabs-tab-active.ant-tabs-tab {
        background: #f5f6fa !important;
        color: #889098 !important;
    }

    .ant-tabs.ant-tabs-bottom.TabPools.ant-tabs-line > .ant-tabs-bar.ant-tabs-bottom-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        background: transparent !important;
        background-color: transparent !important;
    }

    .ant-tabs.ant-tabs-bottom.TabPools.ant-tabs-line > .ant-tabs-bar.ant-tabs-bottom-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > div > .ant-tabs-tab {
        font-size: 30px !important;
        color: #889098 !important;
        opacity: 1 !important;
        padding: 20px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

        .ant-tabs.ant-tabs-bottom.TabPools.ant-tabs-line > .ant-tabs-bar.ant-tabs-bottom-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > div > .ant-tabs-tab.ant-tabs-tab-disabled {
            cursor: default !important;
            font-size: 45px !important;
            padding: 0px !important;
            padding-left: 30px !important;
            padding-right: 30px !important;
        }

.ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachPool {
    min-height: 600px;
}

.loader.default {
    border-color: #00a0df transparent transparent !important;
    width: 85px !important;
    height: 85px !important;
}

.loader-container.inline {
    z-index: 3000;
    content: '';
    position: fixed !important;
    right: 13% !important;
    top: 40% !important;
    width: 100px !important;
    height: 100px !important;
    border-width: 4px !important;
    border-radius: 50% !important;
}

.loader-container.centered {
    z-index: 3000;
    content: '';
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px !important;
    height: 100px !important;
    border-width: 4px !important;
    border-radius: 50% !important;
}

.backgroundHovered:hover {
    background: #f5f6fa !important;
}

.templatePool.e-control.e-richtexteditor.e-lib.e-rte-tb-expand > .e-rte-content {
    height: 82vh !important;
}

    .templatePool.e-control.e-richtexteditor.e-lib.e-rte-tb-expand > .e-rte-content > .e-content.e-lib.e-keyboard {
        background: #f5f6fa;
    }

        .templatePool.e-control.e-richtexteditor.e-lib.e-rte-tb-expand > .e-rte-content > .e-content.e-lib.e-keyboard > div .container > .row.py-5.align-items-center, .e-richtexteditor.e-rte-tb-expand > .e-rte-content > .e-content.e-lib.e-keyboard {
            padding: 0px !important;
        }

.templatePool.e-control.e-richtexteditor.e-lib.e-rte-tb-expand {
    margin-left: 10px !important;
    border: 1px solid transparent !important;
}

.ant-input-number-handler-wrap {
    display: none !important;
}

.innerAddbtn {
    box-shadow: 0 3.2px 7.2px 0 rgba(0,0,0,.18), 0 0.6px 1.8px 0 rgba(0,0,0,.11);
    padding: 10px;
}

.GroupScrollHorizontal {
    height: 86vh !important;
    width: 100% !important;
    overflow: hidden !important;
    overflow-x: auto !important;
}

.GroupScrollVertical {
    padding: 5px;
    width: 315px !important;
    height: calc(100vh - 115px);
    overflow: hidden !important;
    overflow-y: auto !important;
}

.d-block.GroupScrollVertical.col {
    min-width: 325px !important;
    max-width: 325px !important;
}

.dataTables_scrollBody {
    overflow-x: auto !important;
}

.GroupScrollVertical::-webkit-scrollbar {
    width: 8px !important;
}

body .GroupScrollHorizontal::-webkit-scrollbar {
    height: 10px !important;
}

.innerAddbtn.AddGroupBtn.cursor-pointer:hover {
    box-shadow: 0 6.4px 14.4px 0 rgba(0,0,0,.18), 0 1.2px 3.6px 0 rgba(0,0,0,.11);
}

input.GroupInput:focus, input.GroupInput2:focus,
input.GroupInput:active, input.GroupInput2:active {
    border: 1px solid transparent !important;
    outline: unset !important;
    border-color: transparent !important;
    box-shadow: 0 3.2px 7.2px 0 rgba(0,0,0,.18), 0 0.6px 1.8px 0 rgba(0,0,0,.11) !important;
    border-bottom: 2px solid #00a0df !important;
}

table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}

    table tr {
        padding: .35em;
    }

    table th,
    table td {
        padding: .625em;
        text-align: left;
    }

/*table td {
        white-space: nowrap;
        overflow-wrap: initial !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }*/

.dataTables_scrollHead {
    overflow: hidden !important;
    overflow-x: hidden !important;
}

.dataTables_scrollBody {
    overflow: hidden !important;
    overflow-x: auto !important;
    max-height: calc(70vh - 80px);
    min-height: 100% !important;
    overflow-y: auto !important;
}

.scrollable-table {
    width: 100% !important;
    overflow-x: auto !important;
}

.datesPromotions {
    padding: 10px !important;
}

input.GroupInput:hover, input.GroupInput2:hover {
    border: 1px solid transparent !important;
    outline: unset !important;
    border-color: transparent !important;
    box-shadow: unset !important;
    background: transparent !important;
}

.datesPromotions:hover {
    background: #f3f2f1 !important;
    /* padding: 10px !important; */
}

input.GroupInput {
    border: 1px solid transparent !important;
    outline: unset !important;
    border-color: transparent !important;
    box-shadow: unset !important;
    font-size: 16px !important;
    color: black !important;
    width: 230px;
    padding-left: 7px;
}

input.GroupInput2 {
    border: 1px solid transparent !important;
    outline: unset !important;
    border-color: transparent !important;
    box-shadow: unset !important;
    width: 50px;
    padding-right: 0px;
}

.ModelPaper.m10mr10.FullSreenModal {
    width: 86% !important;
    overflow: hidden !important;
    overflow-y: auto;
    overflow-x: auto;
}

.paymentCarousel {
    width: 90% !important;
}

svg.svg-inline--fa.fa-angle-double-left.fa-w-14.maximizeIcon {
    font-size: 20px;
    color: #889098 !important;
}

button.ButtonsModal.MngBtn.btn.btn-secondary,
button.ButtonsModal.MngBtn.btn.btn-secondary:hover,
button.ButtonsModal.MngBtn.btn.btn-secondary:focus,
button.ButtonsModal.MngBtn.btn.btn-secondary:active {
    height: 100% !important;
    min-height: 30px !important;
}

button.tblBtn.disabledAction,
button.tblBtn.disabledAction:focus,
button.tblBtn.disabledAction:active,
button.tblBtn.disabledAction:hover {
    background: transparent !important;
    border-radius: 5px !important;
    padding: 6px;
    color: #889098 !important;
    border: 1px solid #889098 !important;
    cursor: not-allowed !important;
}

button.ActionButton.tblBtn {
    background: transparent !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    padding: 6px;
    color: #00a0df !important;
}

    button.ActionButton.tblBtn:hover,
    button.ActionButton.tblBtn:focus,
    button.ActionButton.tblBtn:active {
        background: #00a0df !important;
        border: 1px solid #00a0df !important;
        border-radius: 5px !important;
        padding: 6px;
        color: white !important;
    }

button.filterDropdown {
    color: #889098 !important;
    font-size: 16px !important;
    background: #f5f6fa !important;
    border-color: transparent;
    border-color: transparent !important;
    padding: 6px !important;
    height: 35px !important;
    border-radius: 5px;
    line-height: 20px !important;
}

select.SelectHTML, select.SelectHTML:focus, select.SelectHTML:active, select.SelectHTML:hover {
    margin-bottom: 15px !important;
    border: 1px solid #ddd !important;
    height: 35px !important;
    border-radius: 5px !important;
    width: 100% !important;
    background: transparent !important;
    border-color: #ddd !important;
    color: #889098 !important;
}

.w218 {
    width: 218px !important;
}

li.filterDropdown.dropdown.nav-item > a.dropdown-toggle.nav-link {
    padding: 0px !important;
    margin: 0px !important;
}

.MuiPaper-root.MuiExpansionPanel-root.CommentPannel.m10.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    box-shadow: unset !important;
}

.e-dropdown-btn .e-caret::before, .e-dropdown-btn.e-btn .e-caret::before {
    content: '\e969';
}

td {
    color: #889098 !important;
    font-size: 16px !important;
}

.dropbtn {
    color: #889098;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    background: transparent !important;
}

    /* Dropdown button on hover & focus */
    .dropbtn:hover, .dropbtn:focus {
        background-color: transparent;
    }

/* The container <div> - needed to position the dropdown content */
.dropdown, .dropdownLanguage {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 115px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 10px;
    top: 45px;
}

    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

        /* Change color of dropdown links on hover */
        .dropdown-content a:hover {
            background-color: transparent !important;
        }

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
    display: block;
}

span.ActivityBodyText.font-14 {
    padding: 10px;
}

button.ButtonsModal.btn.btn-secondary, button.ButtonsModal, button.ButtonsModal.btn.btn-primary {
    height: 35px !important;
}

label.d-block.CheckboxTitle.ant-checkbox-wrapper {
    padding: 0px !important;
}

.p-multiselect.p-component.ShowSearch2.d-block.ShowSearch.NoBordersFilter {
    border-color: transparent !important;
}

.ThemeDropDownMenu2.dropdown-menu.dropdown-menu-right.show {
    padding: 10px;
    width: 225px !important;
}

.p-multiselect.p-component.ShowSearch2:hover {
    border: 1px solid #ddd !important;
}

.p-multiselect.p-component.ShowSearch2 > .p-multiselect-trigger > span.p-multiselect-trigger-icon.pi.pi-chevron-down.p-c {
    color: #889098 !important;
}

.EachLeftColumn.col-md-2 {
    padding-left: 10px !important;
    padding-right: 0px !important;
    background: white !important;
}

input.filterInput.filterInputinPages.form-control-lg.form-control::-webkit-input-placeholder, input.filterInput.filterInputinPages.form-control-lg.form-control {
    color: #889098 !important;
}

.ClientInfoTab {
    width: 98% !important;
    margin-top: 10px !important;
    margin-right: 10px !important;
    padding: 20px !important;
    background: white !important;
}

.ant-tabs.ant-tabs-left.TabsEachClient.ant-tabs-vertical.ant-tabs-line > .ant-tabs-bar.ant-tabs-left-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: transparent !important;
    background-color: transparent !important;
}
/*.ant-tabs.ant-tabs-left.TabsUser.ant-tabs-vertical.ant-tabs-line > .ant-tabs-bar.ant-tabs-left-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: transparent !important;
    background-color: transparent !important;
}*/
.VerticalDivider.ant-divider.ant-divider-vertical {
    margin-left: 20px !important;
    color: black !important;
    height: 38px;
    width: 3px;
    opacity: 1 !important;
}

span.ant-avatar.avatar2.ClientAvatar.mb-2.ant-avatar-circle > span.ant-avatar-string {
    margin-top: 35px;
}

/*.e-control, .e-control [class^='e-'], .e-control [class*=' e-'] {
    z-index: 1 !important;
}

    .e-control, .e-control [class^='e-']:not(.e-richtexteditor), .e-control [class*=' e-'] {
        z-index: 1 !important;
    }

        .e-control.e-richtexteditor .e-rte-content .e-content, .e-control.e-richtexteditor .e-source-content .e-control.e-content {
            z-index: 2 !important;
        }*/

span.ant-avatar.avatar2.ClientAvatar.mb-2.ant-avatar-circle {
    width: 130px;
    height: 130px;
    background: #889098 !important;
    font-size: 60px;
    font-weight: 400 !important;
}

button.BackButton.btn.btn-secondary, button.BackButton.btn.btn-secondary:hover, button.BackButton.btn.btn-secondary:active, button.BackButton.btn.btn-secondary:focus {
    background: transparent !important;
    background-color: transparent !important;
    border: unset !important;
    color: #889098 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}

a.filterDropDown.nav-link {
    display: unset !important;
}

.p-multiselect.p-component.ShowSearch2 {
    background: #f5f6fa !important;
    border: 1px solid #ddd !important;
    color: #889098 !important;
    margin-bottom: 10px !important;
    height: 35px !important;
    width: 210px;
}

    .p-multiselect.p-component.ShowSearch2 > .p-multiselect-label-container > label.p-multiselect-label {
        color: #889098 !important;
    }

input.SettingsInputs.d-block.form-control-lg.form-control {
    border-bottom: #ddd !important;
    border-radius: 4px !important;
    height: 35px !important;
    line-height: 30px !important;
    margin-left: 10px;
    width: 95% !important;
    margin-bottom: 10px;
    border: 1px solid #ddd !important;
}

    input.SettingsInputs.d-block.form-control-lg.form-control::-webkit-input-placeholder, span.ant-input-password.SettingsInputs.ant-input-affix-wrapper > input.ant-input::-webkit-input-placeholder {
        color: #889098 !important;
        font-size: 16px !important;
    }

span.ant-input-password.SettingsInputs.ant-input-affix-wrapper > input.ant-input {
    height: 30px !important;
}

html:lang(ar) * {
    font-family: Cairo !important;
}

span.ant-input-password.ant-input-affix-wrapper > input.ant-input {
    border-radius: 0px !important;
}

html[dir="rtl"] p.versionText {
    text-align: center !important;
}

html [dir="rtl"] .fa-chevron-left:before {
    content: "\f054" !important;
}

html[dir="rtl"] svg.svg-inline--fa.fa-chevron-circle-left, html[dir="rtl"] svg.svg-inline--fa.fa-chevron-circle-right {
    transform: rotate(180deg);
}

html[dir="rtl"] .fa-chevron-right:before {
    content: "\f053" !important;
}

html[dir="rtl"] div.dataTables_wrapper div.dataTables_paginate {
    margin-left: 200px;
}

html[dir="rtl"] .text-center, html[dir="rtl"] div.text-center {
    text-align: center !important;
}

html[dir="rtl"] .align-self-center {
    align-self: center !important;
}

html[dir="rtl"] .align-items-center {
    align-items: center !important;
}

.fa, .far, .fas, .fab,
html[dir="rtl"] .fa, html[dir="rtl"] .far, html[dir="rtl"] .fas, html[dir="rtl"] .fab, html[dir=rtl] .fal {
    font-family: "Font Awesome 5 Pro" !important;
}

.fal, .far, .fad, html[dir="rtl"] .fal, html[dir="rtl"] .far, html[dir="rtl"] .fad {
    font-family: "Font Awesome 5 Pro" !important;
}

i.fas.fa-long-arrow-alt-down {
    font-family: "Font Awesome 5 Free" !important;
}

button.ButtonsModal.mb-0.btn.btn-secondary, button.ButtonsModal.mb-0.btn.btn-secondary:hover, button.ButtonsModal.mb-0.btn.btn-secondary:active, button.ButtonsModal.mb-0.btn.btn-secondary:focus {
    margin-bottom: 0px !important;
}

.fa-house-user:before {
    content: "\e065" !important;
}

.fa-equals:before {
    content: "\003D" !important;
}

html[dir="rtl"] td.p-organizationchart-line-left {
    padding-right: 13px;
    padding-left: unset !important
}



html[dir="rtl"] button.ButtonsApp2.w100p.btn.btn-secondary {
    text-align: center;
}

html[dir="rtl"] .ant-drawer-left .ant-drawer-content-wrapper, .ant-drawer-right .ant-drawer-content-wrapper {
    left: unset !important;
    height: 100%;
    right: 0px !important;
}

html[dir="rtl"] .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-leftTop {
    left: unset !important;
    right: 70px !important;
    width: 250px;
}

html[dir="rtl"] .ContactusColor2 {
    margin-right: 7px !important;
    margin-left: unset !important;
}

html[dir="rtl"] ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > li.ant-menu-item.DashboardMenuTitle > a.titleMenu > span.titleMenu.titleClienMenu > span.DashboardMenu {
    margin-right: 35px;
}

html[dir="rtl"] i.fas.fa-chevron-double-left.maximizeIcon.bottomMenu40.menuItemsToggle.downMenu.left230.cursor-pointer.mb-1 {
    transform: rotate( 180deg );
}

html[dir="rtl"] i.fas.fa-chevron-double-left.maximizeIcon.bottomMenu40.menuItemsToggle.downMenu.transform180.bottom-55 {
    transform: rotateX( 180deg );
}

html[dir="rtl"] .divRight {
    float: left !important;
    left: 0;
    top: 0;
}

.mt11 {
    margin-top: 11px !important;
}

.ant-form-item label {
    overflow: hidden !important;
    overflow-wrap: break-word !important;
    text-align: left !important;
    font-size: 16px !important
}

html[dir="rtl"] .ant-form-item label {
    text-align: right !important;
}

.LabelWhite, .menuBack {
    margin-left: 30px !important;
    color: white !important;
    font-size: 16px !important;
    padding: 10px !important;
    cursor: pointer !important;
}

html[dir="rtl"] .ant-calendar-range-picker-input {
    text-align: center !important;
}

html[dir="rtl"] .ant-calendar-picker-clear, .ant-calendar-picker-icon {
    left: 12px;
}

html[dir="rtl"] .p-multiselect-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
    right: -110px !important;
}

html[dir="rtl"] .offset-md-2 {
    margin-right: 16.666667%;
    margin-left: 0;
}

html[dir="rtl"] .dropdown-menu.show {
    left: 0px !important;
}

html[dir="rtl"] .divLeft {
    float: right !important;
    left: 0;
    top: 0;
}

input.editedInput.form-control-lg.form-control:disabled {
    background: transparent !important;
}

html[dir="rtl"] .mr-4, html[dir="rtl"] .mx-4 {
    margin-left: 1.5rem !important;
}

html[dir="rtl"] .backiconGet {
    margin-right: 8px;
}

html[dir="rtl"].ml-3, html[dir="rtl"] .mx-3 {
    margin-right: 1rem !important;
}

html[dir="rtl"] .spinIndicator2 {
    left: 17% !important;
    content: '';
    right: unset !important;
    position: fixed !important;
    top: 50% !important;
    width: 40px !important;
    height: 40px !important;
    border-width: 4px !important;
    border-radius: 50% !important;
}

html[dir="rtl"] button.customBtn.btn.btn-secondary {
    text-align: center;
}

html[dir="rtl"] .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab2LTR {
    margin-right: -100% !important;
}

html[dir="rtl"] .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab3LTR {
    margin-right: -200% !important;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab2LTR {
    margin-right: -100% !important;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab3LTR {
    margin-right: -200% !important;
}

html[dir="rtl"] .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab4LTR {
    margin-right: -300% !important;
}

html[dir="rtl"] .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab5LTR {
    margin-right: -400% !important;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab4LTR {
    margin-right: -300% !important;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab5LTR {
    margin-right: -400% !important;
}

html[dir="rtl"] .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab6LTR {
    margin-right: -500% !important;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > .ant-tabs-tabpane.ant-tabs-tabpane-active.Tab6LTR {
    margin-right: -500% !important;
}

html[dir="rtl"] .ant-tabs.ant-tabs-top.ArabicTabs.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
}

.ant-tabs.ant-tabs-top.ArabicTabs.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: block !important;
}

html[dir="rtl"] .table > tbody > tr > td, html[dir="rtl"] table.dataTable thead > tr > th {
    text-align: right !important;
}

html[dir="rtl"] div.dataTables_wrapper div.dataTables_length select {
    text-align-last: left !important;
}

html[dir="rtl"] .mr-2, html[dir="rtl"] .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0px !important;
}

html[dir="rtl"] .mr-3, html[dir="rtl"] .mx-3 {
    margin-left: 1rem !important;
    margin-right: 0px !important;
}

html[dir="rtl"] .ant-menu-inline .ant-menu-submenu-title {
    padding-left: 34px !important;
    padding-right: 15px !important;
}

html[dir="rtl"] .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, li.MobileMenu.ant-menu-item-group.ant-menu-item-selected {
    background-color: #c2c2c2 !important;
    border-right: 3px solid transparent !important;
    border-left: 3px solid transparent !important;
    color: #000000 !important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    font-size: 16px !important;
}

html[dir="rtl"] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[dir="rtl"] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[dir="rtl"] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[dir="rtl"] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    left: 16px;
    right: unset !important;
}

.pi,
html[dir="rtl"] .pi {
    font-family: 'primeicons' !important;
}

.e-icons,
html[dir="rtl"] .e-icons {
    font-family: "e-icons" !important;
}

html[dir="rtl"] .ant-empty.ant-empty-normal, html[dir="rtl"] .ant-empty-image, html[dir="rtl"] p.ant-empty-description {
    text-align: center !important;
}

html[dir="rtl"] li.p-multiselect-item.p-highlight, html[dir="rtl"] li.p-multiselect-item {
    text-align: right !important;
}

html[dir="rtl"] .ant-calendar-today .ant-calendar-date, html[dir="rtl"] span.ant-calendar-my-select {
    text-align: center !important;
}

html[dir="rtl"] .floatRight {
    float: left !important;
}

html[dir="rtl"] .floatLeft, html[dir="rtl"].float-Left {
    float: right !important;
}

html[dir="rtl"] .left230 {
    right: 240px;
}

html[dir="rtl"] .ant-tabs-extra-content {
    float: left !important;
}

html[dir="rtl"] .ant-tabs .ant-tabs-left-bar {
    float: right !important;
}

html[dir="rtl"] .mr-1, html[dir="rtl"] .mx-1 {
    margin-left: 0.25rem !important;
}

html[dir="rtl"] .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    right: 0px !important;
}

html[dir="rtl"] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: right !important;
}

html[dir="rtl"] label.ThemesText {
    text-align: center !important;
}

html[dir="rtl"] .mr18 {
    margin-left: 18px;
}

html[dir="rtl"] .ButtonsBottom3 {
    margin-right: 0px !important;
    margin-left: 200px !important;
}

html[dir="rtl"] .ml4 {
    margin-right: -4px !important;
}

.ml4 {
    margin-left: -4px !important;
}

html[dir="rtl"] .ml-19 {
    margin-right: -19px !important;
}

html[dir="rtl"] .ml-28 {
    margin-right: -20px !important;
}

html[dir="rtl"] .ml7 {
    margin-right: 7px !important;
}

html[dir="rtl"] .ml-2, html[dir="rtl"] .mx-2 {
    margin-right: 0.5rem !important;
}

html[dir="rtl"] a.titleMenu > span, html[dir="rtl"] span.titleMenu {
    margin-right: 0px !important;
}

html[dir="rtl"].transform180 {
    transform: rotateX(180deg) !important;
}

html[dir="rtl"] .ml-auto {
    margin-right: auto !important;
}

html[dir="rtl"] .ml-auto {
    margin-left: 0px !important;
}

html[dir="rtl"] .mr-auto {
    margin-right: 0px !important;
}

html[dir="rtl"] .mr-auto {
    margin-left: auto !important;
}

html[dir="rtl"] i.text-center.iconCards2 {
    margin-left: 0px !important;
}

html[dir="rtl"] span.SearchInput.ant-input-affix-wrapper {
    margin-left: 10px;
}

html[dir="rtl"] .dropdown-menu-right {
    left: 0;
    right: auto;
}

html[dir="rtl"] span.float-right.textblue2 {
    float: left !important;
}

svg.svg-inline--fa.fa-circle.blueIcon {
    color: #00a0df !important;
}

.textblue2 {
    color: #00a0df !important;
}

html[dir="rtl"] .float-right {
    float: left !important;
}

html[dir="rtl"] .mx-auto.d-table.h-100.autoDashboard.row {
    margin-left: auto !important;
}

html [dir="rtl"] .ModelPaper, html[dir="rtl"] .ModelPaper1, html[dir="rtl"] .ModelPaper2, html[dir="rtl"] .ModelPaper3, html[dir="rtl"] .ModelPaper4, html[dir="rtl"] .ModelColumns {
    float: left;
}

.ModelColumns {
    width: 20% !important;
}

html[dir="rtl"] .mx-auto.d-table.h-100.col-sm-10.col-md-6.col-lg-3 {
    margin-left: auto !important;
}

html[dir="rtl"] img.h14.ml-2.mt-1.mr-2.cursor-pointer {
    transform: rotate(180deg);
}

html[dir="rtl"] button.loginBtn.btn.btn-secondary {
    text-align: center !important;
}

html[dir="rtl"] button.ButtonsApp.btn.btn-secondary {
    text-align: center !important;
}
/*html[dir="rtl"] .ant-drawer-content-wrapper {
    left: 0 !important;
    right: unset !important;
}
*/
html[dir="rtl"] .ant-steps-item-icon {
    text-align: center !important;
}

html[dir="rtl"] .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    right: 18%;
    left: unset !important;
}

html[dir="rtl"] .ant-steps-item-title::after {
    right: 100%;
    left: unset !important;
}

html[dir="rtl"] .ModelPaper2.ModalPopBody.m10mr10.ModalLogin.ModalAllocation {
    width: 24% !important;
    margin-left: 34% !important;
}

html[dir="rtl"] .float-left {
    float: right !important;
}

html[dir="rtl"] .ModelPaper2.ModalPopBody {
    margin-left: 30% !important;
}

html[dir="rtl"] .ant-drawer-left, html[dir="rtl"] .ant-drawer-right {
    top: unset;
}

    html[dir="rtl"] .ant-drawer-left.ant-drawer-open.no-mask, html[dir="rtl"] .ant-drawer-right.ant-drawer-open.no-mask {
        top: 0% !important;
    }

html[dir="rtl"] .ant-drawer.ant-drawer-left.ant-drawer-open.MobileDrawer {
    top: 0px !important;
    right: 0px !important;
}
/*html[dir="rtl"] .ant-tabs-ink-bar {
    right: 0;
    left: unset !important;
}*/
/* html[dir="rtl"] .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        transform: translate3d(-112px, 0px, 0px) !important;
    }
*/
/*html[dir="rtl"] .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content {
    margin-right: -100% !important;
    margin-left: unset !important;
}*/
html, body {
    position: relative !important;
    overflow: hidden !important;
    font-family: Roboto !important;
}

label.ThemesText {
    display: block !important;
}

h3.LabelCreate2.TabsTitle {
    color: #889098 !important;
    font-size: 16px !important;
}

h2.TitleModal, h1, h2, h3, h4, h5, h1.LabelCreate2, h2.LabelCreate2, h3.LabelCreate2, h4.LabelCreate2, h5.LabelCreate2, h1.PageNotFound {
    font-family: Roboto !important;
}

    h4.LabelCreate2 {
        font-size: 18px !important;
    }

.newExternalForm {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
}

.containerMsg {
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
    padding: 80px 160px;
}

.containerMsg2 {
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
    padding: 80px 160px;
}

span.ant-avatar.avatar.avatarSignals.ant-avatar-square {
    background: #4FB1D7;
    border: 1px solid #00a0df;
    width: 44px;
    height: 44px;
}

img.circularLogo {
    height: 80px;
}

.MuiButton-label {
    text-transform: none !important;
    font-family: Roboto !important;
    font-size: 13px;
    font-weight: 500 !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.ButtonUITemplate {
    margin-bottom: 10px !important;
}
/*#region Datatable Grouping*/
/*group command grooup hover same css*/
tr .group, tr.group:hover {
    background-color: #ddd; /*important removed as may lead to problems */
}

.ant-card.dashboardCards.overflowxCards.cardTabs.ant-card-bordered > .ant-card-body {
    max-height: 400px;
}

.MuiPaper-root.MuiExpansionPanel-root.AppsExpansion.EmailExpansion.Mui-expanded.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    width: 300px;
    max-height: 1400px !important;
}

.ant-drawer-content-wrapper {
    max-width: 260px !important;
}

input.CopyText.form-control {
    background-color: #dedede !important;
    border: #dedede !important;
    font-weight: 500 !important;
    height: 35px !important;
    padding: 10px !important;
    width: 200px;
    overflow: hidden !important;
    margin-bottom: 10px;
    border-radius: 5px !important;
    line-height: 15px;
    font-size: 14px !important;
}

.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__control.css-yk16xz-control,
.react-select.cursor-pointer.AsyncMultiSelect.__control.css-yk16xz-control,
.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__control.css-yk16xz-control.__control--isfocused.__control--menu-is-open.css-1pahdxg-control,
.react-select.cursor-pointer.AsyncMultiSelect.__control.css-yk16xz-control.__control--isfocused.__control--menu-is-open.css-1pahdxg-control,
.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.css-1pahdxg-control,
.react-select.cursor-pointer.AsyncMultiSelect.css-1pahdxg-control {
    max-height: 80px !important;
    height: unset !important;
    min-height: 30px !important;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__value-container.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__value-container--is-multi.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__value-container--has-value.css-1hwfws3,
.react-select.cursor-pointer.AsyncMultiSelect.__value-container.react-select.cursor-pointer.AsyncMultiSelect.__value-container--is-multi.react-select.cursor-pointer.AsyncMultiSelect.__value-container--has-value.css-1hwfws3,
.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__value-container.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__value-container--is-multi.react-select.cursor-pointer.LargeSelect.AsyncMultiSelect.__value-container--has-value.css-g1d714-ValueContainer,
.react-select.cursor-pointer.AsyncMultiSelect.__value-container.react-select.cursor-pointer.AsyncMultiSelect.__value-container--is-multi.react-select.cursor-pointer.AsyncMultiSelect.__value-container--has-value.css-g1d714-ValueContainer {
    max-height: fit-content !important;
    height: unset !important;
    min-height: 30px !important;
    overflow: hidden !important;
}

.Select-menu-outer {
    --accent: orange !important;
}

.Select-arrow {
    --accent: green !important;
}

.ant-drawer-body {
    padding: 0px !important;
}

.rccs__card--unknown > div {
    background: linear-gradient(25deg, #00a0df, #00a0df) !important;
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108)) !important;
}

img.iconAppForm {
    height: 80px;
}

.mobilMenuLeftsidebar {
    display: none !important;
}

.ant-form-explain.text-danger {
    font-weight: 400 !important;
}

.leftsidebarScreen {
    display: contents !important;
}

.hideMobile, .hideDivImage {
    display: initial !important;
}

.row.py-5.mt100.align-items-center {
    margin-top: 100px !important;
}

.hideFilter {
    display: flex !important;
}

.showMobile .showText {
    display: none !important;
}

.hideText {
    display: initial !important;
}

.mobilMenuLeftSideBarMobile {
    display: none !important;
}

.p-multiselect.p-component.multiChildren {
    width: 100% !important;
}

ul.ant-menu.mt5.blue-bg.MenuSettings.MenuSettingsMobile.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > .ant-menu-item,
ul.ant-menu.mt5.blue-bg.MenuSettings.MenuSettingsMobile.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
ul.ant-menu.mt5.blue-bg.MenuSettings.MenuSettingsMobile.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
ul.ant-menu.mt5.blue-bg.MenuSettings.MenuSettingsMobile.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0px 10px !important;
}

.MenuSettings.MenuSettingsMobile > li.ant-menu-item.ant-menu-item-selected {
    width: 35px !important;
}

ul.ant-menu.mt5.blue-bg.MenuSettings.MenuSettingsMobile.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
    width: 35px !important;
    margin-top: -5px !important;
}

.h100vh {
    height: 100vh !important;
}

.has-warning .ant-form-explain, .has-warning .ant-form-split, .has-warning.has-feedback .ant-form-item-children-icon {
    color: #00a0df !important;
}

div#imageRTE_toolbar {
    z-index: 1 !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    box-shadow: unset !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
    background: transparent !important;
}

.e-content, .e-chart-scroll-container.e-content {
    /*min-height: 750px !important;*/
    overflow-y: auto;
}

.ModelPaper2.ModalPopBody.m10mr10.ModalLogin.ModalAllocation {
    width: 24% !important;
    margin-right: 34% !important;
}

.e-grid {
    font-family: Roboto !important;
}

.e-gantt .e-gantt-chart .e-timeline-top-header-cell .e-gantt-top-cell-text, .e-gantt .e-gantt-chart .e-timeline-single-header-cell .e-gantt-top-cell-text {
    text-align: center !important;
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
    stroke: #00a0df !important;
}

.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
    background-color: #00a0df !important;
    border: 1px solid #00a0df !important;
}

.w100p.filterInsideTab {
    position: absolute;
    width: 46% !important;
}

.filterBar.filterSidebar.filterSettings {
    margin: 0px !important;
    padding: 3px !important;
    overflow: hidden;
}

p.clickLinkText:hover {
    text-decoration: underline;
    font-size: 18px;
}

body {
    overflow: hidden !important;
    font-family: Roboto !important;
}

    body ::-webkit-scrollbar-thumb {
        border-radius: 0px !important;
    }

label.loginText {
    color: #051C2C !important;
    font-size: 24px !important;
    font-weight: 500 !important;
}

a.secondaryLoginText, .secondaryLoginText {
    font-size: 15px !important;
    color: #00a0df !important;
}

img.logoSignin {
    height: 100%;
    width: 56%;
}

.insidenewExternalForm {
    padding: 60px 72px;
}

input.InputsExternal.form-control-lg.form-control {
    border-radius: 5px !important;
    height: 35px !important;
}

input.InputsExternal {
    background: transparent !important;
    border-color: #ddd !important;
    color: #889098 !important;
}

button.loginBtn.btn.btn-secondary.btn-sm {
    line-height: 30px !important;
}

.col-md-12.noPadding {
    padding: 0px !important;
}

button .ButtonsModal, button.ButtonsModal.btn.btn-primary, button.ButtonsModal2, button.ButtonsModal2.btn.btn-primary {
    font-size: 15px !important;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    font-size: inherit;
    justify-content: center;
    line-height: inherit;
    outline: none;
    overflow: visible;
    padding: 6px 12px;
    position: relative;
}

.noPadding.col-md-6 {
    padding-left: 0px !important;
}

.tagsDiv {
    display: inline-grid !important;
}

::-webkit-calendar-picker-indicator {
    display: none !important;
}

.mt-3.mb-3.ant-divider.ant-divider-horizontal.signinDivider {
    background: rgba(0, 0, 0, 0.3) !important;
}

.blackFont {
    color: black !important;
}

.mainExternal.signin.heightSighnIn {
    min-height: 95vh !important;
}

a.eachLangugae.dropdown-item:hover {
    background: transparent !important;
}

.filterBar.filterSidebar.filterSettings {
    height: 35px !important;
    width: 270px !important;
}

.iconsStyle, input#email, input#password, input.loginInput2 {
    border: 1px solid rgb(223, 225, 230) !important;
}

    .iconsStyle, input.loginInput2:focus, input.loginInput2:active, input.loginInput2:hover,
    span.ant-input-password.loginInput2.ant-input-affix-wrapper:focus,
    span.ant-input-password.loginInput2.ant-input-affix-wrapper:active {
        border: 1px solid rgb(223, 225, 230) !important;
        background-color: transparent !important;
        color: black;
        box-shadow: unset !important;
        outline: unset !important;
    }

form.credentialBox, .credentialRegisterBox, .credentialBox {
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
}

.AppformCardsHeight {
    background: #f5f6fa !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
    margin: 10px !important;
    border-radius: 5px !important;
}

    .AppformCardsHeight:hover {
        border: 4px solid #00a0df !important;
    }

.credentialRegisterBox {
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.centeredLoader {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.LoadinderSpinner {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.minWidth370 {
    min-width: 370px !important;
}

form.credentialBox, .credentialBox {
    background: white;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.EmailHeader.card-header {
    background-color: black !important;
    height: 85px !important;
}

div#emailCardBody {
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
}

.ScrollingInsidePage {
    height: calc(100vh - 330px);
    overflow-x: hidden !important;
    overflow: auto !important;
    overflow-y: auto !important;
}

input.resetEmail {
    width: 250px !important;
}


.react-select.cursor-pointer.LargeSelect.DarkSelect__control.css-1pahdxg-control {
    border-color: #ddd !important;
    background-color: transparent !important;
}

.react-select.cursor-pointer.LargeSelect.DarkSelect__control.css-yk16xz-control {
    background-color: transparent !important;
}

.MuiButtonBase-root.MuiExpansionPanelSummary-root.CommentSummary, .MuiButtonBase-root.MuiExpansionPanelSummary-root.CommentSummary {
    text-align: center;
    background: #5B5C5D !important;
    font-size: 14px !important;
}

.react-select.cursor-pointer.SelectBorders.__control.css-yk16xz-control, .react-select.cursor-pointer.SelectBorders.__control.css-1pahdxg-control {
    width: 150px;
    font-size: 14px;
    background: transparent !important;
    border: 1px solid #00a0df !important;
    border-radius: 4px !important;
    color: #00a0df !important;
}

.react-select.cursor-pointer.SelectBorders.__menu-list.css-4ljt47-MenuList {
    font-size: 14px;
}

span.react-select.cursor-pointer.__indicator-separator.css-109onse-indicatorSeparator {
    background-color: transparent;
}
/*hide arrows*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ui-fluid .fc .ui-button {
    width: auto;
}

.ant-radio-disabled + span {
    color: black;
    cursor: not-allowed;
}

.e-richtexteditor .e-rte-content .e-content, .e-richtexteditor .e-source-content .e-content {
    min-height: 100px !important;
}

.ScrollingDiv {
    height: calc(100vh - 183px);
    overflow-x: hidden !important;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.ScrollingModal {
    height: calc(100vh - 100px);
    overflow-x: hidden !important;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.ModelPaper.m10mr10.RegistrationModal {
    width: 50% !important;
    margin-right: 23% !important;
}

.MuiPaper-root.MuiExpansionPanel-root.scrollExpansion.Mui-expanded.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    height: calc(100vh - 340px);
    overflow-x: hidden !important;
    overflow: auto !important;
    overflow-y: auto !important;
}

label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
    width: 900px;
    position: inherit;
    text-align: initial;
    white-space: normal;
    word-wrap: break-word;
}

span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
    height: 130px !important;
    width: 130px !important;
    font-size: 75px;
    opacity: 0.5;
    background: #889098 !important;
}

.mr-6 {
    margin-right: 3rem !important;
}

.avatar.avatarUser > span.ant-avatar-string {
    bottom: 40% !important;
    padding-bottom: 1px;
}

input#react-select-16-input, input#react-select-23-input, input#react-select-30-input, input#react-select-32-input {
    border: transparent !important;
}

.completedText {
    text-decoration: line-through !important;
}

input#react-select-13-input {
    display: none !important;
}

textarea.commentTextArea {
    height: 70px;
    border-radius: 5px !important;
    padding: 18px;
    margin: 20px;
    margin-top: 16px;
    background: white !important;
    color: #00a0df !important;
}

nav.sticky-top.h60.toparExternal.navbar.navbar-expand {
    height: 60px !important;
}

button.eyeButton.btn.btn-secondary {
    height: 35px !important;
}

textarea.commentTextArea::-webkit-input-placeholder {
    color: #889098 !important;
}

.eyeButton {
    height: 38px;
    width: 40px;
    border-radius: 2px !important;
    padding-bottom: 8px !important;
}

textarea {
    resize: none !important;
}

.mb5 {
    margin-bottom: 6px !important;
}

p.noteText.AddCommentText {
    color: #00a0df !important;
}

::-webkit-input-placeholder {
    font-size: 14px;
}

input.emailText {
    margin-top: 17px;
    background: transparent;
    border: 1px solid transparent !important;
    opacity: 1 !important;
}

input.t-bold, textarea.t-bold {
    font-weight: 500;
}

input.textAlignLeft, textarea.textAlignLeft {
    text-align: left !important;
}

.backgroundNewCards.backgroundDocuments {
    width: 220px !important;
    height: 160px;
}

.checkedPayment.backgroundNewCards.backgroundDocuments {
    width: 250px !important;
}

.loginUser {
    background: #889098 !important;
    color: white !important;
    font-size: 15px;
    opacity: 0.8;
    padding: 11px !important;
    border-radius: 50% !important;
}

.textAlignRight {
    text-align: right !important;
}

.textAlignLeft {
    text-align: left !important;
}

input .textAlignRight, textarea.textAlignRight {
    text-align: right !important;
}

input.textAlignCenter,
textarea.textAlignCenter {
    text-align: center;
}

input.ValidatingInput {
    display: inline-flex !important;
}

.e-rte-dropdown-popup ul .e-item.e-segoe-ui {
    font-family: Roboto !important;
    color: black !important;
    list-style: none !important;
    background: white !important;
}

.FooterEmail.card-footer {
    text-align: center !important;
}

.e-rte-dropdown-popup ul {
    list-style: none;
    font-family: Roboto;
    color: black !important;
    background: white !important;
    border: 1px solid #ddd;
}

div#imageRTE_toolbar_FontName-popup > ul, div#imageRTE_toolbar_FontSize-popup > ul {
    text-align: center !important;
    padding: 8px !important;
}

input.textfontWhite,
textarea.textAlignCenter, p.textfontWhite {
    color: white;
}

input.textfontBlack,
textarea.textfontBlack {
    color: black;
}

.w16 {
    width: 16px;
}

.hoverImg {
    display: none;
}

    .hoverImg:hover {
        display: inline-flex;
    }

.ant-avatar.avatar3.ant-avatar-circle {
    width: 60px;
    height: 60px;
    padding-top: 6px;
    font-size: 33px;
}

span.ant-avatar.avatar3.ant-avatar-circle {
    margin-left: -9px !important;
    margin-top: 16px !important;
    width: 40px;
    height: 40px;
    font-size: 25px;
    background-color: #00a0df;
}

span.CommentContent.Text.ml-2.d-block {
    color: #33477c !important;
    margin-left: 50px !important;
}

.MuiPaper-root.MuiExpansionPanel-root.CommentPannel.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded::before {
    background-color: transparent !important;
}

p.MuiTypography-root.titlePanel.CommentTitle.MuiTypography-body1 {
    margin-left: -23px;
    font-size: 14px !important;
}

div#CommentBox {
    width: 14%;
}

button.commentButton.btn.btn-secondary {
    width: 50px;
    font-size: 11px;
    margin-top: 14px;
    margin-left: 17px;
    background-color: #00a0df;
    border: transparent;
    height: 23px;
    padding: 4px;
}

    button.commentButton.btn.btn-secondary:hover,
    button.commentButton.btn.btn-secondary:active,
    button.commentButton.btn.btn-secondary:focus {
        width: 50px;
        font-size: 11px;
        margin-top: 14px;
        margin-left: 17px;
        background-color: #00a0df;
        border: transparent;
        height: 23px;
        padding: 4px;
    }

.dividerprofile.ant-divider, .ant-divider-vertical {
    height: 120px;
}

.css-1fhf3k1-control {
    height: 30px !important;
    min-height: 30px !important;
    border-radius: 0px !important;
}

.MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
}

.MuiCardContent-root {
    padding-top: 0px !important;
    padding-bottom: 0 !important;
}

label.d-block.checkRed.ant-checkbox-wrapper > .ant-checkbox > span.ant-checkbox-inner {
    border: 2px solid red;
}

label.d-block.CheckboxTitle2.ant-checkbox-wrapper > .ant-checkbox-checked > span.ant-checkbox-inner:before {
    padding: 3px;
    font-size: 18px;
    color: transparent;
}

label.d-block.CheckboxTitle2.ant-checkbox-wrapper > .ant-checkbox > span.ant-checkbox-inner {
    border-color: transparent !important;
}

label.d-block.CheckboxTitle2.ant-checkbox-wrapper > .ant-checkbox > span.ant-checkbox-inner {
    border-color: transparent !important;
}

label.d-block.CheckboxTitle2.ant-checkbox-wrapper > .ant-checkbox-checked .ant-checkbox-inner {
    background: green !important;
}

.widthMax {
    width: -webkit-fill-available !important;
}

label.d-block.CheckboxTitle2.ant-checkbox-wrapper > .ant-checkbox > span.ant-checkbox-inner:before {
    padding: 3px;
    font-size: 18px;
    content: "x";
    color: red;
    border-color: transparent !important;
}

label.d-block.CheckboxTitle2.ant-checkbox-wrapper > .ant-checkbox-checked > span.ant-checkbox-inner:before {
    padding: 3px;
    font-size: 16px;
    color: transparent;
}

label.d-block.CheckboxTitle.ant-checkbox-wrapper > .ant-checkbox-checked > span.ant-checkbox-inner:before {
    padding-left: 1px;
    font-size: 20px;
    content: "\2713";
    color: transparent;
}

label.d-block.CheckboxTitle.ant-checkbox-wrapper > .ant-checkbox > span.ant-checkbox-inner:before {
    font-size: 20px;
    content: "\2713";
    color: #bfbfbf;
}

label.d-block.CheckboxTitle.ant-checkbox-wrapper > .ant-checkbox-checked > span.ant-checkbox-inner:before {
    padding-left: 1px;
    font-size: 20px;
    content: "\2713";
    color: transparent;
}

label.d-block.CheckboxTitle.ant-checkbox-wrapper > .ant-checkbox-checked > span.ant-checkbox-inner:after {
    padding-left: 1px;
}

input [type='text'], input[type='password'], input[type='number'], input[type='list'], textarea {
    font-size: 14px !important;
}

pre, code, kbd, samp {
    font-family: Roboto !important;
}

.pt1px {
    padding-top: 1px !important;
}

.backgroundGrey3 {
    background: #f6f5fa !important;
}

.whiteBackgroundBox {
    background: white !important;
    padding: 10px;
    width: 100%;
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108));
}

nav.borderbottom.h40.bordertop.pl0.navbar {
    border-top: 1px solid transparent;
    padding-bottom: 30px !important;
    height: 60px !important;
}

nav.goBackNav.navbar, nav.borderbottom.h40.bordertop.pl0.goBackNav.navbar {
    padding: 10px !important;
    height: 30px !important;
}

.whiteBackgroundBox2 {
    background: white !important;
    padding: 10px;
    width: 100%;
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108));
}

div#LoadPlatformLinks_wrapper, div#PaymentMethodColumns_wrapper, div#ApplicationTypesTable_wrapper,
div#AffiliateSettings_wrapper, div#GetGroupRegistryTable_wrapper, div#IBCodeRangeTable_wrapper,
div#EmailsTable_wrapper, div#AllUsersTable_wrapper, div#LoadEmailTemplatesTable_wrapper,
div#AccLeverageTable_wrapper, div#LoginRangeTable_wrapper, div#WalletTable_wrapper,
div#GetTeamsTable_wrapper, div#GetRolesTable_wrapper, div#LoadRegistrationTable_wrapper,
div#AllUsersTable_wrapper, div#GetClientIDRangesTable_wrapper {
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108));
}

.ant-tooltip-inner {
    padding: 6px 24px !important;
}

g.highcharts-range-selector-buttons, g.highcharts-label.highcharts-range-label, p.ant-empty-description, g.highcharts-label.highcharts-no-data, .MuiTypography-h5, .MuiTypography-body1, label {
    font-family: Roboto !important;
}

.ant-empty-image svg > g:first-of-type > g {
    stroke: grey;
}

select.selectPwrmissions {
    width: 300px;
    height: 30px;
}

tr.headContact {
    border-bottom: 3px solid transparent !important;
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.__value-container.css-1hwfws3 > .css-1wa3eu0-placeholder {
    color: #00a0df !important;
    white-space: normal;
}

p.MuiTypography-root.titlePanel.DetailsPanel.MuiTypography-body1 {
    margin-left: -20px !important;
    font-size: 17px !important;
}

span.datePicker.TaskDate2.TaskInTab.ant-calendar-picker > div > .ant-calendar-picker-input.ant-input {
    border: transparent !important;
}

span.ant-time-picker.timeInTab > .ant-time-picker-input::-webkit-input-placeholder {
    border: transparent !important;
}

span.ant-time-picker.timeInTab > .ant-time-picker-input[disabled] {
    border: transparent !important;
    background: transparent !important;
    margin-left: -10px !important;
    color: #889098 !important;
}

.font14 {
    font-size: 14px !important;
}

tr.headContact > th {
    color: #051c2c !important;
    font-size: 14px !important;
}

tr.headContact {
    border-bottom: 1px solid #3C3C3C !important;
    border-top: 1px solid transparent !important;
}

tbody.bodyContact > tr > td {
    color: #889098 !important;
    font-size: 14px !important;
    overflow: inherit !important;
}

span.ant-time-picker.timeInTab > .ant-time-picker-icon i {
    display: none;
}

span.datePicker.TaskDate2.TaskInTab.ant-calendar-picker > div > i.anticon.anticon-calendar.ant-calendar-picker-icon svg {
    display: none;
}

.h100vh-90.upperMenu.SubMenuSettings {
    width: 230px !important;
}

span.datePicker.TaskDate2.TaskInTab.ant-calendar-picker > div > .ant-calendar-picker-input.ant-input::-webkit-input-placeholder,
span.ant-time-picker.timeInTab > .ant-time-picker-input::-webkit-input-placeholder {
    color: #00a0df !important;
}

span .firstCheckbox {
    font-size: 16px;
    font-weight: 500 !important;
    opacity: 1 !important;
}

span.LicenseCheckbox {
    display: block !important;
    margin-left: 27px !important;
}

button.Details.DetailsBtn {
    border: none !important;
    color: transparent !important;
    background: transparent !important;
}

button.KeyBtn {
    border: none !important;
    background: transparent;
    color: transparent !important;
}

    button.KeyBtn:hover, button.KeyBtn:focus {
        color: transparent !important;
        margin-left: 13px;
        width: 20px;
        height: 18px;
        padding: 10px;
        background-size: 12px;
    }

.w15 {
    width: 15px;
}

span.rangePicker.FilterRange.d-block.w180.ant-calendar-picker {
    width: 180px !important;
}

button.primaryApp, button.btn-primaryApp {
    font-weight: 500;
    background: #f4f4f4;
    border: transparent;
    border-radius: 0px;
}

ul.ant-menu.mt5.blue-bg.MenuSettings.ant-menu-dark.ant-menu-root.ant-menu-inline {
    width: 230px !important;
}

nav.navbarSettings.navbar {
    box-shadow: unset !important;
}

.colPaddingRight.col-md-3 {
    padding-right: 0px !important;
}

.ant-tabs-tab-active.ant-tabs-tab {
    font-size: 16px !important;
}

.ant-tabs-tab {
    opacity: 0.7;
}

.ant-tabs-tab-active.ant-tabs-tab {
    opacity: 1;
}

.react-select.cursor-pointer.LargeSelect.__input > input {
    border: transparent !important;
}

.backgroundGrey.colMarginLeft.col-md-9 {
    padding-left: 0px !important;
}

.MenuSettings > li.ant-menu-item.ant-menu-item-selected {
    width: 300px !important;
    height: 40px !important;
    line-height: 40px !important;
}

label.subMenuTitleSettings {
    margin-top: 10px !important;
    font-size: 1.3125rem;
    font-weight: 500;
    letter-spacing: -.02em;
    color: rgba(var(--palette-neutral-100,0, 0, 0),1) !important;
    margin-left: 22px !important;
}

.h100vh-90.upperMenu.topbarBottom {
    overflow-x: hidden !important;
}

.ant-tabs.ant-tabs-top.mh100vhm70.TabsInSettings.ant-tabs-line,
.ant-tabs.ant-tabs-top.TabsInSettings.ant-tabs-line {
    margin-left: 14px;
}

label.MenuTitleSettings {
    padding: 10px;
    height: 48px;
    align-items: center;
    display: flex;
    padding-left: 23px;
    font-size: 24px !important;
    font-weight: 500;
    letter-spacing: -.02em;
}

label.DarkMenuTitleSettings {
    padding: 10px;
    height: 48px;
    align-items: center;
    display: flex;
    padding-left: 23px;
    font-size: 24px !important;
    font-weight: 500;
    letter-spacing: -.02em;
}

h5.LabelCreate2 {
    font-size: 24px !important;
}

.TitleSettings {
    margin-top: 11px;
    margin-left: 24px;
    font-size: 22px;
    margin-bottom: 0px !important;
    font-weight: 500;
}

li.ant-menu-item.SettingsMenu {
    line-height: 40px !important;
}

.w40 {
    width: 40px;
}

input.smallInput.form-control-lg.form-control {
    width: 90px !important;
    min-width: 30px;
}

td {
    font-weight: 400 !important;
}

button.navigate:hover, button.secondNavigate:hover {
    color: #00a0df !important;
    text-decoration: underline;
}

button.noNavigate:hover, button.secondNoNavigate:hover {
    color: black !important;
    cursor: default;
}

.MenuSettings > .ant-menu-inline > .ant-menu-item {
    height: 32px !important;
}

button.noNavigate, button.secondNoNavigate {
    font-weight: 500;
    border: none !important;
    background: transparent;
    color: black;
}

button.navigate, button.secondNavigate {
    font-weight: 500;
    border: none !important;
    background: transparent;
    color: #00a0df;
}

.p-multiselect.p-component.multiChildren > .p-multiselect-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
    left: unset !important;
}

.p-multiselect.p-component.multiChildren.p-disabled:hover, .p-multiselect.p-component.multiChildren:hover {
    background: transparent !important;
}

.p-multiselect.p-component.multiChildren,
.p-multiselect.p-component.multiChildren:hover,
.p-multiselect.p-component.multiChildren:focus,
.p-multiselect.p-component.multiChildren:active {
    border: 1px solid #ddd !important;
    line-height: 14px;
    border-radius: 0px;
    height: 30px;
    width: 100% !important;
    opacity: 1 !important;
    background: transparent;
}

    .p-multiselect.p-component.multiChildren.p-disabled > .p-multiselect-trigger > span.p-multiselect-trigger-icon.pi.pi-chevron-down.p-c,
    .p-multiselect.p-component.multiChildren > .p-multiselect-trigger > span.p-multiselect-trigger-icon.pi.pi-chevron-down.p-c {
        color: #00a0df !important;
        font-size: 15px !important;
        margin-top: unset;
    }

    .p-multiselect.p-component.multiChildren.p-disabled > .p-multiselect-label-container > label.p-multiselect-label.p-placeholder,
    .p-multiselect.p-component.multiChildren > .p-multiselect-label-container > label.p-multiselect-label {
        color: #00a0df !important;
        background: transparent !important;
    }

.has-error .ant-form-explain, .has-error .ant-form-split {
    font-family: Roboto !important;
    font-weight: 400 !important;
}

button.navigateGrey {
    font-weight: 500;
    border: none !important;
    background: transparent;
    color: #808080;
}

.p-multiselect-label-container {
    line-height: 10px !important;
}

button.navigateGrey:hover {
    color: #808080 !important;
    text-decoration: underline;
    font-size: 15px;
}

.ModalForm {
    padding: 15px;
    width: -webkit-fill-available;
    opacity: 1;
    overflow: hidden;
    height: -webkit-fill-available;
    margin: 0 auto;
    left: 50% !important;
    right: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

    .ModalForm > div {
        background: unset !important;
    }

.steps-content.ContentModal {
    flex-direction: row !important;
    position: static !important;
    margin-bottom: 10px !important;
    padding: 10px 10px 0px 10px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    flex-grow: 1 !important;
}

.ant-card.dashboardCards.ant-card-bordered {
    margin: 10px;
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0,0,0,.132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0,0,0,.108));
}

    .ant-card.dashboardCards.ant-card-bordered:hover {
        border: 1px solid #00a0df;
    }

.wrapText {
    overflow: hidden !important;
    overflow-wrap: break-word !important;
}

table#tablesInModals thead td {
    font-size: 14px !important;
    overflow-wrap: break-word !important;
    flex-wrap: wrap;
}

button.ThemeDropDown.dropdown-item {
    text-align: center !important;
}

.ant-tabs-tab, .ant-tabs-tab-active.ant-tabs-tab {
    white-space: normal;
}

.ant-form-item-label {
    white-space: initial !important;
}

.border-radius15.whiteBackgroundBox:hover {
    -webkit-box-shadow: 4px 4px 5px 0px;
    -moz-box-shadow: 4px 4px 5px 0px;
    box-shadow: 4px 4px 5px 0px;
}

.cardDash.p-0.card-body {
    padding-top: 10px !important;
}

.cardDash.p-0.nopadding.card-body {
    padding: 0px !important;
}

.nopadding.card-body {
    padding: 0px !important;
}

.progress-bar {
    top: 1px !important;
    bottom: 0px !important;
}

button.SubmitBtn, button.SubmitBtn:active, button.SubmitBtn:focus, button.SubmitBtn:hover {
    background: #00a0df;
    border-color: #00a0df;
    box-shadow: unset !important;
    outline: unset !important;
    margin: 6px;
    color: white;
}

img.closeOrder {
    height: 16px !important;
}

button.orerButton, button.orerButton:active, button.orerButton:focus, button.orerButton:hover {
    background: transparent !important;
    border-color: transparent !important;
    font-weight: 500 !important;
}

.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    position: fixed;
    left: 0;
    bottom: 10px;
    text-align: center;
}

input.noBorderInputs::-webkit-input-placeholder {
    font-size: 14px;
    color: dimgrey;
    font-weight: 400 !important;
    white-space: pre-line;
    position: relative;
    top: -7px
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.ButtonDashboard.AddPaddng {
    background: white !important;
    border-radius: 5px !important;
    margin: 0px !important;
    height: 50px;
    color: #889098 !important;
    width: 100% !important;
    padding: 10px !important;
    cursor: pointer !important;
    border-bottom: 1px solid #ddd;
}

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.ButtonDashboard.AddPaddng:hover {
        color: #00a0df !important;
        cursor: pointer !important;
        background: #f3f2f1 !important;
    }


.ant-drawer.ant-drawer-left.ant-drawer-open.leftDrawer.no-mask.no-mask > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body {
    overflow: hidden !important;
}

h2.titleDrawer {
    font-size: 24px !important;
    color: #889098 !important;
    font-weight: 400 !important;
}

input .noBorderInputs, input.noBorderInputs:active {
    background: transparent;
    border-color: transparent !important;
    font-size: 18px;
    color: black !important;
    font-weight: 500;
    height: 40px !important;
}

    input.noBorderInputs:focus {
        background: transparent;
        border-color: transparent !important;
        border-bottom: 2px solid #00a0df !important;
    }

.react-select.cursor-pointer.noborderApp.__control.css-yk16xz-control:focus, .react-select.cursor-pointer.noborderApp__control.css-yk16xz-control:focus,
.react-select.cursor-pointer.noborderApp.__control.css-yk16xz-control, .react-select.cursor-pointer.noborderApp__control.css-yk16xz-control {
    border: 1px solid transparent !important;
    background: transparent !important;
}

.react-select.cursor-pointer.noborderApp.__control.css-yk16xz-control {
    border: 1px solid transparent !important;
    background: transparent !important;
}

.react-select.cursor-pointer.noborderApp.__control.css-1pahdxg-control {
    border: 1px solid transparent !important;
    background: transparent !important;
    border-bottom: 2px solid #00a0df !important;
}

.react-select.cursor-pointer.withBorders.__control.css-yk16xz-control:focus, .react-select.cursor-pointer.withBorders.css-yk16xz-control:focus,
.react-select.cursor-pointer.withBorders.__control.css-yk16xz-control, .react-select.cursor-pointer.withBorders.css-yk16xz-control {
    min-width: 150px !important;
    max-width: 300px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    margin: 5px;
    height: 35px !important;
    background: transparent !important;
}

.react-select.cursor-pointer.withBorders.__control.css-yk16xz-control {
    min-width: 150px !important;
    max-width: 300px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    margin: 5px;
    height: 35px !important;
    background: transparent !important;
}

.react-select.cursor-pointer.withBorders.__control.css-1pahdxg-control {
    min-width: 150px !important;
    max-width: 300px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    height: 35px !important;
    margin: 5px;
    background: transparent !important;
}

.react-select.cursor-pointer.withBorders.__indicator.react-select.cursor-pointer.withBorders.__dropdown-indicator.css-tlfecz-indicatorContainer,
.react-select.cursor-pointer.withBorders.__indicator.react-select.cursor-pointer.withBorders.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    color: #00a0df !important;
}

.react-select.cursor-pointer.withBordersBig.__control.css-yk16xz-control:focus, .react-select.cursor-pointer.withBordersBig.css-yk16xz-control:focus,
.react-select.cursor-pointer.withBordersBig.__control.css-yk16xz-control, .react-select.cursor-pointer.withBordersBig.css-yk16xz-control {
    width: 200px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    margin: 5px;
    background: transparent !important;
}

.react-select.cursor-pointer.withBordersBig.__control.css-yk16xz-control {
    width: 200px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    margin: 5px;
    background: transparent !important;
}

.react-select.cursor-pointer.withBordersBig.__control.css-1pahdxg-control {
    width: 200px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    margin: 5px;
    background: transparent !important;
}

.react-select.cursor-pointer.withBordersBig.__indicator.react-select.cursor-pointer.withBordersBig.__dropdown-indicator.css-tlfecz-indicatorContainer,
.react-select.cursor-pointer.withBordersBig.__indicator.react-select.cursor-pointer.withBordersBig.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    color: #00a0df !important;
}

button.FilledButton.btn.btn-secondary,
button.FilledButton.btn.btn-secondary:focus,
button.FilledButton.btn.btn-secondary:active,
button.FilledButton.btn.btn-secondary:hover {
    margin: 5px;
    color: white !important;
    background: #00a0df !important;
    height: 30px !important;
    line-height: 20px;
    border-color: #00a0df !important;
    border-radius: 5px !important;
}

svg.svg-inline--fa.fa-user-tie.fa-w-14.userTrader {
    margin: 0px auto !important;
    display: block !important;
    background: #f5f6fb !important;
    border-radius: 50% !important;
    padding: 32px;
    color: #889098 !important;
    height: 169px !important;
    width: 169px !important;
}

button.FilledButton2.btn.btn-secondary,
button.FilledButton2.btn.btn-secondary:focus,
button.FilledButton2.btn.btn-secondary:active,
button.FilledButton2.btn.btn-secondary:hover {
    margin: 5px;
    background: #00a0df !important;
    color: white !important;
    height: 35px !important;
    line-height: 25px;
    border-color: #00a0df !important;
    border-radius: 5px !important;
    font-size: 15px !important;
}

.backgroundNewCards {
    padding: 20px !important;
    background: #f5f6fa !important;
    padding-bottom: 0px !important;
}

.loader-container.centered > .background {
    background: transparent !important;
}

.ThemeDropDownMenu.EditBulkMenu.dropdown-menu.show {
    width: 116px !important;
    margin-left: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
}

.nameUser.ThemeDropDownMenu.EditBulkMenu.dropdown-menu.show {
    width: 100% !important;
    min-width: 270px !important;
    margin-left: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
}

.WhiteSpace {
    white-space: normal !important;
}

li.AlignBtn.dropdown.nav-item > a {
    padding: 0px !important;
    padding-left: 10px !important;
}

button#AnalyticsBtn {
    padding: 5px !important;
    margin-left: 10px !important;
    background: transparent !important;
    border-radius: 5px !important;
    color: #00a0df !important;
}

    button#AnalyticsBtn:hover, button#AnalyticsBtn:focus, button#AnalyticsBtn:active {
        padding: 5px !important;
        margin-left: 10px !important;
        background: #00a0df !important;
        color: white !important;
    }

button.dropdownNew {
    background: #00a0ff !important;
    border: 1px solid #00a0ff !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    color: white !important;
    padding: 2px;
    height: 35px;
}

    button.dropdownNew:hover,
    button.dropdownNew:active,
    button.dropdownNew:focus {
        background: transparent !important;
        border: 1px solid #00a0ff !important;
        color: #00a0ff !important;
    }

.backgroundNewCards2 {
    background: #f5f6fa !important;
    padding-bottom: 0px !important;
}

.react-select-container.LeverageSelect.css-2b097c-container {
    width: 80px;
}

.react-select.cursor-pointer.LeverageSelect.__indicator.react-select.cursor-pointer.LeverageSelect.__dropdown-indicator.css-tlfecz-indicatorContainer,
.react-select.cursor-pointer.LeverageSelect.__indicator.react-select.cursor-pointer.LeverageSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    color: #00a0df !important;
}

.react-select.cursor-pointer.LeverageSelect.__control.css-yk16xz-control:focus, .react-select.cursor-pointer.LeverageSelect.css-yk16xz-control:focus,
.react-select.cursor-pointer.LeverageSelect.__control.css-yk16xz-control, .react-select.cursor-pointer.LeverageSelect.css-yk16xz-control {
    width: 80px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    background: transparent !important;
}

.react-select.cursor-pointer.LeverageSelect.__control.css-yk16xz-control {
    width: 80px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    background: transparent !important;
}

.react-select.cursor-pointer.LeverageSelect.__control.css-1pahdxg-control {
    width: 80px !important;
    border: 1px solid #00a0df !important;
    border-radius: 5px !important;
    background: transparent !important;
}

.react-select-container.LeverageSelect.react-select.cursor-pointer.LeverageSelect.--is-disabled.css-14jk2my-container {
    width: 80px !important;
}

.react-select.cursor-pointer.withBordersBig.__indicator.react-select.cursor-pointer.withBordersBig.__dropdown-indicator.css-tlfecz-indicatorContainer,
.react-select.cursor-pointer.withBordersBig.__indicator.react-select.cursor-pointer.withBordersBig.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    color: #00a0df !important;
}

svg.svg-inline--fa.fa-times.fa-w-11.iconPrimary, svg.svg-inline--fa.fa-check.fa-w-16.iconPrimary, .iconPrimary {
    color: #889098 !important;
}

label.titleAccount {
    color: #051c2c !important;
    font-size: 24px !important;
    font-weight: bold !important;
}

label.bigTitleAccount {
    color: #051c2c !important;
    font-size: 48px !important;
    font-weight: bold !important;
}

button.ButtonsApp.w100p.btn.btn-secondary {
    padding: 8px;
    background: #00a0df;
    border-color: transparent;
    font-size: 19px;
    font-weight: bold;
    border-radius: 20px;
    margin-left: -30px !important;
}

    button.ButtonsApp.w100p.btn.btn-secondary:hover, button.ButtonsApp.w100p.btn.btn-secondary:active, button.ButtonsApp.w100p.btn.btn-secondary:focus {
        background: #00a0df;
        border-color: transparent;
    }

button.ButtonsApp2.w100p.btn.btn-secondary {
    color: #00a0df;
    padding: 5px;
    background: transparent;
    border-color: #00a0df;
    font-size: 16px;
    border-radius: 20px;
    min-width: 120px !important;
}

    button.ButtonsApp2.w100p.btn.btn-secondary:hover, button.ButtonsApp2.w100p.btn.btn-secondary:active, button.ButtonsApp2.w100p.btn.btn-secondary:focus {
        background: #00a0df;
        color: white;
    }

.inputsBackground {
    background-color: rgba(32, 51, 69, 0.04) !important;
    min-height: 65px;
    border-radius: 10px !important;
}

div.list-group-item-progress {
    z-index: 1;
    list-style-type: none;
    border-bottom: 1px solid #E5E9F2 !important;
}

.colorGrey {
    color: dimgrey !important;
}

.react-select.cursor-pointer.noborderApp.__single-value.css-1uccc91-singleValue {
    color: black !important;
    font-weight: 500;
    font-size: 14px;
}

.SessionPage {
    background: #f5f6fa !important;
    height: 100vh !important;
}

.sessionBox {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
    background: white;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

button.SessionBtn.btn.btn-secondary {
    background: #00a0df !important;
    color: white !important;
    border-radius: 5px !important;
    border-color: #00a0df !important;
    min-width: 100px;
    padding: 10px !important;
    font-size: 15px !important;
}

    button.SessionBtn.btn.btn-secondary:focus, button.SessionBtn.btn.btn-secondary:active, button.SessionBtn.btn.btn-secondary:hover {
        background: transparent !important;
        color: #00a0df !important;
        border-color: #00a0df !important;
    }

.sessionImage {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    background: no-repeat;
}

.MuiPaper-root.MuiExpansionPanel-root.mt-2.AppsExpansion.NotificationExpansion.Mui-expanded.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded, .MuiPaper-root.MuiExpansionPanel-root.mt-2.AppsExpansion.NotificationExpansion.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    border: 1px solid transparent;
    box-shadow: unset !important;
}

.checkboxBackground {
    background-color: rgba(32, 51, 69, 0.04) !important;
    border-radius: 10px !important;
}

.borderIcons {
    border: 2px solid #ddd;
    padding: 5px;
    border-radius: 15px;
}

.row.buttonsAppCard {
    position: relative;
    left: 0;
    bottom: 20px;
    width: 100%;
}

table#amountDist thead, table#tablesInModals thead {
    border: 1px solid black;
}

    table#amountDist thead td,
    table#tablesInModals thead td {
        border: 1px solid black;
    }

table#amountDist tbody > tr, chartline
table#tablesInModals tbody > tr {
    border: 1px solid black;
}

.cardBorder.NewCardDashboard.card, .cardBorder.NewNewCardDashboard {
    height: 400px !important;
}


i.fas.fa-chart-line.text-center.newCardsIconsRoi, i.fas.fa-chart-pie.text-center.newCardsIconsGain {
    color: #e78d72 !important;
    background: #f2b19d !important;
    border-radius: 5px !important;
    border-radius: 5px !important;
    height: 75px !important;
    width: 75px !important;
    font-size: 32px !important;
    padding: 22px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

i.fas.fa-chart-line.text-center.newCardsIconsGain {
    color: #586ac9 !important;
    background: #d1d7f7 !important;
    border-radius: 5px !important;
    height: 75px !important;
    width: 75px !important;
    font-size: 32px !important;
    padding: 22px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

i.fas.fa-file-invoice-dollar.text-center.newCardsIconsNet, i.fas.fa-chart-bar.text-center.newCardsIconsGain {
    color: #69c7b8 !important;
    background: #d1f7f1 !important;
    border-radius: 5px !important;
    height: 75px !important;
    width: 75px !important;
    font-size: 32px !important;
    padding: 22px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

i.fas.fa-sort-down.text-center.newCardsIconsLoss {
    color: #ed9d1e !important;
    background: #f2d19d !important;
    border-radius: 5px !important;
    height: 75px !important;
    width: 75px !important;
    font-size: 35px !important;
    padding: 14px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

i.fas.fa-chart-pie.text-center.newCardsIconsGain.newCardsBigger, i.fas.fa-chart-line.text-center.newCardsIconsGain.newCardsBigger, i.fas.fa-chart-bar.text-center.newCardsIconsGain.newCardsBigger {
    height: 90px !important;
    width: 90px !important;
    font-size: 35px !important;
    padding: 24px !important;
    margin-right: 30px;
    margin-left: 20px !important;
}

.NewDashboardHeader.card-header {
    border: none !important;
}

.cardBorder.NewCardDashboard.card, .backgroundCharts, .cardBorder.NewNewCardDashboard {
    border: none !important;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.h450 {
    height: 450px !important;
}

svg.svg-inline--fa.fa-chart-line.fa-w-16.chartline {
    color: #00a0df !important;
    background: #d1ecf7;
    padding: 4px;
    border-radius: 5px;
    font-size: 28px;
}

svg.svg-inline--fa.fa-download.fa-w-16.downloadIcon, svg.svg-inline--fa.fa-chart-line.fa-w-16.chartline4 {
    color: #5bb58f !important;
    background: #d4fae7;
    padding: 4px;
    border-radius: 5px;
    font-size: 28px;
}

svg.svg-inline--fa.fa-history.fa-w-16.historyIcon, svg.svg-inline--fa.fa-chart-line.fa-w-16.chartline3 {
    color: #c27ee5 !important;
    background: #f1e5f8;
    padding: 4px;
    border-radius: 5px;
    font-size: 28px;
}

svg.svg-inline--fa.fa-chart-pie.fa-w-17.chartpie, svg.svg-inline--fa.fa-chart-line.fa-w-16.chartline2 {
    color: #db5b5b !important;
    background: #f7d2d1;
    padding: 4px;
    border-radius: 5px;
    font-size: 28px;
}

table#amountDist > tbody > tr > td,
table#tablesInModals > tbody > tr > td {
    vertical-align: middle;
    border: 1px solid black !important;
}

table#tablesInModals {
    margin-bottom: 0;
    max-width: none;
}

table.tablesInModals2 {
    margin: 0px !important;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
    width: fit-content !important;
    max-width: 100%;
}

input.smallInput.modalInput.form-control-lg.form-control {
    width: 30px !important;
    padding: 0px !important;
}

input.smallInput.modalInput2.form-control-lg.form-control {
    width: 68% !important;
    padding: 0px !important;
    margin: 0 auto !important;
}

table#tablesInModals > tbody > tr > td {
    font-size: 12px !important;
}

.fix-width {
    overflow-x: auto;
}

.css-1pahdxg-control, .css-4ljt47-MenuList, .css-26l3qy-menu {
    z-index: 3 !important;
}

input.LoginInputs.form-control-lg.form-control {
    min-height: 30px !important;
    height: 30px !important;
}

.ModelPaper2.ModalPopBody.m10mr10.ModalLogin {
    width: 40% !important;
    margin-top: 5% !important;
}

.w20 {
    width: 20px;
}

.w400 {
    min-width: 400px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #00a0df !important;
}

.ant-checkbox-checked::after {
    border: 1px solid #00a0df !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00a0df !important;
    border-color: #00a0df !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
}

.opacity8 {
    opacity: 0.8 !important;
}

.opacity6 {
    opacity: 0.6 !important;
}

svg.svg-inline--fa.fa-circle.fa-w-16.ml-2.circleSmall {
    font-size: 8px;
    margin: 2px;
}

.w16 {
    width: 16px !important;
}

.MuiExpansionPanel-root {
    box-shadow: unset !important;
}

.MuiPaper-root.MuiPaper-elevation1.MuiExpansionPanel-root.mt-2.rolesExpansion.Mui-expanded.MuiExpansionPanel-rounded.MuiPaper-rounded,
.MuiPaper-root.MuiPaper-elevation1.MuiExpansionPanel-root.mt-2.rolesExpansion.MuiExpansionPanel-rounded.MuiPaper-rounded {
    box-shadow: unset !important;
}

    .MuiPaper-root.MuiPaper-elevation1.MuiExpansionPanel-root.mt-2.rolesExpansion.MuiExpansionPanel-rounded.MuiPaper-rounded:before {
        background-color: transparent !important;
    }

.w16 {
    width: 16px;
}

.ant-calendar-range-picker-separator {
    margin: 6px !important;
}

.w20mb {
    width: 15px;
    margin-bottom: 4px;
}

.ylog {
    margin-left: -9px;
    height: 36px;
}

.w15 {
    width: 15px;
}

.MuiCardContent-root.cardNotes {
    margin-bottom: -14px;
}

.css-1pahdxg-control, .css-4ljt47-MenuList, .css-yk16xz-control {
    border-color: transparent !important;
    background-color: white !important;
    color: #000000 !important;
}

.marginleft35p {
    margin-left: 35% !important;
}

.marginleft28p {
    margin-left: 21% !important;
}

.ml-m20 {
    margin-left: -20px !important;
}

.ml-5 {
    margin-left: 2rem !important;
}

.ml-6 {
    margin-left: 3.0rem !important;
}

label.d-block.CheckboxTitle.ant-checkbox-wrapper {
    font-size: 18px !important;
}

.w35 {
    width: 35px;
}

.t-underline {
    text-decoration: underline !important;
}

.reviewLabelsTitles {
    font-size: 14px;
    padding: 0px 30px 0px 5px !important;
    font-weight: 400 !important;
    text-decoration: underline !important;
}

.reviewTitle3 {
    color: #889098;
    font-size: 14px !important;
}

dialog.dialog {
    max-width: 100%;
    z-index: 10;
    padding: 16px 32px 24px;
    position: absolute !important;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    width: 70%;
    opacity: 1;
    overflow: hidden;
    margin: 0 auto;
    left: 50% !important;
    right: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
}

.react-select.cursor-pointer.IbFilterSelect.__control.css-yk16xz-control {
    width: 80px;
    background: transparent !important;
    border-color: transparent !important;
    white-space: normal;
}

.react-select.cursor-pointer.IbFilterSelect.__control.css-1pahdxg-control {
    width: 80px;
    background: transparent !important;
    border-color: transparent !important;
    white-space: normal;
}

span.rangePicker.FilterRange.ant-calendar-picker > span.ant-calendar-picker-input.ant-input, span.rangePicker.FilterRange.ant-calendar-picker > span.ant-calendar-picker-input.ant-input:focus, span.rangePicker.FilterRange.ant-calendar-picker > span.ant-calendar-picker-input.ant-input:hover
span.rangePicker.FilterRange.ant-calendar-picker > .ant-input:hover {
    border: 1px solid transparent !important;
}

.ant-form-item {
    margin-bottom: 0px !important;
}

.spinIndicator2 {
    right: 17% !important;
    content: '';
    position: fixed !important;
    top: 50% !important;
    width: 40px !important;
    height: 40px !important;
    border-width: 4px !important;
    border-radius: 50% !important;
}

.spinIndicator3 {
    z-index: 1000;
    content: '';
    width: 40px !important;
    height: 40px !important;
    margin-left: 10px !important;
}

.spinIndicator4 {
    right: 12% !important;
    content: '';
    position: fixed !important;
    top: 50% !important;
    width: 40px !important;
    height: 40px !important;
    border-width: 4px !important;
    border-radius: 50% !important;
}

.spinIndicator3 i.anticon.anticon-loading.ant-spin-dot {
    margin-left: 46px !important;
    margin-top: 18px !important;
}

.panel-footer {
    height: 50px;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

rect.highcharts-background {
    fill: transparent !important;
}

.mt200 {
    margin-top: 200px !important;
}

.w16 {
    width: 16px !important;
}

span.ant-avatar.avatar.avatarBusinessProfile.ant-avatar-circle {
    height: 65px;
    width: 65px;
}

    span.ant-avatar.avatar.avatarBusinessProfile.ant-avatar-circle > span.ant-avatar-string {
        font-size: 35px;
        border: 30px !important;
        margin-bottom: 22px;
    }

button.tabTitle.tabButton.btn.btn-secondary, button.tabTitle.tabButton.btn.btn-secondary:focus, button.tabTitle.tabButton.btn.btn-secondary:hover {
    font-size: 13px !important;
    height: 32px;
    background: #00a0df !important;
    border: black;
    margin: 0px;
    color: #121212 !important;
}

.filterBarTab {
    display: flex;
    flex-shrink: 0;
    padding: 6px;
    height: 43px;
    border-radius: 4px;
    margin: 8px;
}

.filterBarTab2 {
    display: flex;
    flex-shrink: 0;
    padding: 6px;
    height: 43px;
    border-radius: 4px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 8px !important;
    margin-bottom: 0px !important;
}

span.TitleDate {
    margin-left: 10px;
    font-size: 20px;
}

button .ButtonsModal:hover {
    background-color: #00a0df !important;
    color: white !important;
    border: transparent !important;
}

button .ButtonsModal2:hover {
    background-color: transparent !important;
    color: #00a0df !important;
    border: #00a0df !important;
}

.page-item.disabled .page-link, .page-item:last-child .page-link, .page-link {
    border-radius: 0px !important;
}

.ButtonRightSidebar, .ButtonRightSidebar:hover, .ButtonRightSidebar:focus, .ButtonRightSidebar:active {
    height: 30px !important;
    background-color: #00a0df !important;
    border-radius: 4px !important;
    color: white !important;
    border: transparent !important;
    margin-left: 40px;
    font-size: 13px !important;
    margin-top: 4px !important;
}

.MuiButtonBase-root.MuiExpansionPanelSummary-root.CommentSummary {
    background: #f6f5fa !important;
}

span.NoteTitle {
    font-size: 24px !important;
    font-weight: 600 !important;
}

.ModalSettings > .MuiBackdrop-root {
    background: black;
    opacity: 0.6 !important;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: unset !important;
    background-color: unset !important;
    border-color: unset !important;
    box-shadow: unset !important;
}

.btn-secondary:focus, .btn-secondary.focus {
    box-shadow: unset !important;
}

.dropdown-item {
    padding: 0.2rem 1.5rem !important;
}

a {
    color: #0366d6;
}

img.imgDialog {
    max-width: 100%;
    max-height: 100%;
}

svg.MuiSvgIcon-root.btnImageRed {
    color: red !important;
}

i.anticon.anticon-info-circle {
    color: #00a0df !important;
}

.SaveSignature, .ClearSignature, .SaveSignature:focus, .SaveSignature :hover, .ClearSignature:focus, .ClearSignature:hover {
    background: #00a0df !important;
    color: white !important;
    border: 1px solid #00a0df !important;
}

.ant-avatar.avatar2.ant-avatar-circle {
    width: 60px;
    height: 60px;
    padding-top: 10px;
    font-size: 33px;
}

.resetText:hover {
    color: #54c6f1 !important;
}

.noteText {
    margin: 0px !important;
    margin-left: 10px !important;
}

.ant-divider-horizontal {
    margin: 0px 0 !important;
}
/*----circle inside radio----*/
.ant-radio-inner {
    left: 2px !important;
}
/*--------------------*/
.t-bold {
    font-weight: 500 !important;
}

.titleContact {
    font-size: 16px;
    margin-bottom: 0px !important;
}

.ManageTitle {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #00a0df;
    cursor: pointer;
}

.MuiTypography-body1 {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.AnswerContact {
    font-size: 14px;
    margin-left: 0px;
}

label {
    font-weight: 400 !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-14 {
    font-size: 16px !important;
}

.MuiCardContent-root {
    margin-left: 20px !important;
    margin-bottom: 20px !important;
}

span.ActivityTitleText {
    color: #33475b !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

span.ActivityOwnerText {
    color: #889098 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

span.SearchInputFilter.ant-input-affix-wrapper {
    margin-left: 50px;
    width: 350px !important;
    height: 35px !important;
}

.MuiPaper-root.MuiCard-root.ActivityCard.mb-2.mr-2.mt-2.ml-2.MuiPaper-elevation1.MuiPaper-rounded {
    width: 97% !important;
    margin: 0px !important;
    margin-bottom: 10px !important;
}

.ant-tabs.ant-tabs-left.TabsEachClient.TabPaneEachClients.ml-3.ant-tabs-vertical.ant-tabs-line > .ant-tabs-bar.ant-tabs-left-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > div > .ant-tabs-tab-active.ant-tabs-tab {
    color: #051C2C !important;
}

.ant-tabs.ant-tabs-left.TabsEachClient.TabPaneEachClients.ml-3.ant-tabs-vertical.ant-tabs-line > .ant-tabs-bar.ant-tabs-left-bar {
    width: 100% !important;
}

    .ant-tabs.ant-tabs-left.TabsEachClient.TabPaneEachClients.ml-3.ant-tabs-vertical.ant-tabs-line > .ant-tabs-bar.ant-tabs-left-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > div > .ant-tabs-tab:hover {
        background: #9ea2ab !important;
    }

svg.svg-inline--fa.fa-search.fa-w-16.searchIcon {
    color: #889098 !important;
}

.MuiCardContent-root {
    margin-top: 20px !important;
}

span.dateActivityText {
    margin-top: 20px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}

.MuiCardHeader-root.headerCards {
    border-bottom: 1px solid #ddd;
}

span .dateActivityText {
    color: #516f90 !important;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-32 {
    font-size: 32px !important;
}

.font-42 {
    font-size: 42px !important;
}

.font-48 {
    font-size: 48px !important;
}

i.fa.miniCurrency {
    color: white !important;
    font-size: 24px;
}

span.spanCurrency {
    padding-top: 16px !important;
}

code {
    color: #E01A76;
}

.overflow-y-scroll {
    overflow-y: auto !important;
}

.ActivityCard {
    width: 99%;
}

.backgroundGrey {
    background: #f5f8fa !important;
}

textarea.emailText {
    border: 1px solid transparent !important;
}

.font16 {
    font-size: 16px !important;
}

button.MyAccountBtn {
    background-color: transparent;
    color: #000000;
    font-size: 13px;
    border: 1px solid transparent !important;
}

img.AccountIcon {
    width: 12px;
}

img.AccountIcon2 {
    width: 15px;
}

button.MyAccountBtn:hover {
    background-color: transparent;
    color: #000000;
}
/*------------Sign in-------------*/
input #email, input#password {
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 0;
    height: 38px !important;
}

.p-multiselect.p-component.membersFilter {
    width: 100% !important;
    border: 1px solid #ddd !important;
}

    .p-multiselect.p-component.membersFilter > .p-multiselect-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
        left: 2px !important;
    }

input.loginInput2 {
    margin-bottom: 0;
    height: 25px !important;
    border: transparent !important;
    background: transparent !important;
    border-bottom: 1px solid #00a0df !important;
}

    input.loginInput2:focus, input.loginInput2:active, input.loginInput2:hover {
        border: transparent !important;
        border-bottom: 1px solid #00a0df !important;
        background-color: transparent !important;
        color: black;
    }

.Model {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    touch-action: none;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
}

.centerContent {
    align-items: center;
    justify-content: center;
    display: flex;
}

.centerContent2 {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.h20 {
    height: 20px !important;
}

.mr11 {
    margin-right: 11px;
}

.MuiTypography-body1 {
    width: 100%;
}

.ModelPaper {
    height: 98%;
    width: 75%;
    border-radius: 4px;
    float: right;
}


.ModelPaper2 {
    height: 98%;
    width: 30%;
    border-radius: 4px;
    float: right;
    flex-grow: 1 !important;
    display: flex !important;
    flex-direction: column !important;
}

    .ModelPaper2 .ModelColumns {
        height: 98%;
        width: 20%;
        border-radius: 4px;
        float: right;
    }

.ModelPaper4 {
    height: 98%;
    width: 24%;
    border-radius: 4px;
    float: right;
    flex-grow: 1 !important;
    display: flex !important;
    flex-direction: column !important;
}

.btnModal {
    justify-content: flex-start !important;
    font-size: .875rem;
    display: flex !important;
    flex-grow: 1 !important;
    flex-direction: row !important;
}

.ModelPaper3 {
    height: 98%;
    width: 17%;
    border-radius: 4px;
    float: right;
}


.m10mr10 {
    margin-left: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 20px !important;
}

.Model {
    display: inherit !important;
}

h2.TitleModal {
    font-weight: 400;
    display: inherit !important;
    font-size: 24px;
    padding: 10px 10px 0px 10px;
    position: sticky;
    top: 0;
}

svg.svg-inline--fa.fa-undo, svg.svg-inline--fa.fa-trash-alt.fa-w-14.AddTaskPlus2 {
    cursor: pointer;
}

.ant-tabs .ant-tabs-left-content {
    overflow-y: auto;
}

p.BodyModal, p.BodyModalGraph {
    padding: 10px 10px 0px 10px;
}


.BodyModal3, .BodyModal2 {
    height: 98% !important;
    flex-direction: row !important;
    position: relative !important;
    margin-bottom: 10px !important;
    padding: 10px 10px 0px 10px;
    overflow: auto !important;
    overflow-x: hidden !important;
    flex-grow: 1 !important;
}

.input-group-prepend {
    position: absolute !important;
}

.logoSizeBig {
    width: 184px !important;
    height: 50px !important;
}

.signinText {
    color: #ddd;
    font-size: 14px;
    text-decoration: none !important;
}

.loginText:hover {
    text-decoration: none !important;
}

.inputForm {
    margin: 0 auto;
    width: 100% !important;
}

.loginInput {
    width: 225px !important;
    border-radius: 0px !important;
    border-left: 0 !important;
}

    .loginInput:focus {
        border-left: none !important;
        outline: none !important;
        box-shadow: none !important
    }

.notAuthIcon {
    width: 22px !important;
}

.loginBtn {
    font-size: 16px;
    border: transparent !important;
    margin-bottom: 10px;
    margin-top: 10px;
    border-Radius: 5px !important;
    width: 100% !important;
    height: 35px !important;
    line-height: 30px !important;
}

.loginText {
    color: #0E1B2B !important;
    font-weight: 500;
}

.loginBtn:focus {
    box-shadow: none !important;
}

.react-select.cursor-pointer.SelectRegister__control.css-yk16xz-control {
    border: transparent !important;
    border-bottom: 1px solid #00a0df !important;
    background: transparent !important;
}

.addOnButton {
    background-Color: #fff !important;
    border-top: 1px solid #99D9F3 !important;
    border-bottom: 1px solid #99D9F3 !important;
    border-left: 1px solid #99D9F3 !important;
    border-right: none !important;
}

.langButtons {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.iconStyleLeft {
    background-color: white !important;
    border: 1px solid white !important;
    border-right: none !important;
    border-radius: 0 !important;
    height: 38px;
}

.iconSignin {
    width: 15px !important;
    height: 15px !important;
}

.iconStyleLeft:focus {
    box-shadow: none !important;
}

.disableIt {
    pointer-events: none !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
    padding-top: 4px !important;
}

.resetText {
    font-size: 14px !important;
    color: #DDD !important;
}

    .resetText:hover {
        text-decoration: none !important;
    }

.btn-sm {
    line-height: 2 !important;
}

.Portal {
    margin-left: 25px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
}

.whiteText {
    color: white !important;
}
/*-----------helpers--------------*/
.mb0 {
    margin-bottom: 0px !important;
}

.mb12 {
    margin-bottom: 12px !important;
}

.mt-2p {
    margin-top: 2px !important;
}

.mt-8p {
    margin-top: -8px !important;
}

.h60 {
    height: 60px !important;
}

    .h60.navbar {
        height: unset !important;
    }

tspan {
    font-family: Roboto !important;
}

.h85 {
    height: 85px !important;
}

.mt-160 {
    margin-top: 160px !important;
}

.mt-120 {
    margin-top: 120px !important;
}

.mt-13 {
    margin-top: 13px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-1px {
    margin-left: 1px !important;
}

.ModelPaper2.m10mr10.EmailViewModal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 38%;
}

.ml-10 {
    margin-left: -10px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml8 {
    margin-left: 8px !important;
}

.w18 {
    width: 18px;
}

.h-100 {
    height: 100% !important;
}

.d-flex {
    display: -webkit-box !important;
    display: flex !important;
}

.text-center {
    text-align: center !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mb100 {
    margin-bottom: 100px !important;
}

.mb50 {
    margin-bottom: 50px !important;
}

label.subMenuTitleSettings {
    font-size: 24px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mt100 {
    margin-top: 100px !important;
}

.mt60 {
    margin-top: 60px !important;
}

a.btn-floating, i.iconChevron {
    color: #889098 !important;
    background: #f5f6fa;
    padding: 8px;
    border-radius: 5px !important;
    height: 30px;
    margin: auto;
}

i.fa.fa-chevron-left.iconChevronFilter, i.fa.fa-chevron-right.iconChevronFilter {
    color: #889098 !important;
    background: #f5f6fa;
    padding: 8px;
    border-radius: 5px !important;
    height: unset !important;
    margin: unset;
}

.mb150 {
    margin-bottom: 150px !important;
}

.mb200 {
    margin-bottom: 200px !important;
}

body .signin, body .externalPages {
    background-color: transparent !important;
}

.mt150 {
    margin-top: 150px !important;
}

.mt60 {
    margin-top: 60px !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-6 {
    margin-top: 4.5rem !important;
}

.d-flex {
    display: -webkit-box !important;
    display: flex !important;
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
}

.width17 {
    width: 17px !important;
}

button#UserActions, button#UserActions2 {
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 14px;
    padding-left: 0px !important;
}

.BodyModal {
    height: 94%;
    overflow: hidden;
}

.BodyModalGraph {
    position: relative !important;
    margin-bottom: 10px !important;
    padding: 10px 10px 0px 10px;
    overflow: auto !important;
    overflow-x: hidden !important;
    flex-grow: 1 !important;
    height: 92%;
    overflow: hidden;
}

.ProfileDropdwon.logoutBtnDrop.dropdown-menu.dropdown-menu-right.show {
    width: 100% !important;
}

span.rangePicker.FilterRange.ant-calendar-picker > span.ant-calendar-picker-input.ant-input,
span.rangePicker.FilterRange.ant-calendar-picker > span.ant-calendar-picker-input.ant-input:focus,
span.rangePicker.FilterRange.ant-calendar-picker > span.ant-calendar-picker-input.ant-input:hover
span.rangePicker.FilterRange.ant-calendar-picker > .ant-input:hover {
    border: 1px solid transparent;
    box-shadow: unset !important;
    outline: unset !important;
    background-color: transparent !important;
}

.flex-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
}

.ml30mr30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.mainExternal.signin.heightSighnIn.extraHeight {
    min-height: 100vh !important;
}

.ProfileFooter {
    font-size: 14px !important;
    color: #999999 !important;
    text-align: center !important;
    margin-top: 10px !important;
    margin-bottom: -20px !important;
    font-weight: 500 !important;
}

.ProfileFooter2 {
    font-size: 14px !important;
    color: white !important;
    text-align: center !important;
    margin-top: 10px !important;
    margin-bottom: -20px !important;
    font-weight: 500 !important;
}

.copyright {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    color: #00a0df !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.checkIcon {
    width: 30px !important;
    height: 30px !important;
}

.AlertIcon {
    width: 30px !important;
    height: 30px !important;
}

.cardBack {
    background: #f1f1f8 !important;
}

.h150 {
    height: 150px !important;
}

.h400 {
    height: 400px !important;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.height100Vh {
    min-height: 100vh !important;
}

.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
}

div#slides {
    height: 260px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.imgSlider {
    padding: 20px !important;
    height: 240px;
}

.ibbannersimgs {
    height: 100% !important;
    overflow: hidden;
}

.ml25 {
    margin-left: 25px !important
}

.mr10 {
    margin-right: 10px !important;
}

.carousel-indicators li {
    background-color: #83d7f5 !important;
}

    .carousel-indicators li.active {
        background-color: #00a0df !important;
    }

.main {
    width: 100% !important;
    min-height: 100% !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: hidden;
    min-height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
}

.mainExternal {
    width: 100% !important;
    min-height: 100vh !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: auto;
    min-height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
/*inside normalsetting*/
.insideMain3 {
    width: 100% !important;
    min-height: 100% !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100vh - 270px);
}

.insideMain2, .insideMain {
    width: 100% !important;
    min-height: 100% !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100vh - 240px);
    min-height: calc(100vh - 240px);
}

.insideDash {
    width: 100% !important;
    min-height: 100% !important;
    min-width: 0;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    min-height: calc(100vh - 150px);
}

.insideRightSidebar {
    width: 100% !important;
    min-height: 94% !important;
    height: 94% !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100vh - 110px);
}

.insideRightSidebarIBBanner {
    width: 100% !important;
    min-height: 98% !important;
    height: 98% !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
}

.insideSettings {
    width: 100% !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);
}

.insideAccounts {
    max-width: 100% !important;
    min-width: 0;
    -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);
}

svg.svg-inline--fa.fa-ellipsis-v.fa-w-6.cursor-pointer.moreIcon {
    color: #051c2c !important;
}

h1.PageNotFound {
    color: #000000;
    font-size: 50px;
    font-weight: 500;
}

p.PagenotFoundText {
    color: #121212;
    font-size: 18px;
}

.react-select.cursor-pointer.noborderApp.__input > input {
    border: 1px solid transparent !important;
}

.redux-toastr .toastr .rrt-middle-container {
    text-align: center !important;
}

html [data-useragent*='MSIE 10.0'] .main {
    width: calc(100% - 250px);
}

@media (max-width: 1200px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .container {
        max-width: 100%;
    }
}

.wrapper {
    -webkit-box-align: stretch;
    align-items: stretch;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.rdw-editor-toolbar {
    border-radius: 4px;
    background: transparent !important;
}

.public-DraftStyleDefault-block {
    margin: 0.5em 0 !important;
}

.demo-editor.rdw-editor-main {
    height: 100%;
    min-height: 300px;
    border-radius: 4px;
}

/*#endregion*/
.form-control-lg, form-control {
    height: calc(0.85em + 1rem + 2px) !important;
    font-size: 1rem !important;
}

.ant-tabs-bar.ant-tabs-top-bar {
    border-bottom: transparent !important
}

.TabRightSidebar > div.ant-tabs-bar.ant-tabs-top-bar {
    border-bottom: 1px solid #eee !important;
    background: white !important;
    position: initial;
    width: 100%;
    height: 37px !important;
    width: 100% !important;
}

input, select, textarea, .form-control {
    border: 1px solid #ddd !important;
    border-radius: 0px !important;
}

    inpu:focus, select:focus, textarea:focus, .form-control:focus {
        border: 1px solid #00a0df !important;
        box-shadow: 0 0 0 0.2rem transparent !important;
        outline: unset !important;
    }

.textBlue {
    color: #00a0df !important;
}

.whiteBackgroundBox.commentAppForm {
    border: 1px solid #00a0df !important;
    border-radius: 5px;
}

.FilterInput:focus, .loginButton:focus, .MultiplierBtn:focus {
    border: 1px solid transparent !important;
    background: transparent !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem transparent !important;
}

svg.svg-inline--fa.fa-user-tie.fa-w-14.iconCards.commentIcon {
    background: white !important;
    border: 1px solid #00a0df !important;
    border-radius: 50% !important;
    padding: 17px;
    color: #00a0df !important;
}

.svg-inline--fa.fa-w-12 {
    width: 0.9em !important;
}

svg.svg-inline--fa.fa-user.fa-w-14.text-center.userInvestor,
svg.svg-inline--fa.fa-file-code.userInvestor,
svg.svg-inline--fa.fa-file-alt.userInvestor {
    font-size: 40px !important;
    background: #889098 !important;
    height: 35px;
    border: 1px solid transparent !important;
    border-radius: 50% !important;
    padding: 9px;
    color: white !important;
    opacity: 0.8 !important;
}

span.rangePicker.FilterRange.mr-2.borderDate.ant-calendar-picker {
    border: 1px solid #00a0df !important;
}

.ant-tabs.ant-tabs-top.accounts.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar > .ant-tabs-nav-container {
    background: transparent !important;
}

.ant-tabs.ant-tabs-top.accounts.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar {
    position: relative !important;
}

input#year {
    width: 65px;
    font-size: 14px !important;
}

input#taskName, input#eacheachtaskName {
    font-size: 14px !important;
}

.ant-switch:focus {
    box-shadow: unset !important;
    border: 1px solid transparent !important;
    outline: unset !important;
}
/*#region Helpers*/
.p-0 {
    padding: 0 !important;
}

.p20 {
    padding: 20px;
}

.m-0 {
    margin: 0 !important;
}

.h25 {
    height: 25px !important;
}

.h30 {
    height: 30px !important;
}

.h40 {
    height: 40px !important;
}

.h-w90 {
    height: 86px !important;
    width: 90px !important;
}

.h45 {
    min-height: 45px;
}

.h50 {
    height: 50px !important;
}

a.ant-breadcrumb-link {
    padding: 8px !important;
}

.h100vh {
    min-height: 100vh !important;
}

.h90vh {
    min-height: 90vh !important;
}

.dividerprofile {
    min-height: 12vh !important;
    margin-right: -8px !important;
}

.h96vh {
    min-height: 96vh !important;
}

.h100vh-40 {
    min-height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
}

.h100vh-70 {
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    overflow-y: auto;
}

.h100vh-80 {
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
}

.h100vh-90 {
    overflow: auto !important;
    min-height: calc(100vh - 87px);
    max-height: calc(100vh - 90px);
    overflow-y: auto;
}

.h100vh-100 {
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.h100vh-120, .h100vh-120.upperMenu.topbarBottom.overflowhidden {
    min-height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
    overflow: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.h100vh-140 {
    min-height: calc(100vh - 140px);
    max-height: calc(100vh - 140px);
    overflow-y: auto;
}

.bottom0 {
    bottom: 0 !important;
}

.bottom2 {
    bottom: 2px !important;
}

.darkMenu {
    background-color: #254158;
}

.darkMenu2 {
    background-color: #001529 !important;
}

.ant-avatar.avatar.ant-avatar-circle {
    width: 30px;
    height: 30px;
    font-size: 22px;
}

.whiteBackgroundBox {
    padding: 20px !important;
}

.css-1rhbuit-multiValue.react-select.cursor-pointer.__multi-value {
    border-radius: 5px !important;
    background: #00a0df !important;
    color: white !important;
}

span.ant-avatar.avatar3.ant-avatar-circle {
    margin-left: -9px !important;
    margin-top: -9px !important;
    width: 200px;
    height: 200px;
    font-size: 35px;
    background: #889098 !important;
    color: white !important;
    padding: 35px !important;
}

.ant-input {
    border-radius: 5px !important;
}

input.ant-input:focus {
    box-shadow: unset !important;
}

span.ant-avatar.avatar2.ant-avatar-circle {
    margin-left: -9px !important;
    margin-top: -9px !important;
    width: 50px;
    height: 50px;
    font-size: 25px;
    background-color: #00a0df;
}

span.ant-avatar.avatar2.commentAvatar.ant-avatar-circle {
    background: #889098 !important;
    opacity: 0.6 !important;
}

div#CommentSummary {
    max-height: 300px !important;
    height: 100% !important;
    overflow: hidden;
    overflow-y: auto;
}

svg.UserAvatar {
    font-size: 110px !important;
}

.paymentIcons {
    width: 60%;
    height: auto;
}

.avatar > span.ant-avatar-string {
    bottom: -11% !important;
    padding-bottom: 1px;
}

.white-bg {
    color: white;
}

.ml-23 {
    margin-left: -23px !important;
}

.ml-19 {
    margin-left: -19px !important;
}

.ml-28 {
    margin-left: -20px !important;
}

.ant-menu-inline-collapsed {
    width: 60px !important;
}

.ContactusColor {
    font-size: 16px;
    margin-left: 23px;
    font-weight: 400;
}

.ContactusColor2 {
    font-size: 17px;
    margin-left: 25px;
    font-weight: 400;
}

.w70 {
    width: 70px !important;
}

.ClientInfoResult.mt-3 {
    margin-top: 10px !important;
    margin-right: 10px !important;
    padding: 20px !important;
    background: white !important;
}

span.datePicker.TaskDate2.TaskInTab.ant-calendar-picker > div > .ant-input[disabled] {
    border: transparent !important;
    background: transparent;
    margin-left: -10px !important;
    color: #889098 !important;
}
/*editor note */
.demo-editor.rdw-editor-main {
    padding: 10px !important;
}

.rdw-editor-toolbar {
    align-content: center;
    text-align: center;
    padding: 10px;
}

.rdw-block-wrapper, .rdw-fontsize-wrapper, .rdw-fontfamily-wrapper, .rdw-list-wrapper, .rdw-text-align-wrapper, .rdw-colorpicker-wrapper, .rdw-link-wrapper, .rdw-embedded-wrapper, .rdw-emoji-wrapper, .rdw-image-wrapper, .rdw-remove-wrapper, .rdw-history-wrapper {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 5px;
}

.rdw-option-wrapper, .rdw-inline-wrapper {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 5px;
}

label.d-block.CheckboxTitle.ant-checkbox-wrapper > .ant-checkbox > span.ant-checkbox-inner {
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    background: #ddd;
}

.mt40 {
    margin-top: 40px !important
}

.mb40 {
    margin-bottom: 40px !important
}

.w40 {
    width: 40px;
}

.w100 {
    width: 100px !important;
}

.w140 {
    width: 140px !important;
}

.w170 {
    width: 165px !important;
}

.w100p {
    width: 100% !important;
}

.w90p {
    width: 90% !important;
}

.w80p {
    width: 80% !important;
}

.w20p {
    width: 20% !important;
}

.w50p {
    margin-left: 50% !important;
    width: 50% !important;
}

.backwhite {
    background: white !important;
}

.w90p {
    width: 90% !important;
}

.w66p {
    width: 66% !important;
}

.custom-file-label {
    z-index: 0 !important;
}

button.ant-modal-close {
    border: none !important;
}

ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
    margin-top: 35px !important;
}

.mt35 {
    margin-top: 35px !important;
}

input.editedInput {
    background: transparent;
    border: unset !important;
    font-size: 16px !important;
    color: #889098 !important;
}

    input.editedInput:focus, input.editedInput:active {
        background: white;
        border: 1px solid #ddd;
    }

li.ant-menu-item.MenuItemsSub, li.ant-menu-item.MenuItemsSub:hover {
    padding-left: 30px !important;
}

    li.ant-menu-item.MenuItemsSub.ant-menu-item-selected {
        padding-left: 27px !important;
    }

.h12 {
    height: 12px !important;
}

.menuItemsToggle {
    width: 15px;
    stroke-width: 2px;
}

.menuItemsIconsSub {
    width: 16px;
    stroke-width: 2px;
}

.menuItemsNew {
    width: 18px;
    stroke-width: 2px;
}

.menuItemsNewSig {
    width: 18px;
    stroke-width: 2px;
}

.mt1 {
    margin-top: 1px !important;
}

.mt2 {
    margin-top: 1px !important;
}

.menuSettings {
    margin-left: 8px;
    width: 16px;
    stroke-width: 2px;
}

.menuSettings2 {
    margin-left: 8px;
    width: 20px;
    stroke-width: 2px;
}

.menuItemsReset {
    width: 14px;
    stroke-width: 2px;
}

.menuItemsNewib {
    width: 23px;
    height: 23px;
    stroke-width: 2px;
}

.m-10 {
    margin: 10px !important;
}

.br50p {
    border-radius: 50%;
}

.white-c {
    color: #bcc2c7;
}

.m10 {
    margin: 10px;
}

.mt40 {
    margin-top: 40px !important
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt30 {
    margin-top: 30px !important;
}

.bl1gray {
    border-left: 1px solid #666666;
}

html[dir="ltr"] .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop {
    left: 64px !important;
}

.mt5 {
    margin-top: 5px !important;
}

.mtm3 {
    margin-top: -3px;
}

.mt2 {
    margin-top: 2px;
}

.w600 {
    width: 600px
}

.ml300 {
    margin-left: 300px !important;
}

.ml30 {
    margin-left: 30px !important;
}

.mt50 {
    margin-top: 50px !important;
}

.mr30 {
    margin-right: 30px !important;
}

.horzOverFlowHidden {
    overflow-x: hidden !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent !important;
    color: #000000;
}

.verOverFlowHidden {
    overflow-y: hidden !important;
}

.ThemeIcons {
    width: 50px !important;
    cursor: pointer;
    transition: all 1s;
}

.h33 {
    height: 33px
}

.mL-10 {
    margin-left: -10px !important;
}

.h30 {
    height: 30px
}

.ml17 {
    margin-left: 17px;
}

.ml7 {
    margin-left: 7px;
}

.w165 {
    width: 160px;
}

.left74 {
    left: 74px;
}

.left179 {
    left: 179px;
}

.left185 {
    left: 185px;
}

.left218 {
    left: 218px;
}

.left244 {
    left: 244px;
}

.left230 {
    left: 240px;
}

.YLogo {
    /*width: 40px !important;*/
    height: 40px !important;
}

.YLogo2 {
    width: 30px !important;
    height: 30px !important;
}

.SettingIcon {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-top: 14px;
}

.ant-notification {
    z-index: 1100 !important;
}

.ProfileDropdwon2.dropdown-menu.dropdown-menu-right.show {
    width: 100px !important;
}

.dropdown-menu {
    min-width: 8rem;
}

.dropdown-item {
    font-size: 14px;
}

.react-select.cursor-pointer.select-actionBar.__control.css-yk16xz-control:hover {
    border-radius: 0px !important;
}

li.ant-menu-item.MenuItemsSub {
    padding-left: 30px !important;
}

div.dataTables_wrapper div.dataTables_length select {
    border: 1px solid #00a0df !important;
    height: 30px !important;
    width: 50px !important;
    font-size: 13px !important;
    padding: 6px;
}

a.page-link {
    height: 30px !important;
    padding: 7px;
    margin-top: 1px;
}

.w176-ml5 {
    margin-left: -10px;
    width: 260px !important;
}

.ml-13 {
    margin-left: -10px !important;
}

.w200 {
    width: 200px !important;
}

.w220 {
    width: 220px !important;
}

input#eachtaskName {
    border: transparent !important;
}

.w20 {
    width: 20px !important;
}

.w18 {
    width: 18px !important;
}

.posFixed {
    position: fixed;
}

.h34 {
    height: 34px;
}

.pt5 {
    padding-top: 5px;
}

.mtm1 {
    margin-top: -1px;
}

.floatRight {
    float: right !important;
}

.TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
    margin-left: 5px;
    width: 260px;
    position: sticky;
    top: 0;
    left: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    font-size: 16px !important;
}

.TabsUser > div > div .ant-tabs-nav {
    height: 100vh !important;
}

.bodymodalFlow {
    overflow-y: auto !important;
    overflow: auto !important;
}

input.PercentageInput {
    height: 25px !important;
    width: 80px !important;
    margin-top: 5px;
}

button#Multipliers {
    width: 56px;
    font-size: 12px;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser {
    position: absolute;
    padding: 5px;
    width: 68%;
    height: 80%;
    overflow-y: auto;
    overflow: auto;
    overflow-x: hidden;
}

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab {
        width: 78%;
        overflow-x: hidden;
    }

.ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser2.widerTab {
    width: 78%;
    overflow-x: hidden;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab2 {
    width: 78%;
    height: 80%;
    overflow-x: hidden;
}

.titleFinish {
    font-size: 16px;
    font-weight: 500 !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser2 {
    position: absolute;
    padding: 5px;
    width: 70%;
    height: 100%;
    overflow-y: auto;
    overflow: hidden;
}

.ant-tabs-tab-active.ant-tabs-tab, .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left !important;
}

textarea#notePrivilege {
    width: 500px !important;
}

.reviewTitle {
    color: #1ca8dd;
    font-weight: 500;
    font-size: 18px !important;
    margin: 0px 0px 15px !important;
    padding: 0 15px;
}

.reviewTitle2 {
    font-weight: 400 !important;
    color: #889098 !important;
    font-size: 14px !important;
    margin: 0px 0px 15px !important;
    padding: 0 15px;
}

.dataApplicationform {
    margin-left: 10px !important;
    margin-bottom: 10px !important;
}

.ScrollPage {
    overflow: hidden;
    height: 18%;
    overflow-y: auto;
}

span.minNmaxWidth100p.br4.bxShInset.ant-input-affix-wrapper > input {
    margin-left: 52px;
    width: 90%;
    height: 35px !important;
}

/*.horzOverFlowYXHidden {
    height: 100% !important;
    overflow: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}*/

.horzOverFlowYXHidden2 {
    height: 97% !important;
    overflow: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.OverflowDrawer {
    height: calc(100vh - 200px) !important;
    overflow: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

text.highcharts-credits {
    display: none !important;
}

table.tableContact.table {
    background: white;
    margin: 10px !important;
    margin-left: 0px !important;
    width: 98% !important;
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108));
}

.MuiPaper-elevation1 {
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108)) !important;
}

.reviewLabels {
    font-size: 14px !important;
    padding: 0px 30px 0px 5px !important;
    font-weight: 400 !important;
}

.resultLabels {
    font-size: 14px !important;
    color: #999999 !important;
    padding: 0px 30px 0px 5px !important;
    font-weight: 400 !important;
}

.CollapseBtn {
    display: block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    color: #DDD !important;
    width: 30px !important;
    height: 30px;
}

.Model.SemiModal {
    background-color: rgba(0, 0, 0, 0) !important;
}

.ShareBtn {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    color: rgba(0, 0, 0, 0.54) !important;
    width: 20px !important;
    height: 15px !important;
}

.ant-input {
    height: 35px !important;
}

button:focus, svg.MuiSvgIcon-root:focus {
    outline: 1px solid transparent !important;
}

.whiteBackground {
    background-color: white !important;
}

.bluefont {
    color: #00a0df !important;
}

.mr5 {
    margin-right: 5px;
}

button#Languagedrop, button#Languagedrop:focus, button#Languagedrop:active {
    border: 1px solid transparent !important;
    background: transparent !important;
}

a.eachLangugae.dropdown-item {
    display: table-cell !important;
}

.mr200 {
    margin-right: 200px;
}

nav.toparExternal {
    background: #e4e4e4 !important;
}

img.picDemo {
    height: 430px !important;
}

.ml200 {
    margin-left: 200px;
}

.mr320 {
    margin-right: 320px;
}

span.ant-avatar.avatar.avatarUser.ml-4.mb-2.avatarAdmin.ant-avatar-circle {
    background-color: #889098 !important;
}

.ml320 {
    margin-left: 320px;
}

.positionCenter {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.greenConnect {
    color: #83e11f;
}

.redDanger {
    color: red;
}

.fs13 {
    font-size: 13px;
}

.fw100 {
    font-weight: 100;
}

.fw300 {
    font-weight: 300 !important;
}

.fw400 {
    font-weight: 400 !important;
}

.fw500 {
    font-weight: 500 !important;
}

.fw600 {
    font-weight: 500;
}
.fw700 {
    font-weight: 700 !important;
}
.blueLabel {
    color: cornflowerblue !important;
}

.btn-primary {
    color: #fff;
    background-color: #00a0df !important;
    border-color: #00a0df !important;
}

.hoverSitemap:hover {
    color: #83e11f
}

.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input {
    padding-left: 10px !important
}

div.e-datepicker.e-popup-wrapper.e-lib.e-popup.e-control.null.e-popup-open {
    height: 300px !important;
    z-index: 1400 !important;
}

span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input:focus,
span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input:active,
span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input:hover,
.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover, .e-input-group.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
.e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover, .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]), .e-float-input.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover, .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    border-color: transparent !important;
    border-bottom-color: transparent !important;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
    color: black !important;
}

input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
    font-family: Roboto !important;
}

.iconNes {
    color: #e64759;
}

.hoverIcon {
    cursor: pointer;
}

.nohoverIcon {
    cursor: default;
}

.backiconGet {
    width: 17px;
    margin-right: 3px;
    margin-bottom: 3px !important;
}

i.anticon.anticon-close.ant-tabs-close-x {
    display: none;
}

img.congratsIcon {
    width: 40px;
}

span.ant-breadcrumb-link, .ant-breadcrumb a, .ant-breadcrumb-separator {
    color: white !important;
}

.navigationText.cursor-pointer:hover {
    background: #f0f0f0;
}

.navigationText.cursor-pointer {
    height: 26px;
    padding: 4px;
    color: #ababab !important;
}

.seperatorNavigation {
    color: #ababab !important;
}

.m55 {
    margin-left: 55px !important;
}

.mlm10 {
    margin-top: -10px !important;
}

.light-white-color {
    color: #ffffff !important;
}

.w320 {
    width: 320px !important;
}


.mr18 {
    margin-right: 18px;
}

.mr30 {
    margin-right: 30px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.bbsettingsBar {
    border-bottom: 1px solid #e2e9ec;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00a0df !important;
    background-color: #00a0df !important;
}

label.titleLeftsidebar {
    color: #889098 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

label.answerLefsidebar {
    color: #889098 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.EachRightColumn.col-md-10 {
    height: 100vh !important;
}

label.custom-control-label.labelRadio.RadioLabel::before {
    position: absolute;
    top: 0.16rem;
    left: -1.5rem;
    display: block;
    margin-top: 10px !important;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #dee2e6;
    border: #adb5bd solid 1px;
}

label.custom-control-label.labelRadio.RadioLabel::after {
    position: absolute;
    top: 0.84rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.custom-control-input.roundRadio:checked ~ .custom-control-label.roundRadio::before {
    border-radius: 50% !important;
}

.ant-form-item-label > label::after {
    content: '' !important;
}

.mlm21 {
    margin-left: -21px;
}

/*::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
    background-color: #00a0df !important;
}*/

.fullPink {
    background: #ff6cd975 !important;
    border: #ff00b175 !important;
}

.transparentBtn {
    background: transparent;
    border: none;
}

    .transparentBtn:hover {
        background-color: #e1f3fa;
        cursor: pointer;
    }

    .transparentBtn:focus {
        outline: none;
    }

.mr8 {
    margin-right: 8px;
}

.floatLeft {
    float: left !important;
}

.pad2em {
    padding: 2.5em !important;
}

.pad1em {
    padding: 1.5em !important;
}

.LabelCreate {
    font-size: 14px;
    font-weight: 500;
}

.mxw300 {
    max-width: 300px;
}

select.selectpicker, select.selectpicker:focus, select.selectpicker:hover, select.selectpicker :active {
    margin-top: 20px !important;
    border-color: transparent !important;
}

.w300 {
    width: 300px !important;
}

.w30 {
    width: 30px !important;
}

.mt40 {
    margin-top: 40px !important;
}

.mt45 {
    margin-top: 45px !important;
}

.mh1000 {
    min-height: 1000px;
}

.mh100vh {
    min-height: 100vh;
}

.mh100vhm70 {
    min-height: calc(100vh - 70px);
}

/*.mh100vhm140 {
    min-height: calc(100vh - 140px);
}*/

.pb120 {
    padding-bottom: 120px !important;
}
/*fix the tabs*/
.ant-tabs-bar.ant-tabs-top-bar {
    position: fixed;
    z-index: 1;
    margin-bottom: 0 !important;
    width: 100%;
}

.demo-editor.rdw-editor-main {
    margin-top: 10px;
    border: 1px solid #eeeeee;
    margin-bottom: 10px;
}

textarea {
    width: 100%;
}

.ButtonsBottom {
    min-height: 64px;
    overflow: hidden;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
}

.ButtonsBottom2 {
    float: left;
    margin-left: 313px;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    bottom: 50px;
}

.ButtonsBottom3 {
    float: left;
    margin-left: 313px;
    background-color: white !important;
    position: -webkit-sticky;
    position: sticky;
    width: 66% !important;
    bottom: 5px;
    height: 35px !important;
}

.ButtonsBottom4 {
    position: sticky;
    right: 9px;
    bottom: 9px;
}

.ButtonsBottomDelete {
    position: sticky;
    right: 9px;
    bottom: 9px;
}

.buttonsFinish {
    width: 100% !important;
}

.iconsStyleSignin {
    padding-left: 0px !important;
    width: 40px !important;
}

.ButtonsModal {
    font-weight: 500;
    color: #00a0df;
    background: #f0f0f0;
    border: transparent;
    border-radius: 0;
}

.m80 {
    margin-right: 80px !important;
}

.iconCards {
    height: 75px !important;
    width: 75px !important;
}

dialog.dialog.promotionImage {
    max-width: unset !important;
    width: fit-content;
    border: 1px solid #ddd !important;
}

.iconPercentage, .iconPromotion {
    height: 90px !important;
    width: 90px !important;
}

.promotion-container {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    background: rgba(5, 28, 44, .5);
}

.promotion-container-white {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    background: white;
}

.WalletCard:hover, .WalletCardSelected {
    border-radius: 5px !important;
    outline: 8px solid #a3ddf4 !important;
}

.checkedPayment.backgroundNewCards.cursor-pointer {
    border: 4px solid #00a0df !important;
}
/*.checkedPayment.backgroundNewCards.cursor-pointer, .uncheckedPayment.backgroundNewCards.cursor-pointer {
    width: 230px !important;
}*/
svg.svg-inline--fa.fa-check-circle.fa-w-16.checkedPayment {
    font-size: 40px;
    color: #00a0df !important;
}

svg.svg-inline--fa.fa-check-circle.fa-w-16.checkedPayment2 {
    font-size: 30px;
    color: #00a0df !important;
}

svg.svg-inline--fa.fa-check-circle.fa-w-16.uncheckedPayment {
    font-size: 40px;
    color: #889098 !important;
}

svg.svg-inline--fa.fa-check-circle.fa-w-16.uncheckedPayment2 {
    font-size: 30px;
    color: #889098 !important;
}

button.circularButtons.btn.btn-secondary {
    width: 50px;
    height: 50px;
    background-color: darkcyan !important;
    border-color: darkcyan !important;
    font-size: 20px;
    border-radius: 50%;
    padding: 0;
    color: white !important;
}

    button.circularButtons.btn.btn-secondary:hover {
        width: 50px;
        height: 50px;
        background-color: darkcyan !important;
        border-color: darkcyan !important;
        font-size: 20px;
        border-radius: 50%;
        padding: 0;
        color: white !important;
    }

button.circularButtons2.btn.btn-secondary {
    width: 50px;
    height: 50px;
    background-color: darkgreen !important;
    border-color: darkgreen !important;
    font-size: 20px;
    border-radius: 50%;
    padding: 0;
    color: white !important;
}

    button.circularButtons2.btn.btn-secondary:hover {
        width: 50px;
        height: 50px;
        background-color: darkgreen !important;
        border-color: darkgreen !important;
        font-size: 20px;
        border-radius: 50%;
        padding: 0;
        color: white !important;
    }

.ButtonsModal2, .ButtonsModal2:hover, .ButtonsModal2:active, .ButtonsModal2:focus {
    font-weight: 500;
    color: white;
    background: #00a0df;
    border: transparent;
    border-radius: 0;
}

.BackBtn {
    height: 40px;
    padding: 5px;
    width: 140px;
    font-weight: 500;
    background: #008dcc;
    color: white;
    border: transparent;
    border-radius: 0;
}

button.btn-secondaryApp.disabled, .btn-secondary.disabled {
    font-weight: 500;
    border-radius: 0px;
    cursor: pointer;
}

.BackBtn2 {
    width: 180px;
    font-weight: 500;
    background: #008dcc;
    color: white;
    border: transparent;
    border-radius: 0;
}


.btn-secondary:not(:disabled):not(.disabled):active {
    color: initial;
    background-color: transparent;
}
/*that color is gray check color want and add name */
.bg-lightblue {
    background-color: white;
}

.titleBorderSetting {
    border-bottom: 1px solid #d0cfcf;
    width: 60%;
    padding: 10px 0px;
}

.mw60p {
    min-width: 60%;
}

.mw70p {
    min-width: 70%;
}
/*min and max */
.minNmaxWidth70p {
    min-width: 70%;
    max-width: 70%;
}

.minNmaxWidth100p {
    min-width: 100%;
    max-width: 100%;
}

.br4 {
    border-radius: 4px !important;
}

.mlm5 {
    margin-left: -5px;
}

.mtd10 {
    margin: 10px 0px;
}

.pademTitle {
    padding: 1.5em 0.5em 0em 3.5em !important
}

.grayBackSettings {
    background-color: #f4f6f7 !important;
}

.borderBGray {
    border-bottom: 1px solid #d0cfcf;
}

span.SearchInput.ant-input-affix-wrapper .ant-input {
    background-color: transparent !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 1px;
    height: 32px !important;
    width: 200px !important;
}

span.hoverIcon.backHover {
    font-weight: 400;
    height: 40px !important;
    padding: 6px;
}

.border-danger {
    border-radius: 4px;
}

span.SearchInput.ant-input-affix-wrapper {
    width: 200px !important;
    height: 32px;
    padding: -5px;
    margin-top: 9px;
}

button.AddButtonsUser.btn.btn-secondary {
    background: transparent;
    font-size: 16px !important;
    color: #121212;
    border: transparent;
    padding: 4px !important;
}

    button.AddButtonsUser.btn.btn-secondary:focus, button.AddButtonsUser.btn.btn-secondary:hover {
        background: #e2e2e2;
        font-size: 16px !important;
        color: #121212;
        border: transparent;
        padding: 4px !important;
    }

span.datePicker.TaskDate.ant-calendar-picker > div input {
    height: 30px !important;
}

svg.svg-inline--fa.fa-circle.fa-w-16.circleNotifications {
    height: 10px;
}

button.ButtonsModal.AddBtn.btn.btn-secondary.disabled, button.ButtonsModal.AddBtn.btn.btn-secondary.disabled:hover {
    cursor: not-allowed !important;
    opacity: 0.65 !important;
}

button.ButtonsModal.btn.btn-secondary, button.ButtonsModal, button.ButtonsModal.btn.btn-primary, button.ButtonsModal.btn.btn-secondary:focus, button.ButtonsModal:focus, button.ButtonsModal.btn.btn-primary:focus {
    border-radius: 5px;
    font-size: 15px !important;
}

    button.ButtonsModal.btn.btn-secondary:hover, button.ButtonsModal:hover, button.ButtonsModal.btn.btn-primary:hover {
        color: white !important;
        border-radius: 5px;
        opacity: 1 !important;
    }

    button.ButtonsModal.btn.btn-secondary:active, button.ButtonsModal:active, button.ButtonsModal.btn.btn-primary:active {
        border-radius: 5px;
        opacity: 1 !important;
    }

button.ButtonsClicked.btn.btn-secondary, button.ButtonsClicked, button.ButtonsClicked.btn.btn-primary,
button.ButtonsClicked.btn.btn-secondary:focus, button.ButtonsClicked:focus, button.ButtonsClicked.btn.btn-primary:focus,
button.ButtonsClicked.btn.btn-secondary:active, button.ButtonsClicked:active, button.ButtonsClicked.btn.btn-primary:active {
    border-radius: 5px;
    font-size: 15px !important;
}

.ant-input {
    font-family: inherit !important;
}
/*react select in settings settingsObjects*/
.react-select.cursor-pointer.SelectsStyle.settingsObjects__control.css-yk16xz-control {
    min-width: 70% !important;
    max-width: 70% !important;
    white-space: normal;
}

.filtrtBtn {
    color: black;
}

input .DescInput {
    background: transparent !important;
}

.ApplicationformTab > .ant-tabs-bar.ant-tabs-top-bar {
    width: 87% !important;
}

.ApplicationformTab2 > .ant-tabs-bar.ant-tabs-top-bar {
    width: 70% !important;
}

.TabsInSettings > .ant-tabs-bar.ant-tabs-top-bar {
    width: 100% !important;
    position: sticky !important;
}

.bxShInset {
    box-shadow: inset 0 2px 4px 0 hsla(0,0%,0%,0.08);
}

svg.trashLogo {
    cursor: pointer;
    color: #889098 !important;
}

img.logoimgCompany {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 80%;
    max-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.UploadLogoBtn.btn.btn-secondary, button.UploadLogoBtn.btn.btn-secondary:focus, button.UploadLogoBtn.btn.btn-secondary:active, button.UploadLogoBtn.btn.btn-secondary:hover {
    background: transparent;
    color: #00a0df !important;
    border: unset !important;
    box-shadow: unset !important;
    outline: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
}

img.logoimgCompany2 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 56px !important;
}

button.p-node-toggler.p-link {
    border: 1px solid transparent;
    background: transparent;
    margin: 2px;
}

.p-organizationchart .p-organizationchart-table > tbody > tr > td {
    padding-left: 13px;
}

.lightThemeBackground {
    width: 100% !important;
    border: 1px dashed #889098 !important;
    height: 180px;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightThemeBackground2 {
    width: 100% !important;
    border: 1px dashed #889098 !important;
    height: 60px;
    background: #fafafafa;
    display: flex;
    align-items: center;
    justify-content: center;
}


.darkThemeBackground {
    width: 100% !important;
    border: 1px dashed #889098 !important;
    height: 180px;
    background: #121212 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-cream {
    background: #f9f9f9;
}

.mt60 {
    margin-top: 60px !important;
}

.mt40 {
    margin-top: 40px !important;
}
/*#endregion*/
/*#region Menu*/


.r7 {
    position: absolute;
    right: 7px;
}

.bottomMenu8 {
    position: absolute;
    bottom: 8px;
}

.bottomMenu20 {
    position: absolute;
    bottom: 16px;
    left: 5px;
}

.bottomMenu40 {
    position: absolute;
    bottom: -20px;
}

.ant-menu-dark .ant-menu-item-selected {
    /*color: #dc3545;*/
    border-right: 0;
}

.xHoverIcon {
    height: 30px;
    text-align: center;
    width: 30px;
}

.xHoverIcon2 {
    height: 26px;
    text-align: center;
    width: 24px;
}

.topbar-dark {
    background: #203D52;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #203d52 !important;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

a.nav-link {
    color: #bcc2c7 !important;
}


.menuIcon {
    margin-right: 17px !important;
}

.colmenuIcon {
    margin-right: 36px !important;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover {
    color: rgb(205,222,0);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-submenu-open .ant-menu-active {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
    background-color: transparent;
}

button#UserActions:hover, button#UserActions2:hover {
    height: 31px !important;
}

.bottomMenuPortalSettings {
    position: absolute;
    top: 0;
}


.rowStyle { /*top of menu*/
    border-bottom: 1px solid;
    width: 285px;
    max-width: 106%;
    background-color: #58a2c2;
    padding: 12px 0px;
    font-size: 13px;
    font-weight: 500;
}

.h100vh-90 {
    overflow: hidden;
    min-height: calc(100vh - 87px);
    max-height: calc(100vh - 90px);
    overflow-y: auto;
}

.greybordertab {
    border-bottom: 1px solid #f2f2f2 !important;
}

.leftsidebarFlow {
    overflow: hidden;
    min-height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.h100vh-110 {
    min-height: calc(100vh - 110px);
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}

.borderPortalMenu {
    border-bottom: 1px solid #58a2c2;
}



.ant-menu-inline { /*without clloapased*/
    width: 270px;
}

.grayColor {
    color: #bcc2c7;
}

ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
    margin-top: -4px;
    width: 270px;
}


li.ant-menu-item.mlm10.borderPortalMenu {
    width: 263px;
    overflow: hidden;
    background-color: #46809d;
}

.portsubColor {
    background-color: #58a2c2;
}

.pt10 {
    padding-top: 10px !important;
}

/*#endregion*/
.IconMenu {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 20px !important;
    height: 20px !important;
    fill: transparent;
    float: right !important;
    margin-top: 10px;
}

.IconMenu-item {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    color: black !important;
    width: 20px !important;
    height: 20px !important;
    fill: transparent;
}

.hoverMenu {
    width: 40px;
    float: right;
}

button.more-menu-btn.hover-menu-item {
    padding: 5px 7px !important;
}

.Notstarted, .UrgentBell, .inProgress, .ArrowDown, .Medium {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 14px !important;
    height: 14px !important;
    fill: transparent;
}


i.far.fa-circle:before {
    content: "\F10C";
}

.Completed {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 14px !important;
    height: 14px !important;
}

.react-select.cursor-pointer.SelectBorders.__option.react-select.cursor-pointer.SelectBorders.__option--is-selected.css-9gakcf-option,
.react-select.cursor-pointer.SelectBorders.__option.react-select.cursor-pointer.SelectBorders.__option--is-selected.css-1n7v3ny-option,
.react-select.cursor-pointer.SelectBorders.__option.react-select.cursor-pointer.SelectBorders.__option--is-selected.css-yt9ioa-option {
    font-size: 14px !important;
}

button.ButtonsModal.AddBtn.btn.btn-secondary.disabled:hover,
button.ButtonsModal.AddBtn.btn.btn-secondary.disabled:active,
button.ButtonsModal.AddBtn.btn.btn-secondary.disabled:focus {
    background: #f4f4f4 !important;
    color: #707070 !important;
}

button.ButtonsModal.AddBtn.btn.btn-secondary.disabled {
    background: #f4f4f4 !important;
    color: #707070 !important;
}

.input-group-text {
    background-color: transparent !important;
}

.AddTaskPlus {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 14px !important;
    height: 14px !important;
    color: #00a0df;
}

.AddTaskBlue {
    color: #00a0df !important;
}

.AddTaskPlus2 {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 14px !important;
    height: 14px !important;
    color: #889098;
}

.CheckNoti, .TopbarIcon {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 16px !important;
    height: 16px !important;
    color: #889098;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
    background: #889098 !important;
}

span.ant-avatar.avatar.avatarTopBar.ant-avatar-circle:hover {
    background: #00a0df !important;
}

button.TopbarIconHover {
    background: transparent;
    box-shadow: unset !important;
    outline: unset !important;
    border: unset !important;
    border-radius: 5px !important;
}

    button.TopbarIconHover:focus > .TopbarIcon, button.TopbarIconHover:active > .TopbarIcon, button.TopbarIconHover:hover > .TopbarIcon {
        color: #00a0df !important;
    }

.ant-calendar-input-wrap {
    display: none !important;
}

label .LabelDate {
    margin-right: 10px !important;
    margin-top: 4px !important;
}

.rangePicker .ant-input {
    width: 200px;
    height: 30px !important;
}

.ant-calendar-range .ant-calendar-input-wrap {
    display: none;
}

span.rangePicker.FilterRange.ant-calendar-picker {
    border: 1px solid transparent !important;
    width: 300px !important;
    margin-left: 10px;
    margin-bottom: 10px;
    border-right: 1px solid #ddd !important;
    margin-right: 30px !important;
    border-radius: 5px !important;
}

.MuiButtonBase-root.MuiExpansionPanelSummary-root.CommentSummary.Mui-expanded, .MuiButtonBase-root.MuiExpansionPanelSummary-root.CommentSummary {
    border-radius: 5px !important;
    min-height: 40px !important;
    height: 40px !important;
}

.ant-calendar-range {
    width: 552px !important;
}

.ant-calendar-range-middle {
    display: none !important;
}

.continerMenu {
    position: relative;
}

.css-1hwfws3 {
    position: initial !important;
}

/*---Multivalue select*/
.css-1rhbuit-multiValue {
    background-color: transparent !important;
    display: flex !important;
}

.react-select.cursor-pointer.MultiSelect.__indicators.css-1hb7zxy-IndicatorsContainer {
    -webkit-align-items: initial !important;
    -webkit-box-align: initial !important;
    -ms-flex-align: initial !important;
    align-items: initial !important;
}

.react-select.cursor-pointer.MultiSelect.__control.css-yk16xz-control {
    height: 30px;
    min-height: 30px;
    width: 300px;
    overflow-y: auto;
    border-radius: 0px !important;
    white-space: normal;
}

.react-select.cursor-pointer.MultiSelect.css-1pahdxg-control:hover {
    width: 300px;
    border-radius: 0px !important;
}

.react-select.cursor-pointer.MultiSelect.css-1pahdxg-control {
    height: 30px;
    min-height: 30px;
    width: 300px;
    border-radius: 0px !important;
    overflow-y: auto;
    white-space: normal;
}

.cursor-pointer {
    cursor: pointer !important;
}

.react-select.cursor-pointer.MultiSelect.__value-container.react-select.cursor-pointer.MultiSelect.__value-container--is-multi.react-select.cursor-pointer.MultiSelect.__value-container--has-value.css-1hwfws3 {
    margin-bottom: 3px;
}

.continerMenu {
    position: relative;
}
/*-----------------------------------*/

.ant-tabs-nav .ant-tabs-tab {
    margin: 0px 4px 0 0 !important;
}


span.ant-calendar-picker-input.ant-input {
    border: 1px solid #00a0df;
}



    span.ant-calendar-picker-input.ant-input:focus {
        box-shadow: 0 0 0 2px transparent !important;
    }

.ant-input:focus {
    border: 1px solid #00a0df !important;
}

.h-w60 {
    height: 50px !important;
    width: 50px !important;
}

span.ant-tag.tagError {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e;
}

.gridDiv {
    background-color: #434343;
    background-image: linear-gradient(#434343, #282828);
    background-image: linear-gradient(0deg, transparent 24%, rgba(255, 255, 255, .05) 25%, rgba(255, 255, 255, .05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, .05) 75%, rgba(255, 255, 255, .05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(255, 255, 255, .05) 25%, rgba(255, 255, 255, .05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, .05) 75%, rgba(255, 255, 255, .05) 76%, transparent 77%, transparent);
    background-size: 50px 50px;
    height: 85vh !important;
}

.widthHeight {
    height: 100% !important;
    width: 100% !important;
    position: fixed !important;
}

span.ant-tag.tagSucess {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f;
}

.WalletCard {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin: 10px;
    border-radius: 5px !important;
}

.centerTextInDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.titleDashboard {
    font-size: 16px;
    font-weight: 500 !important;
    color: #889098;
    padding-bottom: 3px !important;
}

.ant-menu-item, .ant-menu-submenu-title, .ant-menu-submenu, .ant-menu-submenu-inline, .ant-menu-dark .ant-menu-submenu-open {
    -webkit-transition: unset !important;
    transition: unset !important;
}

.ant-progress-line {
    width: 56% !important;
}

.answerDashboard {
    font-size: 14px;
    color: #000000;
}

canvas.signature-pad {
    width: 800px;
    height: 200px;
}

.card-header {
    padding: 8px 10px !important;
    background-color: transparent !important;
}

.DashboardHeader.card-header {
    background: #f5f6fa !important;
    border-color: #f5f6fa !important;
    border-bottom: 1px solid #e4e6eb !important;
    padding-bottom: 0px !important;
}

.titleDashboard {
    line-height: 30px !important;
}

.cardBorder.CardDashboard.mb-2.card {
    background: #f5f6fa !important;
    border-color: #f5f6fa !important;
}

.settingsBorder {
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    height: 35px;
}

.input-group > .dropdown {
    margin-top: 10px;
    color: white !important;
    width: 100% !important;
}

    .input-group > .dropdown > .dropdown-menu.show {
        width: 100%;
        text-align: center;
    }

th.ant-descriptions-item-label.ant-descriptions-item-colon {
    font-weight: 500;
    font-size: 15px !important;
}

.ant-card.dashboardCards.heightCard.ant-card-bordered > .ant-card-body {
    height: 452px;
}

td.ant-descriptions-item-content {
    font-size: 14px !important;
}

#DepositBtn, #demoAcc {
    width: 100%;
    color: white;
}

.btnText {
    color: white;
}

svg.MuiSvgIcon-root.moreIcon {
    fill: white !important;
}

button.MuiButtonBase-root.MuiFab-root.FloatingBtns {
    background: #00a0df !important;
}

#demoAcc {
    background-color: #051c2c !important;
    border-color: #051c2c !important;
}

#newAcc {
    background-color: #00a0df !important;
    border-color: #00a0df !important;
}

#withdrawalBtn, #newAcc {
    width: 100%;
    color: white;
}

.settings-panel {
    background: #fff;
    box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: block;
    height: 100%;
    position: fixed;
    width: 256px;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: -256px;
    -webkit-transition: right .2s ease-in-out;
    transition: right .2s ease-in-out;
}

.settings-panel2 {
    background: #fff;
    box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: block;
    height: 100%;
    position: fixed;
    width: 350px;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: -256px;
    -webkit-transition: right .2s ease-in-out;
    transition: right .2s ease-in-out;
}

.settings-content {
    border-left: 1px solid black;
    height: 100%;
    position: relative;
    background: #fff;
}

    .settings-content .ps__thumb-y,
    .settings-content .simplebar-scrollbar::before {
        background: rgba(0, 0, 0, 0.5);
    }

.SideTitleStyle {
    float: left !important;
    padding-top: 5px !important;
    font-size: 13px !important;
    padding-left: 7px !important;
    margin-bottom: 0px !important;
}

.settings-section {
    padding: 1rem 1.5rem;
}

.overflowCardTables {
    overflow: scroll !important;
}

.maxheight {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: visible;
}

.labelSettings {
    font-weight: 500;
    font-size: 17px;
    color: black;
    margin: 0px 0px 5px 0px !important;
}

.react-select.cursor-pointer.LargeSelect.css-1pahdxg-control:hover {
    width: 100%;
}

.react-select.cursor-pointer.LargeSelect.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    box-shadow: unset;
    width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.LargeSelect.__single-value.css-1uccc91-singleValue {
    max-width: 100%;
    white-space: normal;
}

.minHeight {
    min-height: 350px !important;
}

.scrollNewCard {
    height: 200px;
    overflow-y: auto;
}

.react-select.cursor-pointer.LargeSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.LargeSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.titleAccounts {
    height: 30px !important;
    background-color: #203D52 !important;
}

.ant-radio-group.ant-radio-group-outline {
    text-align: left !important;
}

textarea.form-control {
    height: auto !important;
}

.expand:focus, .textAreaMessage {
    height: 8em !important;
}

textarea#apikey {
    height: 60px !important;
}

.lightThemeBackground, .lightThemeBackground2, .darkThemeBackground {
    overflow: hidden !important;
}

p.versionText {
    color: #121212;
    opacity: 0.7;
    margin-bottom: 0px !important;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn .e-icons, .e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn:focus .e-icons, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-icons {
    color: #889098 !important;
}
/*-Filter Select-*/
.react-select.cursor-pointer.MediumFilterSelect.__control.css-yk16xz-control {
    height: 35px !important;
    min-height: 35px !important;
    width: 150px;
    white-space: normal;
    border-radius: 5px !important;
    margin-right: 10px;
    background: transparent !important;
    border: 1px solid transparent !important;
}

.react-select.cursor-pointer.MediumFilterSelect.__control.css-1pahdxg-control {
    height: 35px !important;
    min-height: 35px !important;
}

.react-select.cursor-pointer.noborderApp.__single-value.css-1uccc91-singleValue, input.noBorderInputs.form-control-lg.form-control {
    border-color: transparent !important;
}

.react-select.cursor-pointer.MediumFilterSelect.css-1pahdxg-control:hover {
    width: 150px;
    border-radius: 0px !important;
    height: 35px !important;
    min-height: 35px !important;
    margin-right: 10px;
}

.react-select.cursor-pointer.MediumFilterSelect.css-1pahdxg-control {
    height: 35px !important;
    min-height: 35px !important;
    box-shadow: unset;
    width: 150px;
    border-radius: 0px !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    margin-right: 10px;
}

.react-select.cursor-pointer.MediumFilterSelect.__single-value.css-1uccc91-singleValue {
    width: 150px;
    color: #889098 !important;
    white-space: normal;
    font-weight: 400 !important;
}

.react-select.cursor-pointer.MediumFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.MediumFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.LargeFilterSelect.__control.css-yk16xz-control {
    height: 35px !important;
    border-radius: 5px !important;
    color: #889089 !important;
    min-height: 30px !important;
    width: 95%;
    margin: 10px;
    background: transparent !important;
    border: 1px solid #ddd !important;
    white-space: normal;
}

.react-select.cursor-pointer.LargeFilterSelect.css-1pahdxg-control:hover {
    width: 95%;
    border-radius: 0px !important;
    color: #889089 !important;
    margin: 10px;
}

.react-select.cursor-pointer.LargeFilterSelect.css-1pahdxg-control {
    height: 35px !important;
    border-radius: 5px !important;
    min-height: 30px !important;
    color: #889089 !important;
    box-shadow: unset;
    width: 95%;
    background: transparent !important;
    border: 1px solid #ddd !important;
    white-space: normal;
    margin: 10px;
}

i.anticon.anticon-calendar.ant-calendar-picker-icon {
    display: none !important;
}

span.getText {
    color: #889098 !important;
}

text {
    color: #889098 !important;
    fill: #889098 !important;
}

span.mb-2.ManageBtn:hover, span.mb-2.ManageBtn:hover > .ManageText {
    color: white !important;
}

input.FilterInput.form-control-lg.form-control, input.FilterInput.form-control-lg.form-control:focus, input.FilterInput.form-control-lg.form-control:active {
    border: 1px solid transparent !important;
    border-radius: 5px !important;
    height: 35px !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    font-size: 16px !important;
    color: #889098 !important;
}

.react-select.cursor-pointer.LargeFilterSelect.__single-value.css-1uccc91-singleValue {
    width: 95%;
    white-space: normal;
    color: #889089 !important;
}

.react-select.cursor-pointer.LargeFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.LargeFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.taskfilter.__control.css-yk16xz-control {
    height: 35px !important;
    min-height: 35px !important;
    width: 150px !important;
    border-radius: 0px !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    white-space: normal;
}

.react-select.cursor-pointer.XLargeFilterSelect.__control.css-yk16xz-control {
    height: 35px !important;
    min-height: 35px !important;
    width: 300px;
    border-radius: 0px !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    white-space: normal;
}

.react-select.cursor-pointer.XLargeFilterSelect.css-1pahdxg-control:hover {
    width: 300px;
    border-radius: 0px !important;
    height: 35px !important;
    min-height: 35px !important;
}

.react-select.cursor-pointer.XLargeFilterSelect.css-1pahdxg-control {
    height: 35px !important;
    min-height: 35px !important;
    box-shadow: unset;
    width: 300px;
    background: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 0px !important;
    white-space: normal;
}

.react-select.cursor-pointer.XLargeFilterSelect.__single-value.css-1uccc91-singleValue {
    width: 300px;
    white-space: normal;
}

.react-select.cursor-pointer.XLargeFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.XLargeFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.taskfilter.__menu.css-26l3qy-menu {
    width: 150px;
}

.react-select.cursor-pointer.taskfilter.css-1pahdxg-control:hover {
    width: 150px !important;
    border-radius: 0px !important;
    height: 35px !important;
    min-height: 35px !important;
}

.react-select.cursor-pointer.taskfilter.css-1pahdxg-control {
    height: 35px !important;
    min-height: 35px !important;
    box-shadow: unset;
    width: 150px;
    border-radius: 0px !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    white-space: normal;
}

.react-select.cursor-pointer.taskfilter.__single-value.css-1uccc91-singleValue {
    width: 150px;
    white-space: normal;
}

.react-select.cursor-pointer.MediumFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.MediumFilterSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.textPayment {
    color: #626262 !important;
    font-size: 30px !important;
    display: block !important;
}

.paymentResult {
    text-align: center;
    color: #54c6f1;
    font-size: 40px !important;
}
/*-----*/
input.EvBtn {
    padding: 10px !important;
    height: 29px;
    width: 60px !important;
    border-radius: 0px;
}

span.background-d-Blue.badge.badge-secondary.percentageBlue {
    width: 55px;
    border-radius: 0px !important;
    height: 20px !important;
    padding-top: 5px;
    font-weight: 500;
    font-size: 12px;
}

input.FilterInput {
    height: 31px !important;
    border-radius: 0px;
}

input.loginButton {
    height: 29px !important;
    border-radius: 0px;
}

.react-select.cursor-pointer.MedSelect.__control.css-yk16xz-control {
    height: 30px !important;
    min-height: 30px !important;
    width: 150px;
    white-space: normal;
}

.react-select.cursor-pointer.MedSelect.css-1pahdxg-control:hover {
    width: 150px;
}

.react-select.cursor-pointer.MedSelect.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    box-shadow: unset;
    width: 150px;
    white-space: normal;
}

.react-select.cursor-pointer.MedSelect.__single-value.css-1uccc91-singleValue {
    max-width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.MedSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.MedSelect.__indicator.react-select.cursor-pointer.MedSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

input#react-select-3-input, input#react-select-4-input, input#react-select-2-input, input#react-select-6-input {
    border: transparent !important;
}

input.ant-calendar-range-picker-input {
    border: transparent !important;
}

.ant-calendar-picker {
    padding: 1px !important;
}

.react-select.cursor-pointer.perSelect.__control.css-yk16xz-control {
    height: 30px !important;
    min-height: 30px !important;
    width: 100px;
    white-space: normal;
}

.react-select.cursor-pointer.perSelect.css-1pahdxg-control:hover {
    width: 100px;
}

.react-select.cursor-pointer.perSelect.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    box-shadow: unset;
    width: 100px;
    white-space: normal;
}

.react-select.cursor-pointer.perSelect.__single-value.css-1uccc91-singleValue {
    max-width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.perSelect.__indicator.react-select.cursor-pointer.perSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.perSelect.__indicator.react-select.cursor-pointer.perSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.ActionType.__control.css-yk16xz-control {
    height: 30px !important;
    min-height: 30px !important;
    width: 200px;
    white-space: normal;
}

.react-select.cursor-pointer.ActionType.css-1pahdxg-control:hover {
    width: 200px;
}

.react-select.cursor-pointer.ActionType.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    box-shadow: unset;
    width: 200px;
    white-space: normal;
}

.react-select.cursor-pointer.ActionType.__single-value.css-1uccc91-singleValue {
    max-width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.ActionType.__indicator.react-select.cursor-pointer.perSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.ActionType.__indicator.react-select.cursor-pointer.perSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.MonthSelect.__control.css-yk16xz-control {
    height: 30px !important;
    min-height: 30px !important;
    width: 100px;
    white-space: normal;
}

.react-select.cursor-pointer.MonthSelect.css-1pahdxg-control:hover {
    width: 100px;
}

.react-select.cursor-pointer.MonthSelect.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    box-shadow: unset;
    width: 100px;
    white-space: normal;
}

.react-select.cursor-pointer.MonthSelect.__single-value.css-1uccc91-singleValue {
    max-width: 100%;
    white-space: normal;
}

.css-tlfecz-indicatorContainer {
    padding: 0px !important;
}

.css-1gtu0rj-indicatorContainer {
    padding: 0px !important;
}

.react-select.cursor-pointer.MonthSelect.__indicator.react-select.cursor-pointer.SelectsStyle.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
}

.react-select.cursor-pointer.MonthSelect.__indicator.react-select.cursor-pointer.SelectsStyle.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
}


/*#region right siderbars*/
.settingsIcon {
    color: #bcc2c7 !important;
    margin-top: 4px !important;
    margin-right: 12px;
    margin-left: 12px;
}

.settings.open .settings-panel {
    right: 0;
}

.settings.open .settings-panel2 {
    right: 0;
}

span.datePicker.ant-calendar-picker {
    width: 100% !important;
}
/*#endregion*/ html
.closeBtn {
    float: right !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 14px !important;
    height: 14px !important;
}

.closeButton {
    cursor: pointer;
    float: right !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    margin-top: 5px;
    text-rendering: auto;
    width: 16px !important;
    height: 16px !important;
}

.closeButton2 {
    cursor: pointer;
    float: right !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    margin-top: 5px;
    text-rendering: auto;
    width: 11px !important;
    height: 11px !important;
}

.closeButtonfilter {
    cursor: pointer;
    float: right !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    margin-top: 10px;
    margin-right: 10px;
    text-rendering: auto;
    width: 12px !important;
    height: 12px !important;
}

div.dataTables_wrapper div.dataTables_filter {
    display: none !important;
}
/*group command grooup hover same css*/
body {
    margin: 0;
    text-align: left;
    font-weight: 500 !important;
    font-family: Roboto !important;
}

label.custom-control-label.investorsCheckboxes {
    padding-top: 4px !important;
    cursor: pointer;
}

.d-none {
    display: none !important;
}

tr.group,
tr.group:hover {
    background-color: #ddd; /*important removed as may lead to problems */
}

a.titleColor {
    margin-bottom: 2px;
    text-decoration: none;
    font-size: 12px;
    color: #3498db !important;
}

.FilterInput, .loginButton, .MultiplierBtn {
    background: transparent;
    border: 1px solid transparent !important;
}

.subTitle {
    font-size: 12px;
    color: whitesmoke;
}

.css-1pahdxg-control, .css-yk16xz-control {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #00a0df !important;
    border-radius: 0px !important;
}

.css-1pahdxg-control, .css-yk16xz-control {
    min-height: 30px !important;
    line-height: 15px !important;
    overflow: hidden !important;
    max-height: 60px !important;
    overflow-y: auto !important;
}

.table thead th {
    padding-left: 12px !important;
}

.tabTitle {
    margin-left: 15px;
    font-size: 24px !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    color: #000000;
    padding: 8px;
    cursor: pointer;
    padding-left: 0px !important;
}

span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
    background: #00a0df !important;
    line-height: 30px !important;
    height: 30px !important;
    width: 30px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

span.ant-badge {
    margin-top: 1px !important;
}

.ant-calendar-picker-container, .ant-time-picker-panel {
    z-index: 1500 !important;
    font-family: inherit !important;
}

.ant-time-picker {
    width: 100% !important;
}

.ant-time-picker-panel-input-wrap {
    display: none !important;
}

.ant-time-picker-panel.ant-time-picker-panel-narrow.ant-time-picker-panel-column-2.ant-time-picker-panel-placement-bottomLeft {
    top: 232px !important;
}

.ant-time-picker-panel-inner {
    left: 4px !important;
}

.ml12 {
    margin-left: 12px !important;
}

.tabbar {
    z-index: 20;
    position: relative;
    width: 100%;
    height: 50px !important;
    background: #ffffff;
    border-bottom: 1px solid transparent;
}
/*topBar style*/
.topbar {
    z-index: 20;
    position: relative;
    width: 100%;
    padding: 10px 21px;
}

.navSettingsbar {
    z-index: 19;
    position: relative;
    width: 100%;
    padding: 10px 21px;
}

.ant-progress-success-bg, .ant-progress-bg {
    background-color: #00a0df !important;
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
}

.ant-progress-line {
    width: 55% !important;
    float: right !important;
    margin-right: 4px;
}

.d-inline-block {
    display: inline-block !important;
}

.expandCard {
    display: block !important;
}

.d-block {
    display: block !important;
}

span.ant-calendar-picker-input.ant-input {
    border-radius: 0px !important;
    width: 270px;
}

.UploadText {
    margin-bottom: 0px !important;
}

.ant-calendar-picker-container.ant-calendar-picker-container-placement-bottomLeft {
    top: 200px !important;
}

.ActionCol {
    padding: 0 !important;
    max-width: 55px !important;
}

/*-------------*/

/* More Button / Dropdown Menu */

.more-btn,
.more-menu-btn {
    background: none;
    border: 0 none;
    line-height: normal;
    overflow: visible;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    width: 100%;
    text-align: left;
    outline: none;
    cursor: pointer;
}

.more-dot {
    background-color: #DDD;
    margin: 0 auto;
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 1px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.more-menu {
    position: absolute;
    top: 100%;
    z-index: 900;
    float: left;
    padding: 10px 0;
    margin-top: 9px;
    background-color: #fff;
    border: 1px solid #ccd8e0;
    border-radius: 4px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
    opacity: 0;
    transform: translate(0, 15px) scale(.95);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    pointer-events: none;
}

.ThemeBorders {
    padding: 4px;
    box-shadow: 1px 1px 1px 1px #00a0df;
}

.MenuAction {
    background: white;
    position: absolute;
    z-index: 1300;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2), 1px 1px 1px 1px rgba(0,0,0,0.14), 1px 1px 1px 1px rgba(0,0,0,0.12), 1px 1px 1px 0px rgba(0,0,0,0.12);
}

button.AddTaskBtn.btn.btn-secondary {
    float: right;
    width: 100px !important;
    background: transparent;
    color: #626262;
    border: transparent;
    text-align: left;
    margin: 10px;
    box-shadow: 1px 1px 1px 1px rgba(1,0,0,0.2), 1px 1px 1px 1px rgba(0,0,0,0.14), 1px 1px 1px 1px rgba(0,0,0,0.12), 1px 1px 1px 1px rgba(0,0,0,0.12);
}

    button.AddTaskBtn.btn.btn-secondary:hover {
        background: #626262 !important;
        color: white;
    }

button.AddTaskBtn2.btn.btn-secondary {
    width: 150px !important;
    background: transparent;
    color: #626262;
    height: 30px;
    padding: 0px;
    text-align: center;
    margin: 10px;
    border: 1px solid #626262;
}

    button.AddTaskBtn2.btn.btn-secondary:hover {
        background: #626262 !important;
        color: white;
    }

    button.AddTaskBtn2.btn.btn-secondary:focus {
        outline: none !important;
        box-shadow: none !important;
    }

.TaskCard.card {
    width: 90%;
    margin: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2), 1px 1px 1px 1px rgba(0,0,0,0.14), 1px 1px 1px 1px rgba(0,0,0,0.12), 1px 1px 1px 0px rgba(0,0,0,0.12);
    height: 100%;
    padding-left: 10px;
    padding-top: 10px;
}

.more-menu-items {
    margin: 0;
    list-style: none;
    padding: 0;
}

.more-menu-item {
    display: block;
}

.more-menu-btn {
    min-width: 100%;
    color: #66757f;
    cursor: pointer;
    display: block;
    font-size: 13px;
    line-height: 18px;
    padding: 5px 20px;
    position: relative;
    white-space: nowrap;
}

.highcharts-menu-item {
    font-size: 14px !important;
}

.more-menu-item:hover {
    background-color: #489fe5;
}

    .more-menu-item:hover .more-menu-btn {
        color: #fff;
    }

.more-btn:hover .more-dot,
.show-more-menu .more-dot {
    background-color: #516471;
}

.show-more-menu .more-menu {
    opacity: 1 !important;
    top: auto !important;
    transform: translate(0, 0) scale(1);
    pointer-events: auto;
}

.more-menu {
    opacity: 1 !important;
    top: auto !important;
    margin: auto !important;
}
/*-------------*/
.MuiSvgIcon-root {
    width: 0.9em !important;
}

.MuiExpansionPanel-root.Mui-expanded {
    margin: 0 !important;
}


.GetIcon i.anticon.anticon-undo svg {
    height: 18px;
    width: 18px;
    transform: scaleY(-1);
}

.UndoIcon i.anticon.anticon-undo svg {
    height: 18px;
    width: 18px;
    transform: scaleY(-1);
}

span.GetIcon {
    height: 40px;
    padding: 8px;
}

.w11 {
    width: 11px !important;
    margin-bottom: 2px;
    margin-right: 2px;
}

.borderbottom {
    border-bottom: 1px solid transparent;
}

.bordertop {
    border-top: 1px solid transparent;
}

.align-middle {
    vertical-align: middle !important;
}

.pt30 {
    padding-top: 30px !important;
}

.cursor-pointer {
    cursor: pointer;
}


.anticon {
    vertical-align: 0.07em !important;
}

.navbar {
    justify-content: left !important;
}
html[dir="rtl"] .navbar {
    justify-content: right !important;
}
.padding-0-10 {
    padding: 0 10px !important;
}

.ThemeDropDownMenu.dropdown-menu.dropdown-menu-right.show {
    width: 160px !important;
}

.react-select.cursor-pointer.SelectsStyle.__control.css-yk16xz-control {
    padding: 4px;
    height: 30px !important;
    min-height: 30px !important;
    width: 300px;
    white-space: normal;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.SelectsStyle.css-1pahdxg-control:hover {
    padding: 4px;
    width: 300px;
    border: 1px solid #00a0df !important;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.SelectsStyle.css-1pahdxg-control {
    padding: 5px;
    height: 30px !important;
    min-height: 30px !important;
    box-shadow: unset;
    width: 300px;
    white-space: normal;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.SelectsStyle.__single-value.css-1uccc91-singleValue {
    padding: 5px;
    /*align-items: baseline;*/
    max-width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.SelectsStyle.__indicator.react-select.cursor-pointer.SelectsStyle.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.react-select.cursor-pointer.SelectsStyle.__indicator.react-select.cursor-pointer.SelectsStyle.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.react-select.cursor-pointer.select-actionBar.__control.css-yk16xz-control {
    border: 1px solid transparent !important;
    height: 30px !important;
    min-height: 30px !important;
    width: 130px;
    white-space: normal;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.css-1pahdxg-control:hover {
    border-color: transparent !important;
    width: 130px;
    border-color: transparent !important;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    border-color: transparent !important;
    box-shadow: unset;
    width: 130px;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.__single-value.css-1uccc91-singleValue {
    /*align-items: baseline;*/
    max-width: 100%;
}

.css-1okebmr-indicatorSeparator {
    background-color: transparent !important;
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.__indicator.react-select.cursor-pointer.select-actionBar.CardsSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.__indicator.react-select.cursor-pointer.select-actionBar.CardsSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.__control.css-yk16xz-control:hover {
    border-radius: 0px !important;
}

.react-select.cursor-pointer.select-actionBar.CardsSelect.__control.css-yk16xz-control {
    border: 1px solid transparent !important;
    height: 30px !important;
    min-height: 30px !important;
    width: 130px;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.transform180 {
    transform: rotate(180deg);
}

.logoIcon {
    max-width: 73%;
    height: auto
}

.bottom-44 {
    bottom: -44px !important;
}

.bottom-33 {
    bottom: -33px !important;
}

.bottom-55 {
    bottom: -55px !important;
}

.dropdown-menu.dropdown-menu-right.show {
    width: 235px;
}

.nameProfile {
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.ant-tooltip .ant-menu-inline-collapsed-tooltip .ant-tooltip-placement-right {
    max-width: 400px !important;
}

.react-select.cursor-pointer.select-actionBar.css-1pahdxg-control:hover {
    border-color: transparent !important;
    width: 130px;
    border-color: transparent !important;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.select-actionBar.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    border-color: transparent !important;
    box-shadow: unset;
    width: 130px;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.select-actionBar.__single-value.css-1uccc91-singleValue {
    /*align-items: baseline;*/
    max-width: 100%;
    white-space: normal;
}

.css-1okebmr-indicatorSeparator {
    background-color: transparent !important;
}

.react-select.cursor-pointer.select-actionBar.__indicator.react-select.cursor-pointer.select-actionBar.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.react-select.cursor-pointer.select-actionBar.__indicator.react-select.cursor-pointer.select-actionBar.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.react-select.cursor-pointer.SmallSelect.__control.css-yk16xz-control {
    height: 30px !important;
    min-height: 30px !important;
    border-color: transparent !important;
    width: 80px;
    white-space: normal;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.SmallSelect.css-1pahdxg-control:hover {
    border-color: transparent !important;
    width: 80px;
    border-color: transparent !important;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.SmallSelect.css-1pahdxg-control {
    height: 30px !important;
    min-height: 30px !important;
    border-color: transparent !important;
    box-shadow: unset;
    width: 80px;
    white-space: normal;
    /*align-items: baseline;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;*/
}

.react-select.cursor-pointer.SmallSelect.__single-value.css-1uccc91-singleValue {
    /*align-items: baseline;*/
    max-width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.SmallSelect.__indicator.react-select.cursor-pointer.select-SmallSelect.__dropdown-indicator.css-tlfecz-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.react-select.cursor-pointer.SmallSelect.__indicator.react-select.cursor-pointer.select-SmallSelect.__dropdown-indicator.css-1gtu0rj-indicatorContainer {
    padding: 0px;
    padding-bottom: 5px;
}

.css-1hwfws3 {
    padding: 0 8px !important;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: none;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.sidebartoggleBtn {
    /*width: 100vh;*/
    background: none;
    color: #ffffff;
    border: none;
    overflow: hidden;
}

    .sidebartoggleBtn:active, .sidebartoggleBtn:focus, .sidebartoggleBtn:hover {
        width: 100vh;
        background: none;
        color: #ffffff;
        border: none;
        overflow: hidden
    }


.buttonsStyle {
    background-color: #051C2C !important;
    height: 41px !important;
    width: 600px !important;
    font-size: 14px !important;
    border: none !important;
    box-shadow: 0 !important;
    margin: -1px !important;
    padding: 0px !important;
}

.m10 {
    margin: 10px !important;
}

.m20 {
    margin: 20px !important;
}

.overflowhidden {
    overflow: hidden !important;
}

button.AssignToBtn.btn.btn-secondary {
    border: transparent !important;
    background: transparent !important;
    color: #626262;
}

button.ActionTabTitle.btn.btn-secondary {
    background: transparent !important;
    border: none !important;
    color: #626262;
}

    button.ActionTabTitle.btn.btn-secondary:focus {
        box-shadow: none !important;
    }

    button.ActionTabTitle.btn.btn-secondary:hover {
        background-color: #eaf9ff;
    }

button.ActionTabTitle2.btn.btn-secondary {
    background: transparent !important;
    border: none !important;
    color: white;
    font-size: 14px !important;
    padding: 12px !important;
}

    button.ActionTabTitle2.btn.btn-secondary:focus {
        box-shadow: none !important;
    }

    button.ActionTabTitle2.btn.btn-secondary:hover {
        background-color: #eaf9ff;
    }

input.InputNameTask {
    height: 30px !important;
    padding-left: 10px;
    border: transparent !important;
    border-bottom: 2px solid #505050 !important;
    text-align: left;
    margin-left: 10px;
}

    input.InputNameTask:focus {
        outline: none !important;
    }

.sidebar-sticky .sidebar-content {
    border-right: 0;
}

.sidebar-sticky .sidebar-nav {
    padding-bottom: 0;
}

.sidebar-sticky .sidebar-content {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
}

.profile-center {
    text-align: center !important;
    border: 1px solid #ddd;
}

.text-center {
    text-align: center !important;
}

.img-fluid {
    max-width: 30%;
    height: auto;
}

.profileImg {
    margin-left: 40%;
    margin-top: 3%;
}

.contactIcon {
    width: 14px !important;
    margin-bottom: 2px;
}

.titleName {
    font-size: 24px !important;
}

.titleName2 {
    font-size: 14px !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: #626262 !important;
    font-weight: 500 !important;
}



.EditBtn {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 20px !important;
    height: 20px !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-circle2 {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    background: #eaf9ff;
    margin: 5px;
    border: 1px solid #ddd;
}

.ActionIcons {
    margin-top: 9px;
    margin-left: 3px;
    width: 17px !important;
    height: 19px;
}

.titleAction {
    font-size: 14px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.ml-20 {
    margin-left: 20% !important;
}
/*#region scroller*/
.ScrollStyle {
    max-height: 150px;
    overflow-y: auto;
}

.custom-file-input {
    z-index: 0 !important;
}
/*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 7px;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #727272;
}*/
.dataTables_scrollHead::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
}

.redux-toastr .toastr {
    background-color: black !important;
    min-height: 50px !important;
    color: #c2c2c2 !important;
}

.rrt-progressbar {
    background-color: #c2c2c2 !important;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
    fill: #c2c2c2 !important;
}

svg.toastr-icon {
    width: 20px !important;
    height: 20px !important;
}

.redux-toastr .toastr .rrt-middle-container {
    font-family: inherit !important;
    font-size: 13px !important;
    margin-top: 4px !important;
}

.redux-toastr .toastr .rrt-progress-container {
    height: 1px !important;
}
/*#endregion*/
.card {
    width: 100%;
}

.cardExternal {
    width: 90% !important;
    margin: 0 auto;
}
/*#region Datatable*/
.card > .dataTables_wrapper .table.dataTable td:first-child,
.card > .dataTables_wrapper .table.dataTable th:first-child,
.card > .table td:first-child,
.card > .table th:first-child,
.card > .table-responsive .table td:first-child,
.card > .table-responsive .table th:first-child,
.card > .table-responsive-sm .table td:first-child,
.card > .table-responsive-sm .table th:first-child,
.card > .table-responsive-md .table td:first-child,
.card > .table-responsive-md .table th:first-child,
.card > .table-responsive-lg .table td:first-child,
.card > .table-responsive-lg .table th:first-child,
.card > .table-responsive-xl .table td:first-child,
.card > .table-responsive-xl .table th:first-child {
    border-left: 0;
    padding-left: 1.25rem;
}

.card > .dataTables_wrapper .table.dataTable td:last-child,
.card > .dataTables_wrapper .table.dataTable th:last-child,
.card > .table td:last-child,
.card > .table th:last-child,
.card > .table-responsive .table td:last-child,
.card > .table-responsive .table th:last-child,
.card > .table-responsive-sm .table td:last-child,
.card > .table-responsive-sm .table th:last-child,
.card > .table-responsive-md .table td:last-child,
.card > .table-responsive-md .table th:last-child,
.card > .table-responsive-lg .table td:last-child,
.card > .table-responsive-lg .table th:last-child,
.card > .table-responsive-xl .table td:last-child,
.card > .table-responsive-xl .table th:last-child {
    border-right: 0;
    padding-right: 1.25rem;
}

table.dataTable {
    margin-top: 0px !important;
}

.card > .dataTables_wrapper .table.dataTable tr:first-child td,
.card > .dataTables_wrapper .table.dataTable tr:first-child th,
.card > .table tr:first-child td,
.card > .table tr:first-child th,
.card > .table-responsive .table tr:first-child td,
.card > .table-responsive .table tr:first-child th,
.card > .table-responsive-sm .table tr:first-child td,
.card > .table-responsive-sm .table tr:first-child th,
.card > .table-responsive-md .table tr:first-child td,
.card > .table-responsive-md .table tr:first-child th,
.card > .table-responsive-lg .table tr:first-child td,
.card > .table-responsive-lg .table tr:first-child th,
.card > .table-responsive-xl .table tr:first-child td,
.card > .table-responsive-xl .table tr:first-child th {
    border-top: 0;
}

.card > .dataTables_wrapper .table.dataTable tr:last-child td,
.card > .table tr:last-child td,
.card > .table-responsive .table tr:last-child td,
.card > .table-responsive-sm .table tr:last-child td,
.card > .table-responsive-md .table tr:last-child td,
.card > .table-responsive-lg .table tr:last-child td,
.card > .table-responsive-xl .table tr:last-child td {
    border-bottom: 0;
}

.card > .dataTables_wrapper .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.card > .dataTables_wrapper .dataTables_info {
    padding: 1rem 1.25rem;
}

.card > .dataTables_wrapper .dataTables_paginate {
    padding: 0.6rem 1.25rem;
}

.dataTables_filter {
    float: left !important;
    margin-bottom: 10px;
}

.dts_label {
    display: none;
}

.dataTables_length {
    float: right !important;
}

.dataTables_info {
    float: left !important;
}

.dataTables_paginate {
    float: right !important;
}

.dataTables_scrollHeadInner {
    margin-top: -11px !important;
    width: 100% !important;
}

.table > tbody > tr > td {
    outline: none !important;
}

th {
    text-align: left;
    font-weight: 400 !important;
    border-top: 1px solid #dee2e6;
}


.table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    text-align: left;
}

.table > tbody > tr > td {
    vertical-align: middle;
    border: white !important;
}

td.dataTables_empty {
    font-size: 12px !important;
}

div.dataTables_wrapper div.dataTables_processing {
    border: 1px solid transparent !important;
    background-color: transparent !important;
    top: 62%;
    margin-top: -45px !important;
}

table {
    border-spacing: 0px !important;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f4f8;
    border-top: 2px solid #ffffff;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
    background-color: rgba(60,180,229, 0.1);
}

child td {
    border-top: none;
    outline: none !important;
}

.dataTables_filter {
    width: 146px;
    height: 30px !important;
}

.page-item.active .page-link {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db;
}

div.dts div.dataTables_paginate, div.dts div.dataTables_length {
    display: inline !important;
}


div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
}

div.dataTables_length select {
    width: 75px;
    display: inline-block;
}

div.dataTables_filter {
    text-align: right;
}

    div.dataTables_filter label {
        font-weight: normal;
        white-space: nowrap;
        text-align: left;
    }

    div.dataTables_filter input {
        margin-left: 0.5em;
        display: inline-block;
    }

div.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
}

div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

    div.dataTables_paginate ul.pagination {
        margin: 2px 0;
        white-space: nowrap;
    }

/*iframe {
    border: 1px solid transparent !important;
}*/

.fix-width {
    width: 452px;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 767px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .showOnBigScreen {
        display: none !important;
    }

    div .dataTables_length, div.dataTables_filter, div.dataTables_info, div.dataTables_paginate {
        text-align: center;
    }
}

div.dataTables_wrapper div.dataTables_length select {
    margin-top: 3px;
    margin-right: 10px !important;
}

.page-item.active .page-link {
    background-color: #00a0df !important;
    border-color: #00a0df !important;
}

span.ActionTabs {
    background: transparent !important;
    padding: 5px !important;
}

table.dataTable td,
table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}


table.dataTable {
    word-wrap: break-word;
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
}

    table.dataTable thead > tr > th {
        padding-left: 18px;
        padding-right: 18px;
    }

    table.dataTable th:active {
        outline: none;
    }

.ant-tabs-bar.ant-tabs-top-bar {
    height: 40px !important;
}

.ant-tabs-nav .ant-tabs-tab {
    padding: 8px 6px !important;
}

Scrolling div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
}

label.subMenuTitleSettings.forClient {
    margin-left: 0px !important;
    padding: 0px !important;
    font-size: 22px !important;
}

button.navigate.EditButton, button.secondNavigate.EditButton {
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.titleFinish, .resultFinish {
    margin-bottom: 5px !important;
}
/*Frustratingly the border-collapse:collapse used by Bootstrap makes the column
width calculations when using scrolling impossible to align columns. We have
to use separate*/
table.table-bordered.dataTable {
    border-collapse: separate !important;
}

table.table-bordered thead th,
table.table-bordered thead td {
    border-left-width: 0;
    border-top-width: 0;
}

table.table-bordered tbody th,
table.table-bordered tbody td {
    border-left-width: 0;
    border-bottom-width: 0;
}

table.table-bordered th:last-child,
table.table-bordered td:last-child {
    border-right-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}


.table.dataTable tbody tr.active td,
.table.dataTable tbody tr.active th {
    background-color: #08C;
    color: white;
}

.table.dataTable tbody tr.active:hover td,
.table.dataTable tbody tr.active:hover th {
    background-color: #eaf9ff !important;
}

.table.dataTable tbody tr.active th > a,
.table.dataTable tbody tr.active td > a {
    color: white;
}

.table-striped.dataTable tbody tr.active:nth-child(odd) td,
.table-striped.dataTable tbody tr.active:nth-child(odd) th {
    background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
    cursor: pointer;
}

div.DTTT .btn {
    color: #333 !important;
    font-size: 12px;
}

    div.DTTT .btn:hover {
        text-decoration: none !important;
    }

ul.DTTT_dropdown.dropdown-menu {
    z-index: 2003;
}

    ul.DTTT_dropdown.dropdown-menu a {
        color: #333 !important;
        /*needed only when demo_page.css is included*/
    }

    ul.DTTT_dropdown.dropdown-menu li {
        position: relative;
    }

        ul.DTTT_dropdown.dropdown-menu li:hover a {
            background-color: #0088cc;
            color: white !important;
        }

div.DTTT_collection_background {
    z-index: 2002;
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: unset !important;
}

TableTools information display
div.DTTT_print_info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 150px;
    margin-left: -200px;
    margin-top: -75px;
    text-align: center;
    color: #333;
    padding: 10px 30px;
    opacity: 0.95;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

div.DTTT_print_info h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
}

div.DTTT_print_info p {
    font-size: 14px;
    line-height: 20px;
}

div#myAccountTbl_wrapper {
    z-index: 0;
}

div.dataTables_processing {
    position: absolute;
    top: 150px;
    left: 50%;
    width: 100%;
    height: 60px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
}

* FixedColumns styles

div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
div.DTFC_RightHeadWrapper table,
div.DTFC_RightFootWrapper table,
table.DTFC_Cloned tr.even {
    background-color: white;
    margin-bottom: 0;
}

label.ant-checkbox-wrapper {
    padding-top: 10px;
}

div .DTFC_RightHeadWrapper table, div.DTFC_LeftHeadWrapper table {
    border-bottom: none !important;
    margin-bottom: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

    div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
    div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
    div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
    div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

div.DTFC_RightBodyWrapper table,
div.DTFC_LeftBodyWrapper table {
    border-top: none;
    margin: 0 !important;
}

div.DTFC_RightBodyWrapper tbody tr:first-child th,
div.DTFC_RightBodyWrapper tbody tr:first-child td,
div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
    border-top: none;
}

div.DTFC_RightFootWrapper table,
div.DTFC_LeftFootWrapper table {
    border-top: none;
    margin-top: 0 !important;
}

div.FixedHeader_Cloned table {
    margin: 0 !important;
}

th.dt-center, td.dt-center {
    text-align: center;
}

.dataTables_info {
    display: none !important;
}
/*#endregion*/

button#NewAccount, button#ActionBt {
    color: #00a0df;
    background-color: transparent !important;
    border-color: #00a0df !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 5px;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    height: 35px !important;
}

    button#NewAccount > span.ManageText {
        color: #00a0df !important;
        font-size: 16px !important;
    }

    button#NewAccount:hover, button#ActionBtn:hover {
        background: #00a0df !important;
    }

        button#NewAccount:hover > span.ManageText {
            color: white !important;
        }

.ActionDiv > div > .dropdown-menu.show {
    left: -55px !important;
}

button#NewAccount:focus, button#ActionBt:focus {
    box-shadow: unset !important;
    outline: unset !important;
}

button#NewAccount:active, button#ActionBt:active {
    box-shadow: unset !important;
    outline: unset !important;
}
/*Portal settings ActionBar*/
.settingsbars {
    min-height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.PortalsettingsActionBar {
    position: fixed;
    height: 40px !important;
    background: white;
    margin-top: -20px;
    width: 81%;
}
/*external Layout*/
body .signin {
    background-color: transparent !important;
}
/*antd switch*/
.ant-switch-checked {
    background-color: #00a0df !important;
}
/*antd select*/
.ant-select-selection.ant-select-selection--single {
    border-color: #9cdaf3 !important;
}
/*select of class settingsObjects*/
.react-select-container.SelectsStyle.settingsObjects.react-select.cursor-pointer.SelectsStyle.settingsObjects--is-disabled.css-14jk2my-container {
    max-width: 70%;
    min-width: 70%;
    white-space: normal;
    cursor: not-allowed !important;
}
/*Categories portal setting*/
.bgSecdonrtySettingcolor {
    background-color: #eaeaea;
}
/*Categories color picker setting*/
.colorPickerSettings {
    padding: 2.5em;
    border-bottom: 1px solid aliceblue;
}
/*Categories chrome-picker*/
.chrome-picker {
    width: 100% !important;
}
/*Settingsobjects Styles */
.react-select.cursor-pointer.SelectsStyle.settingsObjects.__control.css-yk16xz-control {
    width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.SelectsStyle.settingsObjects.__control.react-select.cursor-pointer.SelectsStyle.settingsObjects.__control--is-focused.css-1pahdxg-control {
    width: 100%;
    position: relative;
}

.react-select.cursor-pointer.SelectsStyle.settingsObjects.__menu.css-26l3qy-menu {
    width: 100%;
}
/*------Multi select------*/
body .p-multiselect {
    background: transparent !important;
    border: 1px solid #00a0df !important;
    border-radius: 4px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-radius: 0px !important;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0px !important;
}

body .p-component {
    margin-left: 10px;
    font-family: inherit !important;
    font-size: 16px !important;
}

body .p-multiselect .p-multiselect-trigger {
    background-color: transparent !important;
    color: #00a0df !important;
}

body .p-multiselect:not(.p-disabled):focus {
    border-color: #00a0df !important;
}

body .p-multiselect:not(.p-disabled):hover {
    border-color: #00a0df !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: white !important;
    background-color: #54c6f1 !important;
}

.h120 {
    height: 120px !important;
}

.p-multiselect-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
    left: -22px !important;
    top: 38px !important;
    border: 1px solid transparent !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-left: 10px !important;
}

.multiselect-demo .p-multiselect {
    min-width: 15rem;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

    .multiselect-demo .multiselect-custom .country-item-value img.flag {
        width: 17px;
    }

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    padding-left: 10px !important;
    margin-bottom: 5px !important;
    color: #889098 !important;
    font-size: 12px !important;
}

body .p-multiselect .p-multiselect-label {
    font-size: 14px !important;
    padding-top: 10px !important;
}

.p-multiselect .p-multiselect-panel {
    min-width: 100%;
    width: 250px !important;
}

body .p-checkbox .p-checkbox-box {
    border: 1px solid #000000 !important;
    width: 14px !important;
    height: 14px !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 3px 5px;
}

body .p-multiselect-panel .p-multiselect-items {
    margin-bottom: 0px !important;
}

body .p-multiselect-panel .p-multiselect-header {
    padding: 5px 0px 0px 5px;
    display: none;
}

body .ShowSearch .p-multiselect-panel .p-multiselect-header {
    display: flex;
}
/*--end of multiselect----*/
/*collopase settings menu*/
.collpaseMenu {
    width: 10px !important;
}

.text-darkBlue {
    color: #051C2C !important;
}

.text-Grey {
    color: #889098 !important;
}

input.form-control.attachPromotion {
    border-color: transparent !important;
    height: 40px !important;
    color: #00a0df !important;
}

.input-group-prepend.attachClip.attachPromotion > span.input-group-text {
    background: white !important;
    border-color: transparent !important;
    color: #00a0df !important;
    font-size: 18px;
}

svg.MuiSvgIcon-root.closeButton.mr-1 {
    width: 30px !important;
    height: 30px !important;
    color: #889098 !important;
    cursor: pointer !important;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    top: 20% !important;
    right: 0px;
    background: transparent !important;
}

.p-multiselect-header {
    height: 70px;
}

    .p-multiselect-header > .p-checkbox.p-component {
        margin-top: 40px !important;
        padding-left: 9px !important;
        z-index: 1000 !important;
    }

.ant-tabs-tab {
    font-weight: 500;
    font-size: 16px !important;
}

.ant-tabs-tab-active.ant-tabs-tab {
    font-weight: 500 !important;
    font-size: 16px !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    outline: none !important;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext::-webkit-input-placeholder {
    color: #889098 !important;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    height: 30px !important;
    padding: 5px !important;
    color: #889098 !important;
    margin-bottom: 8px !important;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    color: #889098 !important;
    top: 25% !important;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    width: 76% !important;
    margin-left: -21px !important;
}

p-multiselect-header > .p-checkbox .p-checkbox-box {
    margin-top: 7px !important;
    margin-left: 9px !important;
}

.pi {
    font-family: 'primeicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
    height: 13px !important;
    width: 13px !important;
}

.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
    margin-top: -0.7em !important;
    color: rgba(0,0,0,0.65) !important;
}
/*checkboxes group Account Currency*/
label.ant-checkbox-group-item.ant-checkbox-wrapper {
    margin-right: 18%;
    width: 70px; /*to be aligned under each other*/
    margin-bottom: 20px;
    font-weight: 600;
}

/*label.ant-checkbox-wrapper {
    font-weight: 500;
}*/
.wp60 {
    width: 60% !important;
}

.react-select-container.SelectsStyle.settingsObjects.react-select.cursor-pointer.SelectsStyle.settingsObjects.--is-disabled.css-14jk2my-container {
    max-width: 100%;
}
/*card wrapper for desgin so drop down menu appears*/
.MuiCard-rootCard {
    overflow: visible !important;
}

label.LabelCardsNew.centerLabelCards {
    padding: 14px !important;
    width: 160px;
}

button#saveProfile, button#resetProfile, button#saveEmail, button#savePassword, button#saveLanguage {
    width: 100%;
}

span#dateAccount {
    width: 100% !important;
}

.divLeft {
    float: left;
    left: 0;
    top: 0;
}

.divRight {
    float: right !important;
    right: 0;
    top: 0;
}

input#filterInput {
    font-size: 14px !important;
    border: 1px solid transparent !important;
    width: 100%;
    background-color: transparent !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.h14 {
    height: 14px !important;
}

.h18 {
    height: 18px !important;
}

.h64 {
    height: 64px !important;
}

.filterBar {
    /*  display: flex;
    flex-shrink: 0;
    background: rgba(0,0,0,.06);
    background: var(--palette-black-alpha-6,rgba(0, 0, 0, .06));*/
    padding: 3px;
    height: 35px;
    border-radius: 4px;
    margin: 8px;
    margin-right: 0px !important;
    min-width: 100px !important;
}

.MainFilterBar.filterBar.w100p {
    z-index: 3 !important
}

.ThemeDropDownMenu.LanguageMenu.dropdown-menu.dropdown-menu-right.show {
    max-height: 400px;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.filterBar.filterSidebar {
    box-shadow: unset !important;
    background: white;
    border: 1px solid #ddd !important;
}

.divButtons {
    height: 30px;
    font-size: 15px;
    border-radius: 3px;
}
.h600 {
    height: 600px !important;
}
.h100 {
    height: 100px !important;
}

.h80 {
    height: 80px !important;
}

.whitebackground {
    background-color: white;
    border: 1px solid #ddd;
    padding: 5px;
}

label.p-multiselect-label {
    color: #00a0df !important;
}

body .p-multiselect .p-multiselect-label {
    font-size: 14px !important;
}

input#email {
    height: 40px !important;
}

.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
    color: #00a0df !important;
}

body .pi {
    font-size: 0.7em !important;
}

body .p-component {
    font-size: 16px !important;
}

.h100p {
    height: 100% !important;
}

.react-select.cursor-pointer.LargeSelect.DarkMode__input > input {
    border: 1px solid transparent !important;
}
/*Email template edit template text area*/
.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #a6a6a6;
}
/*Advanced Tabs*/
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: 0 !important;
    margin-right: 0 !important;
    background: #ffffff !important;
    border: none !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: transparent !important;
    border-color: transparent !important;
    border-bottom: 2px solid #00a0df !important;
    font-weight: 400;
    margin-left: 8px;
    font-size: 16px !important;
    font-family: Roboto !important;
    font-weight: 500 !important;
    color: #000000;
    padding: 8px;
}

button.ml42p.white-bg.no-border.no-outline.fw600 {
    display: none;
}

input.ml-3.mb-3.w100.p10.FilterInput.form-control-lg.form-control {
    background: transparent !important;
}

    input.ml-3.mb-3.w100.p10.FilterInput.form-control-lg.form-control:hover,
    input.ml-3.mb-3.w100.p10.FilterInput.form-control-lg.form-control:focus {
        border: 1px solid transparent !important;
    }

.sizeFull {
    width: 100% !important;
    height: 100% !important;
}

.width50 {
    width: 50% !important;
}

Style Attribute {
    font-family: inherit !important;
}



.borderPage .ant-tabs-bar.ant-tabs-top-bar, .logspage .ant-tabs-bar.ant-tabs-top-bar {
    border-color: rgba(0, 0, 0, .08) !important
}

div.dataTables_wrapper div.dataTables_length select, div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    cursor: pointer;
}

.hidePromotion {
    display: none; /*not !important as want to change in promotion class*/
}

.ant-radio-inner::after {
    background-color: #00a0df !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #00a0df !important;
}

.width350 {
    width: 350px;
}
/*multi select inline*/
.fixMulitselect {
    position: fixed !important;
    display: inline !important;
}

.input-group-prepend.attachClip {
    z-index: 1;
    position: unset !important;
}
/*close tab*/
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
    color: rgb(205, 222, 0);
}
/*spinner*/
.blurBackground {
    width: 100% !important;
    opacity: 0.3;
    pointer-events: none;
    z-index: -1;
    position: fixed;
}

.blurBackground2 {
    width: 100% !important;
    opacity: 0.3;
    pointer-events: none;
    z-index: -1;
}

h2.LabelCreate2 {
    font-size: 24px !important;
}

.AccountTypesCard:hover {
    border: 2px solid #00a0df !important;
    cursor: pointer !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #889098 !important;
}

.ant-badge-count {
    min-width: 16px !important;
    width: 16px !important;
    height: 16px !important;
    padding: 0 4px !important;
}

svg.svg-inline--fa.fa-sitemap.fa-w-20.Servers.TopbarIcon {
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 22px !important;
    height: 16px !important;
    color: #889098;
}

button.ThemeDropDown.ServerDrop.dropdown-item {
    text-align: left !important;
}

.ThemeDropDownMenu.ServerMenu.dropdown-menu.dropdown-menu-right.show {
    width: min-content !important;
}

.ant-badge-count {
    color: white !important;
    background: #00a0df !important;
}

svg.svg-inline--fa.fa-times-circle.fa-w-16.ant-scroll-number-custom-component.notConnected {
    color: #cc2626 !important;
    border-radius: 10px !important;
    background: white !important;
    font-size: 12px !important;
}

svg.svg-inline--fa.fa-check-circle.fa-w-16.ant-scroll-number-custom-component.connected {
    color: #20be26;
    border-radius: 10px !important;
    background: white !important;
    font-size: 12px !important;
}

svg.svg-inline--fa.fa-server.fa-w-16.Servers.NotConnected.TopbarIcon {
    color: red !important;
    border-radius: 10px !important;
}

svg.svg-inline--fa.fa-server.fa-w-16.Servers.Connected.cursor-pointer {
    color: #00a0df !important;
    font-size: 17px;
}

Label.notConnectedServer {
    color: red !important;
    opacity: 0.4 !important;
}

.AccountTypesCard, .AccountTypesDisabled, .AccountTypesCardSelected {
    width: 450px !important;
    background: #f5f6fa !important;
    padding: 30px;
    height: 100%;
    min-height: 370px !important;
    max-height: 700px !important;
    margin-bottom: 20px !important;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.AccountTypesCardSelected {
    height: fit-content !important;
    border: 2px solid #00a0df !important;
}

button.btn.btn-primary.closeTraders {
    background: transparent !important;
    color: #889098 !important;
    border-color: transparent !important;
    text-align: center !important;
}

    button.btn.btn-primary.closeTraders:hover, button.btn.btn-primary.closeTraders:focus, button.btn.btn-primary.closeTraders:active {
        background: transparent !important;
        color: #00a0df !important;
        border-color: transparent !important;
        text-align: center !important;
    }

.progress {
    display: block;
    margin-bottom: -1px;
    background-color: transparent;
}

.progress-bar {
    position: absolute;
    left: 0;
    z-index: -1;
    display: block;
}

.list-group-item-progress {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: transparent;
}

a.list-group-item-progress {
    z-index: 1;
    list-style-type: none;
    border-bottom: 1px solid #E5E9F2 !important;
}

.list-group-progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    background-color: #eaf9ff;
}

.spinIndicator, .spinIndicatorSignIn {
    z-index: 1000;
    content: '';
    position: fixed !important;
    left: 48% !important;
    top: 50% !important;
    width: 40px !important;
    height: 40px !important;
    border-width: 4px !important;
    border-radius: 50% !important;
}

.ibclient > .ant-tabs-bar.ant-tabs-left-bar {
    width: 200px !important;
}

.ibclient .ant-tabs-tab {
    margin-bottom: 0px !important;
    margin-left: -7px !important;
}

.ant-tabs .ant-tabs-left-bar {
    height: 100vh !important;
}
/*.logspage .ant-tabs-nav-scroll {
    margin-top: 40px !important;
}*/
/*filtration btn style*/
.filtrtBtn {
    height: 0px !important;
    margin-left: -4px !important;
    border: none !important;
    background-color: transparent !important;
}

img.walletImg {
    width: 225px !important;
    margin: 0 auto;
}
/*::-webkit-scrollbar-thumb {
    background-color: white !important;
}*/
.ant-progress.ant-progress-line.ant-progress-status-active.ant-progress-show-info.ant-progress-small.progreesAppForm {
    position: absolute !important;
    bottom: -12px;
    left: 0;
    width: 100% !important;
}

.ant-progress-text {
    display: none !important;
}

.ant-progress-show-info .ant-progress-outer {
    margin-right: 0px !important;
    padding-right: 0px !important;
}

.barServer {
    border-right: 1px solid #ddd;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 3px;
    margin-right: 10px;
}

.pl0 {
    padding-left: 0px !important;
}

.sticky-bottom {
    position: -webkit-sticky;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 51px !important;
    z-index: 11;
    background-color: white;
    border-bottom: none !important;
}

.has-error .ant-input:focus {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.btn-primary, .btn-secondary {
    border-radius: 0px;
}


.h48 {
    height: 48px !important
}

.ml10 {
    margin-left: 10px !important;
}

.TextStatusForm {
    color: #00a0df !important;
    float: right;
    font-size: 18px;
}

.ml7 {
    margin-left: 7px !important;
}
/*Signals Page*/
.signalsTitle {
    margin-left: 20px !important;
    font-size: 30px !important;
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    font-weight: 300;
    line-height: 1.1;
    color: #777777;
    width: 100%;
}

.signalsDate > span.ant-calendar-picker-input.ant-input, .signalsDate > span.ant-calendar-picker-input.ant-input:focus, .signalsDate > span.ant-calendar-picker-input.ant-input:hover {
    border: transparent;
}

div.dataTables_processing {
    background: transparent !important;
}

.signalsTitleUnderline {
    border-bottom: 2px solid transparent !important;
}

body a.signalsTitleUnderline {
    color: #121212 !important;
}

singals .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #3498db;
    color: #f5eeee !important;
    border-radius: 4px !important;
}

a.page-link, .page-item.active .page-link {
    z-index: 0 !important;
}

.ModelPaper2.ModalPopBody {
    height: unset !important;
    min-height: 16%;
    margin-right: 28% !important;
}

button.ButtonsModal.btn.btn-secondary, button.ButtonsModal, button.ButtonsModal.btn.btn-primary, button.ButtonsModal.btn.btn-secondary:focus, button.ButtonsModal:focus, button.ButtonsModal.btn.btn-primary:focus {
    margin-bottom: 10px !important;
}

button.FilledButton2.btn.btn-secondary, button.FilledButton2.btn.btn-secondary:focus, button.FilledButton2.btn.btn-secondary:active, button.FilledButton2.btn.btn-secondary:hover {
    margin-bottom: 17px !important;
}

.react-select-container.withBordersBig.css-2b097c-container {
    height: 35px !important;
    margin-bottom: 10px !important;
}

.ModelPaper.m10mr10.ClientsModal {
    margin: 0 auto !important;
    float: unset !important;
}

.ModelPaper2.ModalinMiddle.m10mr10 {
    overflow: hidden !important;
    height: 430px !important;
    /*width: 585px !important; set responsive as contnet size*/
    max-height: 430px !important;
    overflow-y: auto !important;
    margin: 0 auto;
    left: 50% !important;
    right: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    position: absolute !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px !important;
}

.breakdownReport .ant-tabs-bar.ant-tabs-top-bar, .accountState .ant-tabs-bar.ant-tabs-top-bar, .openpos .ant-tabs-bar.ant-tabs-top-bar {
    position: sticky;
}

.paddingInputs {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.border-radius15 {
    border-radius: 15px !important;
}

.border-radius10 {
    border-radius: 10px !important;
}

div#InvestorsMAM_wrapper {
    background: white !important;
}

.AppformCardsHeight.SelectedCard {
    border: 4px solid #00a0df !important;
}

.singals .ant-tabs-bar {
    margin: 0 0 0px 0 !important;
    background: white;
    position: sticky;
    z-index: 12;
    position: -webkit-sticky;
    width: 100%;
    border-bottom: 0px solid white !important;
    padding: 7px !important;
    background-color: transparent !important;
    color: #000000 !important;
    font-size: 12px !important;
    text-decoration: none !important;
}

    .singals .ant-tabs-bar a.nav-link {
        padding: 7px !important;
        background: #ffffff;
        color: #000000 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }

.TabRightSidebar .ant-tabs-nav-container {
    background: transparent;
}

.ant-tabs.ant-tabs-top.TabsInSettings.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar > .ant-tabs-nav-container {
    border-bottom: 1px solid transparent !important;
}

.TabsDetails .ant-tabs-nav-container {
    background: transparent !important;
    margin-top: -10px !important;
}


.TabsClient .ant-tabs-nav-container {
    /* max-width: 500px !important;*/
    margin-top: -10px !important;
}

.ant-tabs.ant-tabs-top.TabsDetails.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar {
    position: absolute;
}

button#TrasnferBtn2 {
    color: #000000;
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    font-size: 14px;
}

    button#TrasnferBtn2:hover {
        background-color: #00a0df !important;
        border-color: #00a0df !important;
        color: white !important;
    }

.margin20mt0 {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 0px;
}

img.iconAppForm {
    background-size: cover;
}

th {
    font-size: 16px !important;
}

@media (min-width: 300px) {

    .ModelPaper2.ModalPopBody.m10mr10.ModalLogin.ModalAllocation {
        width: 98% !important;
        margin-right: 2% !important;
    }

    .containerMsg {
        padding: 10px !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.text-center.dropdown-item > .row {
        margin-right: 0px !important;
        margin: 0px !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 60px !important;
        width: 60px !important;
        font-size: 25px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 25% !important;
    }

    .insideAccounts, .insideSettings {
        padding-bottom: 100px !important;
    }

    body {
        margin: 0;
        overscroll-behavior: none;
        overscroll-behavior: contain;
        overscroll-behavior-y: contain;
        min-height: 100vh;
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 12% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(60vh - 150px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    .commentIcon {
        display: none;
    }

    .font-24 {
        font-size: 20px !important;
    }

    .CardSide.col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .AppformCardsHeight {
        width: 100% !important;
    }

    .EachLeftColumn.col-sm-2.col-md-2 > nav > span.tabTitle {
        font-size: 24px !important;
    }

    .Mobile75p.col-md-8 {
        flex: 75% 1 !important;
        max-width: 75% !important;
    }

    .CheckNoti {
        width: 20px !important;
        height: 20px !important;
    }

    .SettingIcon {
        height: 25px;
        width: 25px;
    }

    .backiconGet {
        margin-left: 8px;
    }

    button#ActionBtn {
        margin-right: 20px !important;
    }

    .ModelPaper {
        width: 92%;
    }

    li.ant-menu-item, .ant-menu-submenu-title {
        font-size: 16px !important;
    }


    input.loginInput2::-webkit-input-placeholder, input.loginInput2 {
        font-size: 16px !important;
    }

    .spinIndicatorSignIn {
        left: 38% !important;
        align-items: center;
        justify-content: center;
        margin: 0 auto !important;
    }

    .insideMain {
        max-height: 80vh;
    }

    .margin20mt0 {
        margin: 0px;
    }

    .NotificationTab > .ant-tabs-bar.ant-tabs-top-bar {
        width: 55% !important;
    }

    h2.LabelCreate2.t-bold.d-block.TitleCardForm {
        font-size: 18px;
    }

    input.noBorderInputs, input.noBorderInputs:active {
        height: 80px !important;
    }

    .Mobile75p {
        display: inline-flex !important;
        width: 75%;
    }

    .Mobile25p {
        display: inline-flex !important;
        width: 25%;
    }

    .hideMobile, .leftsidebarScreen, .hideFilter, .hideDivImage {
        display: none !important;
    }

    .row.py-5.mt100.align-items-center {
        margin-top: 0px !important;
    }

    .insidenewExternalForm {
        padding: 60px 30px;
    }

    .showMobile {
        display: initial !important;
    }

    .hideText, .showText {
        display: none !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.dropdown-item {
        padding: 8px !important;
    }

    .filterBar.filterSidebar.filterSettings {
        width: 115px !important;
        margin-right: 7px !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: inline !important;
    }

    .TextStatusForm {
        font-size: 13px;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: fit-content;
    }

    .custom-file > label.custom-file-label {
        color: transparent !important;
    }

    .mr320 {
        margin-right: 0px;
    }

    .ml320 {
        margin-left: 0px;
    }

    .steps-content.ContentModal {
        flex-direction: unset !important;
        position: unset !important;
        margin-bottom: 10px !important;
        padding: 10px 10px 0px 10px;
        overflow-y: unset !important;
        overflow-x: unset !important;
        flex-grow: 1 !important;
    }

    .whiteBackgroundBox {
        margin: 0px;
        width: 93%;
        margin-left: 10px;
        margin-top: 10px !important;
    }

    .insideSettings {
        max-height: calc(100vh - 40px);
    }

    .mobilMenuLeftsidebar {
        display: block !important;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser {
        height: 80%;
        width: 68%;
    }

    /* .ModelPaper2 {
        width: 80%;
    }*/
    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 95% !important;
    }

    img.errorimg {
        width: 300px;
    }

        img.errorimg.errorBounding {
            width: 400px;
        }

    .dataTables_scroll, .dataTables_scrollHead {
        width: 100% !important;
        overflow-x: auto;
    }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
        width: 260px;
    }

    h1.PageNotFound {
        font-size: 40px !important;
    }

    .ant-menu-inline {
        width: 260px;
    }

    .left230 {
        left: 240px;
    }

    .ButtonsBottom3 {
        margin-left: 144px;
        bottom: 17px;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        width: 128px;
        margin-left: -33px;
    }

    span.SearchInput.ant-input-affix-wrapper, span.SearchInput.ant-input-affix-wrapper .ant-input {
        display: none;
    }

    .ant-tabs .ant-tabs-left-bar {
        height: unset !important;
    }

    .ThemeIcons {
        width: 50px;
    }

    .ModelPaper4 {
        width: 75%;
    }

    .ModelPaper3 {
        width: 75%;
    }


    .dividerprofile.ant-divider, .ant-divider-vertical {
        display: none;
    }

    .p-multiselect .p-multiselect-panel {
        width: 230px !important;
    }

    .imagelogo {
        width: 100px;
    }

    .ThemeIcons {
        width: 50px !important;
    }

    .heightNavbar.navbar {
        height: 80px;
    }

    .w70 {
        width: 50px !important;
    }

    div#cardRegister {
        height: 154px;
    }

    .dropdown-menu.dropdown-menu-right.show {
        width: 100%;
    }

    button.DropDownFix.mt40.dropdown-item {
        margin-top: 0px !important;
    }

    label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
        width: 200px;
    }

    .ProfileDropdwon.dropdown-menu.dropdown-menu-right.show {
        width: 200px !important;
    }
}

.ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open {
    z-index: 1100;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.ant-menu-submenu-placement-rightTop {
    z-index: 2000 !important;
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {

    .ModelPaper2.ModalPopBody.m10mr10.ModalLogin.ModalAllocation {
        width: 90% !important;
        margin-right: 5% !important;
    }

    .containerMsg {
        padding: 10px !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.text-center.dropdown-item > .row {
        margin-right: 0px !important;
        margin: 0px !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 60px !important;
        width: 60px !important;
        font-size: 25px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 25% !important;
    }

    .insideAccounts, .insideSettings {
        padding-bottom: 100px !important;
    }

    html {
        margin: 0;
        overscroll-behavior: none;
    }

    body {
        margin: 0;
        overscroll-behavior: none;
        overscroll-behavior: contain;
        overscroll-behavior-y: contain;
        min-height: 100vh;
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
    }

    .backgroundNewCards {
        margin-bottom: 10px !important;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 12% !important;
    }

    .mx-auto.d-table > div > img, .d-table-cell.align-middle > div > img {
        width: 100%;
        height: auto;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(60vh - 232px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    .commentIcon {
        display: none;
    }

    .font-24 {
        font-size: 20px !important;
    }

    .CardSide.col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .AppformCardsHeight {
        width: 100% !important;
    }

    .EachLeftColumn.col-sm-2.col-md-2 > nav > span.tabTitle {
        font-size: 24px !important;
    }

    .Mobile75p.col-md-8 {
        flex: 75% 1 !important;
        max-width: 75% !important;
    }

    .CheckNoti {
        width: 20px !important;
        height: 20px !important;
    }

    .SettingIcon {
        height: 25px;
        width: 25px;
    }

    .ModelPaper {
        width: 92%;
    }

    .backiconGet {
        margin-left: 8px;
    }

    button#ActionBtn {
        margin-right: 20px !important;
    }

    .insideMain {
        max-height: 80vh;
    }

    li.ant-menu-item, .ant-menu-submenu-title {
        font-size: 16px !important;
    }

    input.loginInput2::-webkit-input-placeholder, input.loginInput2 {
        font-size: 16px !important;
    }

    .spinIndicatorSignIn {
        left: 38% !important;
        align-items: center;
        justify-content: center;
        margin: 0 auto !important;
    }

    .margin20mt0 {
        margin: 0px;
    }

    h2.LabelCreate2.t-bold.d-block.TitleCardForm {
        font-size: 18px;
    }

    .NotificationTab > .ant-tabs-bar.ant-tabs-top-bar {
        width: 55% !important;
    }

    .hideMobile, .leftsidebarScreen, .hideFilter, .hideDivImage {
        display: none !important;
    }

    .row.py-5.mt100.align-items-center {
        margin-top: 0px !important;
    }

    .insidenewExternalForm {
        padding: 60px 30px;
    }

    .showMobile {
        display: initial !important;
    }

    .hideText, .showText {
        display: none !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.dropdown-item {
        padding: 8px !important;
    }

    .filterBar.filterSidebar.filterSettings {
        width: 150px !important;
        margin-right: 7px !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: inline !important;
    }

    .Mobile75p {
        display: inline-flex !important;
        width: 75%;
    }

    .Mobile25p {
        display: inline-flex !important;
        width: 25%;
    }

    .TextStatusForm {
        font-size: 13px;
    }

    input.noBorderInputs, input.noBorderInputs:active {
        height: 80px;
    }

    .custom-file > label.custom-file-label {
        color: transparent !important;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: fit-content;
    }

    img.iconAppForm {
        height: 50px;
    }

    .mr320 {
        margin-right: 0px;
    }

    .ml320 {
        margin-left: 0px;
    }

    .steps-content.ContentModal {
        flex-direction: unset !important;
        position: unset !important;
        margin-bottom: 10px !important;
        padding: 10px 10px 0px 10px;
        overflow-y: unset !important;
        overflow-x: unset !important;
        flex-grow: 1 !important;
    }

    .whiteBackgroundBox {
        margin: 0px;
        width: 93%;
        margin-left: 10px;
        margin-top: 10px !important;
    }

    .insideSettings {
        max-height: calc(100vh - 40px);
    }

    .mobilMenuLeftsidebar {
        display: block !important;
    }

    .ModelPaper2 {
        width: 80%;
        height: 90%;
    }

        .ModelPaper2.EmailViewModal.m10mr10 {
            width: 95% !important;
        }

    img.errorimg {
        width: 300px;
    }

        img.errorimg.errorBounding {
            width: 400px;
        }

    .dataTables_scroll, .dataTables_scrollHead {
        width: 100% !important;
        overflow-x: auto;
    }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
        width: 260px;
    }

    h1.PageNotFound {
        font-size: 40px !important;
    }

    .ant-menu-inline {
        width: 260px;
    }

    .left230 {
        left: 240px;
    }

    .ButtonsBottom3 {
        margin-left: 139px;
        bottom: 17px;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        width: 128px;
        margin-left: -33px;
    }

    span.SearchInput.ant-input-affix-wrapper, span.SearchInput.ant-input-affix-wrapper .ant-input {
        width: 100px !important;
    }

    .ant-tabs .ant-tabs-left-bar {
        height: unset !important;
    }

    .ThemeIcons {
        width: 50px;
    }

    .ModelPaper4 {
        width: 75%;
    }

    .ModelPaper3 {
        width: 75%;
        height: 90%;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser2 {
        width: 66%;
    }

    .dividerprofile.ant-divider, .ant-divider-vertical {
        display: none;
    }

    .p-multiselect .p-multiselect-panel {
        width: 230px !important;
    }

    .imagelogo {
        width: 85px;
    }

    .heightNavbar.navbar {
        height: 80px;
    }

    .w70 {
        width: 50px !important;
    }

    div#cardRegister {
        height: 154px;
    }

    .dropdown-menu.dropdown-menu-right.show {
        width: 100%;
    }

    button.DropDownFix.mt40.dropdown-item {
        margin-top: 0px !important;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab {
        width: 60%;
    }

    label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
        width: 200px;
    }

    .ProfileDropdwon.dropdown-menu.dropdown-menu-right.show {
        width: 200px !important;
    }
}

@media (min-width: 576px) {

   
    .ModelPaper2.ModalPopBody.m10mr10.ModalLogin.ModalAllocation {
        width: 80% !important;
        margin-right: 10% !important;
    }

    .containerMsg {
        padding: 20px !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.text-center.dropdown-item > .row {
        margin-right: -15px !important;
        margin-left: -15px !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(60vh - 158px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    .commentIcon {
        display: none;
    }

    .CardSide.col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .AppformCardsHeight {
        width: 100% !important;
    }

    .font-24 {
        font-size: 20px !important;
    }

    .font-16, .font-14 {
        font-size: 12px !important;
    }

    .font14 {
        font-size: 10px !important;
    }

    .EachLeftColumn.col-sm-2.col-md-2 > nav > span.tabTitle {
        font-size: 16px !important;
    }

    .Mobile75p.col-md-8 {
        flex: 75% 1 !important;
        max-width: 75% !important;
    }

    .CheckNoti {
        width: 20px !important;
        height: 20px !important;
    }

    .SettingIcon {
        height: 25px;
        width: 25px;
    }

    .ModelPaper {
        width: 92%;
    }

    .backiconGet {
        margin-left: 8px;
    }

    button#ActionBtn {
        margin-right: 20px !important;
    }

    .insideMain {
        max-height: 80vh;
    }

    li.ant-menu-item, .ant-menu-submenu-title {
        font-size: 16px !important;
    }

    input.loginInput2::-webkit-input-placeholder, input.loginInput2 {
        font-size: 16px !important;
    }

    .spinIndicatorSignIn {
        left: 38% !important;
        align-items: center;
        justify-content: center;
        margin: 0 auto !important;
    }

    .margin20mt0 {
        margin: 0px;
    }

    h2.LabelCreate2.t-bold.d-block.TitleCardForm {
        font-size: 18px;
    }

    .NotificationTab > .ant-tabs-bar.ant-tabs-top-bar {
        width: 30% !important;
    }

    .Mobile75p {
        display: inline-flex !important;
        width: 75%;
    }

    .Mobile25p {
        display: inline-flex !important;
        width: 25%;
    }

    .hideMobile, .leftsidebarScreen, .hideFilter, .hideDivImage {
        display: none !important;
    }

    .row.py-5.mt100.align-items-center {
        margin-top: 0px !important;
    }

    .insidenewExternalForm {
        padding: 60px 30px;
    }

    .showMobile .showText {
        display: initial !important;
    }

    .hideText {
        display: none !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.dropdown-item {
        padding: 8px !important;
    }

    .filterBar.filterSidebar.filterSettings {
        width: 150px !important;
        margin-right: 7px !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: inline !important;
    }

    .TextStatusForm {
        font-size: 13px;
    }

    input.noBorderInputs, input.noBorderInputs:active {
        height: 70px !important;
    }

    .mr320 {
        margin-right: 0px;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: fit-content;
    }

    img.iconAppForm {
        height: 50px;
    }

    .ml320 {
        margin-left: 0px;
    }

    .steps-content.ContentModal {
        flex-direction: unset !important;
        position: unset !important;
        margin-bottom: 10px !important;
        padding: 10px 10px 0px 10px;
        overflow-y: unset !important;
        overflow-x: unset !important;
        flex-grow: 1 !important;
    }

    .custom-file > label.custom-file-label {
        color: transparent !important;
    }

    .whiteBackgroundBox {
        margin: 0px;
        width: 93%;
        margin-left: 10px;
        margin-top: 10px !important;
    }

    .margin4Rem {
        margin-right: 4rem !important;
    }

    .insideSettings {
        max-height: calc(100vh - 40px);
    }

    .mobilMenuLeftsidebar {
        display: block !important;
    }

    /*.ModelPaper2 {
        width: 80%;
    }*/
    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 95% !important;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab, .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser {
        width: 66%;
    }

    .ButtonsBottom3 {
        width: 60%;
    }

    img.errorimg {
        width: 300px;
    }

        img.errorimg.errorBounding {
            width: 400px;
        }

    .dataTables_scroll, .dataTables_scrollHead {
        width: 100% !important;
        overflow-x: auto;
    }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
        width: 260px;
    }

    h1.PageNotFound {
        font-size: 40px !important;
    }

    .ant-menu-inline {
        width: 260px;
    }

    .left230 {
        left: 240px;
    }

    .ButtonsBottom3 {
        margin-left: 144px;
        bottom: 17px;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        width: 128px;
        margin-left: -33px;
    }

    .ant-tabs .ant-tabs-left-bar {
        height: unset !important;
    }

    .ModelPaper4 {
        width: 60%;
    }

    li.noDots {
        list-style: none !important;
    }

    .ModelPaper3 {
        width: 40%;
    }

    .dividerprofile.ant-divider, .ant-divider-vertical {
        display: none;
    }

    .p-multiselect .p-multiselect-panel {
        width: 230px !important;
    }

    .imagelogo {
        width: 85px;
    }

    .ThemeIcons {
        width: 50px !important;
    }

    .heightNavbar.navbar {
        height: 80px;
    }

    .w70 {
        width: 50px !important;
    }

    div#cardRegister {
        height: 154px;
    }

    .dropdown-menu.dropdown-menu-right.show {
        width: 100%;
    }

    button.DropDownFix.mt40.dropdown-item {
        margin-top: 0px !important;
    }

    span.SearchInput.ant-input-affix-wrapper, span.SearchInput.ant-input-affix-wrapper .ant-input {
        display: none;
    }

    label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
        width: 300px;
    }

    .ProfileDropdwon.dropdown-menu.dropdown-menu-right.show {
        width: 200px !important;
    }
}

@media (min-width: 660px) {

    .ModelPaper2.ModalPopBody.m10mr10.ModalLogin.ModalAllocation {
        width: 80% !important;
        margin-right: 10% !important;
    }

    .containerMsg {
        padding: 20px !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 368px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 400px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 500px);
    }
}

.DashboardCard.e-panel {
    min-height: 100px !important;
    height: fit-content !important;
}

@media (min-width: 768px) {
    .ModelPaper2.ModalPopBody.m10mr10.ModalLogin.ModalAllocation {
        width: 40% !important;
        margin-right: 25% !important;
    }

    .containerMsg {
        padding: 20px !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 95% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 368px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 400px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 500px);
    }
        .commentIcon {
            display: inherit;
        }

    .CardSide.col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .AppformCardsHeight {
        width: 100% !important;
    }

    .font-24 {
        font-size: 22px !important;
    }

    .font-16, .font-14 {
        font-size: 14px !important;
    }

    .font14 {
        font-size: 12px !important;
    }

    .EachLeftColumn.col-sm-2.col-md-2 > nav > span.tabTitle {
        font-size: 14px !important;
    }

    .Mobile75p.col-md-8 {
        flex: 0 0 50.666667%;
        max-width: 50.666667%;
    }

    .CheckNoti {
        width: 16px !important;
        height: 16px !important;
    }

    .SettingIcon {
        height: 20px;
        width: 20px;
    }

    .ModelPaper {
        width: 75%;
    }

    .insideMain {
        max-height: 85vh;
    }

    input.loginInput2::-webkit-input-placeholder, input.loginInput2 {
        font-size: 14px !important;
    }

    .spinIndicatorSignIn {
        left: 48% !important;
        align-items: initial;
        justify-content: initial;
        margin: initial;
    }

    .margin20mt0 {
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-top: 0px;
    }

    h2.LabelCreate2.t-bold.d-block.TitleCardForm {
        font-size: 18px;
    }

    .NotificationTab > .ant-tabs-bar.ant-tabs-top-bar {
        width: 23% !important;
    }

    .leftsidebarScreen {
        display: none !important;
    }

    input.noBorderInputs, input.noBorderInputs:active {
        height: 70px !important;
    }

    img.iconAppForm {
        display: none;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: 200px;
    }

    .TextStatusForm {
        font-size: 18px;
    }

    .custom-file > label.custom-file-label {
        color: black !important;
    }

    li.ant-menu-item, .ant-menu-submenu-title {
        font-size: 14px !important;
    }

    .backiconGet {
        margin-left: 8px;
    }

    button#ActionBtn {
        margin-right: 0px !important;
    }

    .hideMobile, .hideDivImage {
        display: initial !important;
    }

    .row.py-5.mt100.align-items-center {
        margin-top: 100px !important;
    }

    .insidenewExternalForm {
        padding: 50px 72px;
    }

    .hideFilter {
        display: flex !important;
    }

    .showMobile .showText {
        display: none !important;
    }

    .hideText {
        display: none !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.dropdown-item {
        padding: 0.2rem 1.5rem !important;
    }

    .filterBar.filterSidebar.filterSettings {
        width: 270px !important;
        margin-right: 0px !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: block !important;
    }

    .Mobile75p {
        display: inline-flex !important;
        width: 75%;
    }

    .Mobile25p {
        display: inline-flex !important;
        width: 25%;
    }

    .mr320 {
        margin-right: 320px;
    }

    .ml320 {
        margin-left: 320px;
    }

    .whiteBackgroundBox {
        margin: 10px;
    }

    .mobilMenuLeftsidebar {
        display: block !important;
    }
    /*
    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab, .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser {
        width: 77%;
    }*/

    .ButtonsBottom3 {
        width: 70%;
    }

    .dropdown-menu.dropdown-menu-right.show {
        width: 235px;
    }

    img.errorimg {
        width: 300px;
    }

        img.errorimg.errorBounding {
            width: 400px;
        }

    .dataTables_scroll, .dataTables_scrollHead {
        width: 100% !important;
        overflow-x: auto;
    }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
        width: 260px;
    }

    h1.PageNotFound {
        font-size: 40px;
    }

    .ant-menu-inline {
        width: 260px;
    }

    .left230 {
        left: 240px;
    }

    .ButtonsBottom3 {
        margin-left: 271px;
        bottom: 17px;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        width: 170px;
    }

    .ant-tabs .ant-tabs-left-bar {
        height: unset !important;
    }

    .ThemeIcons {
        width: 50px !important;
    }

    .ModelPaper4 {
        width: 40%;
    }

    .ModelPaper3 {
        width: 30%;
    }



    .p-multiselect .p-multiselect-panel {
        width: 230px !important;
    }

    .imagelogo {
        width: 138px;
    }

    .ThemeIcons {
        width: 50px;
    }

    .heightNavbar.navbar {
        height: 80px;
    }

    .w70 {
        width: 50px !important;
    }

    div#cardRegister {
        height: 154px;
    }

    .dividerprofile.ant-divider, .ant-divider-vertical {
        height: 120px;
        display: unset;
    }

    button.DropDownFix.mt40.dropdown-item {
        margin-top: 40px !important;
    }

    span.SearchInput.ant-input-affix-wrapper .ant-input, span.SearchInput.ant-input-affix-wrapper {
        width: 200px;
        display: unset;
    }

    label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
        width: 400px;
    }

    .ProfileDropdwon.dropdown-menu.dropdown-menu-right.show {
        width: 235px !important;
    }
}

@media (min-width: 800px) {
    .showMobile {
        display: none !important;
    }

    .containerMsg {
        padding: 20px !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 92% !important;
    }

    .leftsidebarScreen {
        display: none !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: block !important;
    }

    .mobilMenuLeftsidebar {
        display: block !important;
    }
}

@media (min-width: 850px) {
    .showMobile {
        display: none !important;
    }

    .containerMsg {
        padding: 20px !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 410px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 400px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 500px);
    }
    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 87% !important;
    }
}

@media (min-width: 900px) {
    .showMobile {
        display: none !important;
    }

    .containerMsg {
        padding: 20px !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 82% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 366px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 400px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 500px);
    }
}

.ant-switch-inner {
    font-size: 8px !important;
}

@media (min-width: 992px) {
    .showMobile {
        display: none !important;
    }

    .containerMsg {
        padding: 80px 160px;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 76% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 366px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 400px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 500px);
    }
    .commentIcon {
        display: inherit;
    }

    .CardSide.col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .AppformCardsHeight {
        width: 100% !important;
    }

    .font-24 {
        font-size: 24px !important;
    }

    .font-16, .font-14 {
        font-size: 16px !important;
    }

    .font14 {
        font-size: 14px !important;
    }

    .EachLeftColumn.col-sm-2.col-md-2 > nav > span.tabTitle {
        font-size: 14px !important;
    }

    .Mobile75p.col-md-8 {
        flex: 50% !important;
        max-width: 50% !important;
    }

    .CheckNoti {
        width: 16px !important;
        height: 16px !important;
    }

    .SettingIcon {
        height: 20px;
        width: 20px;
    }

    .ModelPaper {
        width: 75%;
    }

    .backiconGet {
        margin-left: 8px;
    }

    button#ActionBtn {
        margin-right: 0px !important;
    }

    .insideMain {
        max-height: 85vh;
    }

    button#ActionBtn {
        margin-right: 0px !important;
    }

    input.loginInput2::-webkit-input-placeholder, input.loginInput2 {
        font-size: 14px !important;
    }

    .spinIndicatorSignIn {
        left: 48% !important;
        align-items: initial;
        justify-content: initial;
        margin: initial;
    }

    .margin20mt0 {
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-top: 0px;
    }

    img.iconAppForm {
        display: inherit;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: 200px;
    }

    h2.LabelCreate2.t-bold.d-block.TitleCardForm {
        font-size: 18px;
    }

    .NotificationTab > .ant-tabs-bar.ant-tabs-top-bar {
        width: 23% !important;
    }

    .leftsidebarScreen {
        display: block !important;
    }

    .custom-file > label.custom-file-label {
        color: black !important;
    }

    .TextStatusForm {
        font-size: 18px;
    }

    img.iconAppForm {
        height: 80px;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: 180px;
    }

    input.noBorderInputs, input.noBorderInputs:active {
        height: 60px !important;
    }

    li.ant-menu-item, .ant-menu-submenu-title {
        font-size: 14px !important;
    }

    .hideMobile, .hideDivImage {
        display: initial !important;
    }

    .row.py-5.mt100.align-items-center {
        margin-top: 100px !important;
    }

    .insidenewExternalForm {
        padding: 60px 72px;
    }

    .hideFilter {
        display: flex !important;
    }

    .showMobile {
        display: none !important;
    }

    .showText {
        display: none !important;
    }

    .hideText {
        display: none !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.dropdown-item {
        padding: 0.2rem 1.5rem !important;
    }

    .filterBar.filterSidebar.filterSettings {
        width: 270px !important;
        margin-right: 0px !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: none !important;
    }

    .mr320 {
        margin-right: 320px;
    }

    .ml320 {
        margin-left: 320px;
    }

    .whiteBackgroundBox {
        margin: 10px;
    }

    .mobilMenuLeftsidebar {
        display: none !important;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab, .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser {
        width: 68%;
    }

    .ButtonsBottom3 {
        width: 62%;
    }

    img.errorimg {
        width: unset;
    }

        img.errorimg.errorBounding {
            width: unset;
        }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
        width: 260px;
    }

    .ant-menu-inline {
        width: 260px;
    }

    .left230 {
        left: 240px;
    }

    .ButtonsBottom3 {
        margin-left: 271px;
        bottom: 17px;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        width: 200px;
    }

    .ant-tabs .ant-tabs-left-bar {
        height: unset !important;
    }

    .p-multiselect .p-multiselect-panel {
        width: 230px !important;
    }

    .imagelogo {
        width: 138px;
    }

    .ThemeIcons {
        width: 55px !important;
    }

    .heightNavbar.navbar {
        height: 80px;
    }

    .w70 {
        width: 50px !important;
    }

    div#cardRegister {
        height: 154px;
    }

    .dropdown-menu.dropdown-menu-right.show {
        width: 235px;
    }

    .dividerprofile.ant-divider, .ant-divider-vertical {
        height: 120px;
        display: unset;
    }

    button.DropDownFix.mt40.dropdown-item {
        margin-top: 40px !important;
    }

    span.SearchInput.ant-input-affix-wrapper .ant-input, span.SearchInput.ant-input-affix-wrapper {
        width: 200px;
        display: unset;
    }

    .ButtonsBottom3 {
        margin-left: 313px;
    }

    label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
        width: 600px;
    }

    .ProfileDropdwon.dropdown-menu.dropdown-menu-right.show {
        width: 235px !important;
    }
}

body .p-checkbox .p-checkbox-box.p-highlight,
body .p-checkbox .p-checkbox-box.p-highlight:hover {
    border-color: #00a0df !important;
    background-color: #00a0df !important;
}

@media (min-width: 1000px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 76% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 400px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 500px);
    }
}

@media (min-width: 1100px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 76% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 400px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 500px);
    }
}

@media (min-width: 1200px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 62% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    .commentIcon {
        display: inherit;
    }

    .CardSide.col-md-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .AppformCardsHeight {
        width: 100% !important;
    }

    .font-24 {
        font-size: 24px !important;
    }

    .font-16, .font-14 {
        font-size: 16px !important;
    }

    .font14 {
        font-size: 14px !important;
    }

    .EachLeftColumn.col-sm-2.col-md-2 > nav > span.tabTitle {
        font-size: 24px !important;
    }

    .Mobile75p.col-md-8 {
        flex: 60% 1 !important;
        max-width: 60% !important;
    }

    .CheckNoti {
        width: 16px !important;
        height: 16px !important;
    }

    .SettingIcon {
        height: 20px;
        width: 20px;
    }

    .ModelPaper {
        width: 75%;
    }

    .backiconGet {
        margin-left: 8px;
    }

    button#ActionBtn {
        margin-right: 0px !important;
    }

    .insideMain {
        max-height: 85vh;
    }

    input.loginInput2::-webkit-input-placeholder, input.loginInput2 {
        font-size: 14px !important;
    }

    .spinIndicatorSignIn {
        left: 48% !important;
        align-items: initial;
        justify-content: initial;
        margin: initial;
    }

    img.iconAppForm {
        display: inherit;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: 200px;
    }

    .margin20mt0 {
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-top: 0px;
    }

    h2.LabelCreate2.t-bold.d-block.TitleCardForm {
        font-size: 2rem;
    }

    .NotificationTab > .ant-tabs-bar.ant-tabs-top-bar {
        width: 23% !important;
    }

    .leftsidebarScreen {
        display: block !important;
    }

    .custom-file > label.custom-file-label {
        color: black !important;
    }

    .TextStatusForm {
        font-size: 18px;
    }

    input.noBorderInputs, input.noBorderInputs:active {
        height: 50px !important;
    }

    img.iconAppForm {
        height: 60px;
    }

    .whiteBackgroundBox.AppformCardsHeight {
        height: 180px;
    }

    li.ant-menu-item, .ant-menu-submenu-title {
        font-size: 14px !important;
    }

    .hideMobile, .hideDivImage {
        display: initial !important;
    }

    .row.py-5.mt100.align-items-center {
        margin-top: 100px !important;
    }

    .insidenewExternalForm {
        padding: 60px 72px;
    }

    .hideFilter {
        display: flex !important;
    }

    .showMobile, .showText {
        display: none !important;
    }

    .hideText {
        display: initial !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.dropdown-item {
        padding: 0.2rem 1.5rem !important;
    }

    .filterBar.filterSidebar.filterSettings {
        width: 270px !important;
        margin-right: 0px !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: none !important;
    }

    .mr320 {
        margin-right: 320px;
    }

    .ml320 {
        margin-left: 320px;
    }

    .whiteBackgroundBox {
        margin: 10px;
    }

    .mobilMenuLeftsidebar {
        display: none !important;
    }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
        width: 260px;
    }

    .ant-menu-inline { /*without clloapased*/
        width: 260px;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab, .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser {
        width: 68%;
    }

    .ButtonsBottom3 {
        width: 62%;
    }

    .ant-tabs .ant-tabs-left-bar {
        height: unset !important;
    }

    img.errorimg {
        width: unset;
    }

        img.errorimg.errorBounding {
            width: unset;
        }

    .p-multiselect .p-multiselect-panel {
        width: 230px !important;
    }

    .imagelogo {
        width: 160px;
    }

    .ThemeIcons {
        width: 55px !important;
    }

    .heightNavbar.navbar {
        height: 80px;
    }

    .dropdown-menu.dropdown-menu-right.show {
        width: 235px;
    }

    .dividerprofile.ant-divider, .ant-divider-vertical {
        height: 120px;
        display: unset;
    }

    button.DropDownFix.mt40.dropdown-item {
        margin-top: 40px !important;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        margin-left: 5px;
        width: 230px;
    }

    span.SearchInput.ant-input-affix-wrapper .ant-input, span.SearchInput.ant-input-affix-wrapper {
        width: 200px;
        display: unset;
    }

    .left230 {
        left: 240px;
    }

    .ButtonsBottom3 {
        margin-left: 313px;
    }

    div#cardRegister {
        height: 100%;
    }

    label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
        width: 600px;
    }

    .ProfileDropdwon.dropdown-menu.dropdown-menu-right.show {
        width: 235px !important;
    }
}

@media (min-width: 1300px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 57% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }
}

@media (min-width: 1400px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 51% !important;
    }
}

@media (min-width: 1500px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 47% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }
}

@media (min-width: 1550px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }
}

@media (min-width: 1600px) {
    .ModelPaper2.EmailViewModal.m10mr10 {
        width: 38% !important;
    }

    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 365px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }
}

@media (min-width: 1650px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 320px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 358px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 450px);
    }
}

@media (min-width: 1700px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 320px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 360px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 450px);
    }
}

@media (min-width: 1750px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 320px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 360px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 450px);
    }
}

@media (min-width: 1850px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: none !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 320px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 360px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 450px);
    }
}

@media (min-width: 1920px) {
    span.ant-avatar.avatar.avatarUser.ant-avatar-circle {
        height: 130px !important;
        width: 130px !important;
        font-size: 75px;
    }

    .avatar.avatarUser > span.ant-avatar-string {
        bottom: 40% !important;
        padding-bottom: 1px;
    }

    .ModelPaper2.ModalPopBody {
        margin-right: 28% !important;
    }

    .showOnBigScreen {
        display: flex !important;
    }

    .dataTables_scrollBody {
        overflow: hidden !important;
        overflow-x: auto !important;
        max-height: calc(100vh - 320px);
        min-height: 100% !important;
        overflow-y: auto !important;
    }

    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDepositReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetSignalCopiers_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#investorsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#TradingAccountsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadClosedTradesTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadIBDetailedReportTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#GetWalletsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBAccounts_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadSignalsTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusTableReport_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadBonusClientTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#IBProfCogTableIBProfCogTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#LoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#AccountLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#OperationRequestTransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody,
    div#DelayedQueuedLoadOperationReqTable_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 360px);
    }
    div#TransactionLogs_wrapper > .dataTables_scroll > .dataTables_scrollBody {
        max-height: calc(100vh - 450px);
    }
}

button .p-node-toggler.p-link {
    border: 1px solid transparent;
    background: transparent;
}

.dt-panelfooter.clearfix.col-md-12.mt-3 {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 38px;
}

i.p-node-toggler-icon.pi.pi-chevron-down {
    font-size: 0.8em;
    text-align: center;
    display: flex;
    margin-bottom: 7px;
}

body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    bottom: -.7em;
    margin-left: -13px;
    color: #00a0df;
}

.p-organizationchart-node-content.p-person.p-organizationchart-selectable-node {
    border-radius: 20%;
}

.p-organizationchart-node-content.department-cfo.p-organizationchart-selectable-node {
    border-radius: 20%;
}

.p-organizationchart-node-content.department-cto.p-organizationchart-selectable-node {
    border-radius: 12px !important;
}

.p-organizationchart-node-content.department-coo.p-organizationchart-selectable-node {
    border-radius: 12px !important;
}

.pi-chevron-up:before {
    font-size: 0.8em;
}

li.ant-menu-submenu.ant-menu-submenu-inline.mobileSubMenu > .ant-menu-submenu-title {
    width: calc(100% + -15px) !important;
}

.mobilMenuLeftSideBarMobile {
    padding-top: 10px;
}

@media (min-width: 1500px) {
    .commentIcon {
        display: inherit;
    }

    .CardSide.col-md-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .AppformCardsHeight {
        width: 100% !important;
    }

    .EachLeftColumn.col-sm-2.col-md-2 > nav > span.tabTitle {
        font-size: 24px !important;
    }

    .Mobile75p.col-md-8 {
        flex: 60% 1 !important;
        max-width: 60% !important;
    }

    .CheckNoti {
        width: 16px !important;
        height: 16px !important;
    }

    .SettingIcon {
        height: 20px;
        width: 20px;
    }

    .ModelPaper {
        width: 75%;
    }

    .backiconGet {
        margin-left: 8px;
    }

    button#ActionBtn {
        margin-right: 0px !important;
    }

    .insideMain {
        max-height: 85vh;
    }

    input.loginInput2::-webkit-input-placeholder, input.loginInput2 {
        font-size: 14px !important;
    }

    .spinIndicatorSignIn {
        left: 48% !important;
        align-items: initial;
        justify-content: initial;
        margin: initial;
    }

    img.iconAppForm {
        display: inherit;
    }

    .margin20mt0 {
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-top: 0px;
    }

    h2.LabelCreate2.t-bold.d-block.TitleCardForm {
        font-size: 2rem;
    }

    img.iconAppForm {
        height: 80px;
    }

    .NotificationTab > .ant-tabs-bar.ant-tabs-top-bar {
        width: 23% !important;
    }

    .TextStatusForm {
        font-size: 18px;
    }

    .leftsidebarScreen {
        display: block !important;
    }

    .custom-file > label.custom-file-label {
        color: black !important;
    }

    input.noBorderInputs, input.noBorderInputs:active {
        height: 40px !important;
    }

    li.ant-menu-item, .ant-menu-submenu-title {
        font-size: 14px !important;
    }

    .hideMobile, .hideDivImage {
        display: initial !important;
    }

    .row.py-5.mt100.align-items-center {
        margin-top: 100px !important;
    }

    .insidenewExternalForm {
        padding: 60px 72px;
    }

    .hideFilter {
        display: flex !important;
    }

    .showMobile .showText {
        display: none !important;
    }

    .hideText {
        display: initial !important;
    }

    button.DropDownFix.hoverIcon.ProfileDrop.cursor-pointer.dropdown-item {
        padding: 0.2rem 1.5rem !important;
    }

    .filterBar.filterSidebar.filterSettings {
        width: 270px !important;
        margin-right: 0px !important;
    }

    .mobilMenuLeftSideBarMobile {
        display: none !important;
    }

    .mr320 {
        margin-right: 320px;
    }

    .ml320 {
        margin-left: 320px;
    }

    .whiteBackgroundBox {
        margin: 10px;
    }

    .mobilMenuLeftsidebar {
        display: none !important;
    }

    label.d-block.CheckboxTitle2.WrapDiv.ant-checkbox-wrapper, label.d-block.checkRed.ant-checkbox-wrapper {
        width: 800px;
    }

    .ThemeIcons {
        width: 55px !important;
    }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
        width: 260px;
    }

    .ant-menu-inline { /*without clloapased*/
        width: 260px;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser.widerTab, .ant-tabs-tabpane.ant-tabs-tabpane-active.TabEachUser {
        width: 75%;
    }

    .ButtonsBottom3 {
        width: 62%;
    }

    .heightNavbar.navbar {
        height: 45px;
    }

    img.errorimg {
        width: unset;
    }

        img.errorimg.errorBounding {
            width: unset;
        }

    .ModelPaper {
        height: 98%;
        width: 75%;
    }

    .ModelPaper2 {
        height: 98%;
        width: 30%;
    }

    .ModelPaper3 {
        height: 98%;
        width: 17%;
    }

    .ModelPaper4 {
        height: 98%;
        width: 24%;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        margin-left: 5px;
    }

    .dropdown-menu.dropdown-menu-right.show {
        width: 235px;
    }

    .dividerprofile.ant-divider, .ant-divider-vertical {
        height: 120px;
        display: unset;
    }

    button.DropDownFix.mt40.dropdown-item {
        margin-top: 40px !important;
    }

    .TabsUser > div.ant-tabs-bar.ant-tabs-left-bar {
        margin-left: 5px;
        width: 260px;
    }

    span.SearchInput.ant-input-affix-wrapper .ant-input, span.SearchInput.ant-input-affix-wrapper {
        width: 200px;
        display: unset;
    }

    .left230 {
        left: 240px;
    }

    .imagelogo {
        width: 160px;
    }

    .ButtonsBottom3 {
        margin-left: 313px;
    }

    div#cardRegister {
        height: 100%;
    }
}

.w12 {
    width: 12px;
}

p.expiredLinkText {
    color: #121212;
    font-size: 15px;
    text-align: center;
}

.w10 {
    width: 10px;
}

.MuiPaper-root.MuiExpansionPanel-root.mt-2.RolesExpansion.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    background: transparent;
    border: none !important;
    width: 300px !important;
}

.react-select.cursor-pointer.LargeSelect.__placeholder.css-1wa3eu0-placeholder {
    cursor: text !important;
    white-space: normal;
}

.MuiExpansionPanelDetails-root.RolesDetails {
    padding: 0px 24px 7px;
}

.MuiExpansionPanelDetails-root.emailTemplateContent {
    max-height: 80vh;
    overflow: hidden;
    overflow-y: auto;
}

.singals .ant-tabs-nav .ant-tabs-tab-active {
    color: #121212 !important;
    border-radius: 4px !important;
}

.ant-tabs.ant-tabs-top.TabsClient.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar {
    position: relative;
}

.react-select.cursor-pointer.SelectExternal.__control.css-yk16xz-control,
.react-select.cursor-pointer.SelectExternal.__control.css-1pahdxg-control {
    border-radius: 5px !important;
    height: 35px;
}

.react-select.cursor-pointer.SelectExternal.__indicators.css-1hb7zxy-IndicatorsContainer {
    padding-right: 10px;
}

.css-1pahdxg-control, .css-4ljt47-MenuList, .css-yk16xz-control {
    position: static !important;
    overflow: initial !important;
}
/* multi select overflow*/
/* .p-multiselect-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
    position: static !important;
    overflow: initial !important;
}*/
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive, .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive {
    height: 60vh !important;
}
/* Menu mobile */
.foo-menu {
    position: relative;
    z-index: 1000 !important;
}

.menu-active .top-nav-bar {
    z-index: 80;
}

.top-nav-bar {
    position: relative;
    background-color: #008AE6;
    color: #FFF;
}

.am-navbar-title {
    color: #FFF !important;
}

.menu-mask {
    content: ' ';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 79;
}

.css-1pahdxg-control, .css-4ljt47-MenuList {
    overflow: hidden !important;
    overflow-y: auto !important;
}

@media (max-width: 480px) {
    .showOnBigScreen {
        display: none !important;
    }

    .ant-steps-horizontal.ant-steps-label-horizontal {
        display: none !important;
    }
}

.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    position: relative;
    left: 18%;
    width: initial;
    bottom: 10px;
    text-align: center;
    width: 40% !important;
}

.ant-tabs.ant-tabs-top.NotificationTab.ant-tabs-line > .ant-tabs-bar.ant-tabs-top-bar > .ant-tabs-nav-container {
    background: transparent !important;
    width: initial !important;
}

.mobilMenuLeftsidebar {
    margin-left: 15px;
    margin-right: 15px;
}

.ant-menu-item-group-title {
    display: none !important;
}

.Menuicon {
    margin-left: -22px !important;
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    width: 25px !important;
    height: 30px !important;
    color: #00a0df;
}

.control-pane.row, .control-section, div#rteAPI {
    width: 100% !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.mt45.TabEachUser.addHeight {
    height: 90% !important;
    width: 100% !important;
}

div#richtexteditor_714620837_9_toolbar {
    z-index: 1 !important;
}

button#Transfers {
    width: 100% !important;
    background-color: #051c2c !important;
    border-color: #051c2c !important;
}

button#EditActions {
    background: transparent !important;
    border-color: transparent !important;
}

.ant-card-head-title {
    overflow: initial !important;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: #00a0df !important;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #00a0df !important;
}

span.ant-calendar-picker-input.ant-input {
    width: 100% !important;
}

span.ActivityBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.MuiExpansionPanelDetails-root.CommentDetails {
    background: transparent !important;
}

p.MuiTypography-root.titlePanel.MuiTypography-body1 {
    font-weight: 400 !important;
}

.MuiPaper-root.MuiCard-root.ActivityCard.mb-2.mr-2.mt-2.ml-2.MuiPaper-elevation1.MuiPaper-rounded > .MuiCardContent-root {
    padding: 0 !important;
}

span.ActivityTitleText {
    font-size: 16px !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    width: 250px !important;
}

.react-select.cursor-pointer.LargeSelect.__control.css-yk16xz-control {
    height: 30px !important;
    min-height: 30px !important;
    width: 100%;
    white-space: normal;
}

.react-select.cursor-pointer.SelectRegister__input > input {
    border: 1px solid transparent !important;
}

.react-select.cursor-pointer.__input > input {
    border: 1px solid transparent !important;
}

.react-select.cursor-pointer.LargeSelect.__control.css-yk16xz-control {
    height: 30px !important;
    width: 100%;
    white-space: normal;
}
/* if any problem with select */
.css-yk16xz-control, .css-1pahdxg-control {
    min-height: 30px !important;
    line-height: 15px !important;
}

.p-multiselect.p-component.multiChildren {
    margin-left: 0px !important;
}

.form-control {
    height: 30px !important;
}

.css-g1d714-ValueContainer {
    height: 25px !important;
}

.react-select.cursor-pointer.SelectRegister__input > input {
    border: 1px solid transparent !important;
}

.e-richtexteditor .e-rte-toolbar.e-control[class*='e-toolbar'], .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-control[class*='e-toolbar'] {
    z-index: 1 !important;
}

.css-4ljt47-MenuList, .css-11unzgr-MenuList {
    max-height: 180px !important;
}

.ant-tabs.ant-tabs-left.TabsUser.ant-tabs-vertical.ant-tabs-line > .ant-tabs-bar.ant-tabs-left-bar > .ant-tabs-nav-container {
    width: 100% !important;
}

label.LabelCreate2.EmailElement {
    padding: 8px;
    margin: 0 0 8px;
    display: block;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ebeff3;
    box-shadow: 0 6px 14px 0 rgba(24,50,71,.05);
    font-weight: 500;
    font-size: 16px !important;
}

.ProfileDropdwon.UserDropdown.dropdown-menu.dropdown-menu-right.show {
    margin-left: 75px;
    width: 140px !important;
}

button .MuiButtonBase-root.MuiButton-root.MuiButton-text.ButtonUITemplate {
    padding: 10px;
    margin: 0 0 8px;
    display: block;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ebeff3;
    box-shadow: 0 6px 14px 0 rgba(24,50,71,.05);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 100%;
    text-align: left;
    font-weight: 500;
    cursor: unset !important;
    text-transform: none;
}

.ant-form-item-label {
    line-height: 20px !important;
    height: 30px !important;
}

li.ant-menu-item.SettingsMenu {
    height: 40px !important;
}

.p-multiselect.p-component.d-block.ShowSearch.multiselect-custom {
    border: 1px solid #ddd !important;
}

    .p-multiselect.p-component.d-block.ShowSearch.multiselect-custom > .p-multiselect-label-container > label.p-multiselect-label {
        color: #889098 !important;
    }

    .p-multiselect.p-component.d-block.ShowSearch.multiselect-custom > .p-multiselect-trigger,
    .p-multiselect.p-component.d-block.ShowSearch.multiselect-custom > .p-multiselect-trigger > span.p-multiselect-trigger-icon.pi.pi-chevron-down.p-c {
        color: #889098 !important;
    }

.Payments {
    margin: 0 auto;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

svg.svg-inline--fa.fa-w-16.iconsWallet {
    font-size: 42px !important;
    margin-bottom: 10px;
    color: white;
}

.paymentsIconDep {
    background-color: #ff6584;
}

.paymentsIconWith {
    background-color: #656ba0;
}

.paymentsIconTrans {
    background-color: #3ad29f;
}

input.noBorderInputs.form-control-lg.form-control {
    background: transparent;
}

.disabledIcons {
    background-color: teal;
    cursor: default;
}

label.WalletLabel {
    color: white !important;
}

i.iconCards, i.iconPercentage {
    color: #00a0df;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    font-size: 40px !important;
    padding: 10px;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    display: inline-block;
    position: absolute;
    left: 58%;
    margin-left: -50px;
}

i.iconPromotion {
    color: white;
    background-position: center;
    font-size: 40px !important;
    padding: 10px;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    display: inline-block;
    position: absolute;
    left: 58%;
    margin-left: -50px;
}

i.AppfromCards {
    color: #00a0df;
    background-position: center;
    font-size: 40px !important;
    padding: 10px;
    align-items: center;
}

i.AppfromCardsSub {
    color: #00a0df;
    background-position: center;
    font-size: 30px !important;
    padding: 10px;
    align-items: center;
}

.flexWrap {
    flex-flow: wrap !important;
}

i.iconCards2 {
    width: 50px !important;
    height: 50px !important;
    color: white;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    font-size: 20px !important;
    padding: 10px;
    background: #00a0df;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    display: inline-block;
    position: absolute;
    left: 50%;
    margin-left: -50px;
}

i.iconPercentage {
    background: white !important;
}

i.userTie {
    color: #889098;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    font-size: 40px !important;
    padding: 10px;
    background: white;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    display: inline-block;
    position: absolute;
    left: 50%;
    margin-left: -50px;
}

.fa-aud::before {
    content: "A";
    font-size: 30px;
}

.fa-aud::after {
    content: "\f155";
}

.fa-chf:before {
    content: "";
    font-size: 30px;
}

.fa-chf:after {
    content: "CHF";
    font-size: 30px;
}

.fa-zar:before {
    content: "";
}

.fa-zar:after {
    font-weight: bold;
    content: "R";
}

.fa-cad::before {
    content: "C";
}

.fa-cad::after {
    content: "\f155";
}

.fa-xau:before {
    content: "";
}

.fa-xau:after {
    font-weight: bold;
    content: "XAU";
    font-size: 30px;
}

.fa-xpt:before {
    content: "";
}

.fa-xpt:after {
    font-weight: bold;
    content: "XPT";
    font-size: 30px;
}

.fa-dkk:before {
    content: "";
}

.fa-dkk:after {
    font-weight: bold;
    content: "Kr";
    font-size: 30px;
}

.fa-huf:before {
    content: "";
}

.fa-huf:after {
    font-weight: bold;
    content: "Ft";
    font-size: 30px;
}

.fa-mxn:before {
    content: "";
}

.fa-lbp:after {
    font-weight: bold;
    content: "LBP";
    font-size: 30px;
}

.fa-lbx:before {
    content: "";
}

.fa-lbx:after {
    font-weight: bold;
    content: "LBx";
    font-size: 30px;
}

.fa-lbp:before {
    content: "";
}

.fa-mxn:after {
    font-weight: bold;
    content: "MXN";
    font-size: 30px;
}

.fa-nok:before {
    content: "";
}

.fa-nok:after {
    font-weight: bold;
    content: "kr";
    font-size: 30px;
}

.fa-nzd::before {
    content: "NZ";
}

.fa-nzd::after {
    content: "\f155";
    font-size: 30px;
}

.fa-pln::before {
    content: "";
}

.fa-pln::after {
    content: "zł";
    font-size: 30px;
}

.fa-sek::before {
    content: "";
}

.fa-sek::after {
    content: "kr";
    font-size: 60px;
}

.fa-sgd::before {
    content: "";
}

.fa-sgd::after {
    content: "SGD";
    font-size: 30px;
}

.fa-xag::before {
    content: "";
}

.fa-xag::after {
    content: "XAG";
    font-size: 30px;
}

.fa-xpd::before {
    content: "";
}

.fa-xpd::after {
    content: "XPD";
    font-size: 30px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.ButtonUITemplate.AddPaddng {
    padding: 15px;
    cursor: pointer !important;
}

.cursor-move {
    cursor: move !important;
}

/*react-draggable-dragged, .handle {
    width: 50%;
}*/

react-draggable {
    width: 100%;
}

.w50percent {
    width: 50% !important;
}

.ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer > .ant-drawer-content-wrapper {
    max-width: 100% !important;
}

.ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer {
    z-index: 3000;
}

    .ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer > .ant-drawer-content-wrapper {
        height: 48px !important;
    }

    .ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer.no-mask.no-mask > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body {
        overflow: hidden !important;
    }

    .ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer > .ant-drawer-mask, .ant-drawer.ant-drawer-left.ant-drawer-open > .ant-drawer-mask {
        opacity: 0.1 !important;
        -webkit-animation: unset !important;
        animation: unset !important;
    }

.ant-drawer.ant-drawer-left.ant-drawer-open > .ant-drawer-content-wrapper {
    width: 232px !important;
}

.ant-drawer.ant-drawer-left.ant-drawer-open.no-mask {
    z-index: 3001 !important;
}

.ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer.no-mask.no-mask > .ant-drawer-content,
.ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer.no-mask.no-mask > .ant-menu-root.ant-menu-inline,
.ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer.no-mask.no-mask > .ant-drawer-wrapper-body,
.ant-drawer.ant-drawer-top.ant-drawer-open.topDrawer.no-mask.no-mask > .ant-drawer-body {
    background: #00a0df !important;
    background-color: #00a0df !important;
    border-color: #00a0df !important;
}

.marginAuto200 {
    margin: 0px 200px auto;
}

.dt-panelfooter.clearfix {
    position: relative;
}

button.customBtn {
    background: #00a0df;
    border: 1px solid #00a0df !important;
    height: 30px;
    line-height: 18px;
    width: 200px;
    padding: 3px !important;
    font-size: 15px;
    color: white !important;
    font-weight: 500;
}

    button .customBtn.btn.btn-secondary, button.customBtn.btn.btn-secondary:hover, button.customBtn.btn.btn-secondary:focus, button.customBtn.btn.btn-secondary:active {
        background: #00a0df;
        border: 1px solid #00a0df !important;
    }

.overflowxCards {
    overflow: hidden;
    overflow-x: scroll;
}

label.custom-control-label.CustomInputSignal::before {
    margin-top: 10px;
    border-radius: 0px !important;
}

img.logoTop {
    margin-left: 25%;
    height: 40px;
    width: 40px;
}


.e-richtexteditor .e-rte-toolbar.e-control[class*='e-toolbar'], .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-control[class*='e-toolbar'] {
    z-index: 1 !important;
}


.react-select.cursor-pointer.LastSelect.__menu-list.css-4ljt47-MenuList, .css-4ljt47-MenuList {
    position: static !important;
    overflow: initial !important;
    overflow-y: auto !important;
    max-height: 80px !important;
}

.react-select.cursor-pointer.LargeSelect.LastSelect.css-26l3qy-menu {
    max-height: 80px !important;
}
/* react select when opened */
.react-select.cursor-pointer.LastSelect.__menu-list.css-11unzgr {
    max-height: 80px !important;
}

.ant-tabs.ant-tabs-top.mh100vhm140.TabsClient.ArabicTabs.ant-tabs-line {
    overflow: unset !important;
}


@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 20mm;
}

input[type="radio"].radioDeclaration {
    border: 2px solid #00a0df !important;
    border-radius: 50% !important;
    cursor: pointer;
    display: inline-flex !important;
    height: 17px;
    margin-right: 15px;
    position: relative;
    width: 17px;
    -webkit-appearance: none;
}

    input[type="radio"].radioDeclaration:after {
        background-color: transparent;
        border-radius: 25px;
        content: '';
        display: block;
        height: 7px;
        left: 3px;
        position: relative;
        top: 3px;
        width: 7px;
    }

    input[type="radio"].radioDeclaration:focus {
        box-shadow: unset !important;
        outline: unset !important;
    }

    input[type="radio"].radioDeclaration:checked:after {
        background-color: #00a0df;
        border: 2px solid #00a0df !important;
    }

button.ButtonsModal.AddBtn.ml-2.btn.btn-secondary.disabled > svg.svg-inline--fa.fa-edit.fa-w-18.cursor-pointer.mr-2 {
    cursor: not-allowed !important;
}

.ant-tooltip-inner > a.titleMenu > img {
    margin-left: 0px !important;
}

.ant-tabs.ant-tabs-left.TabsUser.ant-tabs-vertical.ant-tabs-line > .ant-tabs-bar.ant-tabs-left-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav.ant-tabs-nav-animated > div > .ant-tabs-tab-active.ant-tabs-tab {
    color: #889098 !important;
}

.buttonsBottomModal {
    bottom: 30px !important;
    position: absolute;
    float: right !important;
    right: 0px !important;
}

.rdw-colorpicker-modal {
    left: unset !important;
    right: 5px !important;
}

span.NotValidatedTab {
    color: #889098 !important;
}

span.ValidatedTab {
    color: #00a0df !important;
}

li.ant-menu-item.MenuItemsSub {
    margin: 0px !important;
    line-height: 45px;
    height: 45px !important;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 45px !important;
    height: 45px !important;
    margin: 0px !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 45px !important;
}

.ant-col.ant-form-item-label {
    height: unset !important;
    line-height: unset !important;
}

.react-select.cursor-pointer.__control.css-yk16xz-control {
    overflow: hidden !important;
    overflow-y: auto !important;
}

.semicircle-container > svg > circle:nth-child(2) {
    stroke: #00a0df !important;
}

.progress1 {
    margin: 4px;
    text-align: center;
}

i.fas.fa-user-alt.clientUser {
    color: #00a0df !important;
    font-size: 20px;
}

i.fas.fa-user-shield.adminIcon {
    color: #cdde00 !important;
    font-size: 20px;
}

.barOverflow { /* Wraps the rotating .bar */
    margin: -14px auto !important;
    overflow: hidden;
    width: 200px;
    height: 100px;
}

.darkSettings.continerMenu.my-custom-scrollbar.my-custom-scrollbar-primary.border-rightGray.scrollableMenu {
    min-height: calc(100vh - 45px);
    overflow: hidden !important;
    max-height: calc(100vh -100px);
    overflow-y: auto !important;
}

.bar1 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 12px solid #f5f6fa;
    border-bottom-color: #00a0df;
    border-right-color: #00a0df;
}

.barOverflowTotal { /* Wraps the rotating .bar */
    margin: -14px auto !important;
    overflow: hidden;
    width: 170px;
    height: 70px;
}

.bar2 {
    width: 170px;
    height: 165px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 12px solid #f5f6fa;
    border-bottom-color: #00a0df;
    border-right-color: #00a0df;
}

/*dashboard admin*/
#target_dash .sidebar-content {
    padding: 10px;
    width: calc(100% - 60px);
}

#target_dash .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    height: 55px;
    position: relative;
}

#analytic_dashboard.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header {
    padding: 10px;
    height: 35px;
    margin: 0 15px 0 15px;
}

#target_dash #pie,
#target_dash #pieChart,
#target_dash #colChart,
#target_dash #visitorsChart,
#target_dash #maps {
    width: 100% !important;
    height: 100% !important;
}

#dashboard_sidebar_section {
    padding: 0px !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

#target_dash .e-bigger #search {
    display: none;
}

@media (max-width: 650px) {
    .showOnBigScreen {
        display: none !important;
    }

    .e-bigger .searchContent {
        display: none;
    }

    .information {
        right: 17% !important;
    }

    #target_dash #pieChart,
    #target_dash #colChart,
    #target_dash #visitorsChart,
    #target_dash #pie,
    #target_dash #maps {
        width: 100% !important;
        height: 100% !important;
    }
}

#dockSideDash {
    text-align: center;
}

#dashboard_sidebar_section .e-icons::before {
    font-size: 20px;
}

#target_dash li.sidebar-item.filterHover {
    background: #eef4f7;
    border-left: 4px solid #057ece;
}

#target_dash .right-content {
    float: right;
    height: 55px;
    width: 100px;
}

#target_dash #right-sidebar {
    display: none;
}

#target_dash .e-dock.e-close span.e-text {
    display: none;
}

#target_dash .e-dock.e-open span.e-text {
    display: inline-block;
}

#target_dash #dockSideDash li {
    list-style-type: none;
    cursor: pointer;
    padding: 5px;
}

#target_dash #dockSideDash ul {
    padding: 0px;
}

#dashboard_sidebar_section span.e-icons {
    line-height: 2;
}

#dashboard_sidebar_section .e-open .e-icons {
    margin-right: 16px;
}

#target_dash .analysis {
    font-size: 18px;
    padding: 12px;
    text-align: left;
    vertical-align: middle;
}

#target_dash #search {
    margin-left: 10px;
    text-indent: 8px;
}

#target_dash .searchContent .e-input-group.e-control-wrapper.e-ddl {
    height: 28px !important;
}

#target_dash .piecontent .content {
    display: inline-block;
    padding: 4px;
    width: 31%;
    font-size: 13px;
    text-align: center;
}

#target_dash .card-content.text {
    font-size: 15px;
    text-align: right;
    color: #66696b;
}

#target_dash .card {
    margin-right: 5%;
    margin-top: 10%;
}

#target_dash .card-content.number {
    font-size: 16px;
    text-align: right;
    padding-top: 10px;
}

#analytic_dashboard.e-dashboardlayout.e-control.e-responsive {
    width: 100% !important;
    overflow: hidden;
}

#analytic_target {
    background: linear-gradient(-141deg, #eef5f7 14%, #eef2fb 100%);
}


#target_dash .searchContent {
    display: inline-block;
    position: absolute;
    left: 60px;
}

#target_dash .e-panel-content {
    height: calc(100% - 35px) !important;
    width: 100%;
}

#target_dash .card .home::before {
    font-size: 25px;
}

#target_dash .dashboardParent {
    width: 100%;
}

/* styles for highcontrast theme */

body.highcontrast #dashboard_sidebar_section {
    border: 1px solid #969696;
}

body.highcontrast #target_dash .header {
    border-bottom: 1px solid #969696;
}

body.highcontrast #dockSideDash .e-icons::before {
    color: #fff;
}

body.highcontrast #dockSideDash li.sidebar-item.filterHover, body.highcontrast #dockSideDash {
    background: #000;
}

body.highcontrast #analytic_dashboard.e-dashboardlayout.e-control .e-panel {
    background: #000;
}

    body.highcontrast #analytic_dashboard.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header {
        color: rgba(255, 255, 255, 0.54);
    }

i.iconDashboard {
    color: #00a0df;
    background: white !important;
    font-size: 58px !important;
    padding: 10px;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    display: inline-block;
    position: absolute;
    left: 58%;
    margin-left: -50px;
}

/*table.dataTable thead > tr > th {
    width: 150px !important;
    white-space: nowrap;
}
div#AllCLientsTable_wrapper > .dataTables_scroll > .dataTables_scrollHead > .dataTables_scrollHeadInner > table.dataTable thead > tr > th:first-child,
table#AllCLientsTable > .table tbody tr td:first-child,
table#AllCLientsTable > div.dataTables_scrollBody tbody tr:first-child td {
    width: 50px !important;
}*/
table.dataTable td, table.dataTable th {
    min-width: 50px !important;
    white-space: nowrap;
    overflow-wrap: initial !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

div#ib-breakDown-tbl_wrapper > .dataTables_scroll > .dataTables_scrollHead > .dataTables_scrollHeadInner > table.dataTable thead > tr > th,
div#client-ib-breakDown-tbl_wrapper > .dataTables_scroll > .dataTables_scrollHead > .dataTables_scrollHeadInner > table.dataTable thead > tr > th {
    white-space: unset !important;
}

svg .highcharts-root {
    height: 320px !important;
}

.highcharts-container {
    height: 320px !important;
}

.DTFC_ScrollWrapper {
    width: 100% !important;
}

.dataTables_scrollBody > table.dataTable thead > tr > th {
    border-right: 1px solid transparent !important;
}

.dataTables_scrollHeadInner > table.dataTable thead > tr > th {
    border-right: 1px solid rgba(0, 0, 0, .08) !important;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0.7 !important;
}

svg.highcharts-root {
    max-height: 100% !important;
}

.NewDashboardHeader.card-header {
    padding: 16px 10px !important;
}

.backgroundCharts.h400 {
    height: 400px !important;
}

.backgroundCharts.w100p.mb-3.noMax {
    height: 100% !important;
}

.ThemeDropDownMenu.dropdown-menu.dropdown-menu-right.show {
    max-height: 390px !important;
    overflow: hidden !important;
    overflow-y: auto !important;
}

a.list-group-item.noPlatformsLinks {
    cursor: not-allowed !important;
}

a.list-group-item.platformsLinks, a.list-group-item.noPlatformsLinks {
    width: 100% !important;
    border-radius: 5px !important;
}

    a.list-group-item.platformsLinks:hover {
        background: #F5F6FA !important;
    }

.ant-input-password-icon {
    color: #889098 !important;
}

input.ant-input:focus,
input.ant-input:active,
input.ant-input:hover {
    box-shadow: none !important;
    outline: unset !important;
}

span.ant-input-password.ant-input-affix-wrapper > input.ant-input,
span.ant-input-password.ant-input-affix-wrapper > input.ant-input:focus,
span.ant-input-password.ant-input-affix-wrapper > input.ant-input:active,
span.ant-input-password.ant-input-affix-wrapper > input.ant-input:hover {
    color: #495057;
    background: #fff;
    border: 1px solid #ddd !important;
}

.textWhite {
    color: #889098 !important;
}

span.ant-input-password.DarkMode.ant-input-affix-wrapper > input.ant-input,
span.ant-input-password.DarkMode.ant-input-affix-wrapper > input.ant-input:focus,
span.ant-input-password.DarkMode.ant-input-affix-wrapper > input.ant-input:hover,
span.ant-input-password.DarkMode.ant-input-affix-wrapper > input.ant-input:active {
    height: 30px !important;
}

span.rangePicker.FilterRange.d-block.TransparentDate.ant-calendar-picker {
    border: unset !important;
}

.field-icon {
    margin-top: -25px;
    position: relative;
    margin-right: 10px;
    color: #889098 !important;
    z-index: 2;
    cursor: pointer !important;
}

html[dir="ltr"] .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    margin-right: 10px;
    color: #889098 !important;
    z-index: 0;
    cursor: pointer !important;
}

html[dir="rtl"] .field-icon {
    float: left !important;
    margin-right: -25px;
    margin-left: unset !important;
}

html[dir="rtl"] .ant-input-affix-wrapper .ant-input-suffix {
    left: 12px !important;
    right: unset !important;
}

html[dir="rtl"] svg.MuiSvgIcon-root.closeButton {
    transform: scaleX(-1);
}

label .RegisterTextSquared {
    font-size: 45px !important;
    color: white !important;
    font-weight: 500 !important;
}

button.RegisterBtn1.btn.btn-secondary {
    border-radius: 20px;
    background: transparent;
    border: 2px solid white !important;
    border: border-box;
    font-size: 20px;
    font-weight: 400;
    height: 45px !important;
    line-height: 35px !important;
}

    button.RegisterBtn1.btn.btn-secondary:hover, button.RegisterBtn1.btn.btn-secondary:active, button.RegisterBtn1.btn.btn-secondary:focus {
        background: white;
        color: black;
        box-shadow: unset !important;
    }

label.resetSquared, label.resetSquared:focus, label.resetSquared:active, label.resetSquared:hover {
    text-align: right !important;
    color: black !important;
    font-size: 14px;
    text-decoration: underline;
    /* line-height: 70px !important; */
}

.LoginFormSquared {
    background: white;
    padding: 30px;
    border-radius: 3px;
}

label.textFormSquared {
    color: white !important;
    font-size: 12px !important;
}

label.LoginTitle.text-center {
    color: black !important;
    padding-bottom: 20px;
    text-align: center !important;
    font-size: 25px;
}

button.ButtonSquared.btn.btn-secondary.btn-sm, button.ButtonSquared.btn.btn-secondary.btn-sm:hover, button.ButtonSquared.btn.btn-secondary.btn-sm:focus, button.ButtonSquared.btn.btn-secondary.btn-sm:active {
    background: black !important;
    color: white !important;
    border-radius: 20px;
    width: 130px;
    height: 40px !important;
    margin: 10px;
    font-weight: bold;
}

.ant-form-item-control {
    line-height: inherit !important;
}

label.textFormSquared {
    color: white !important;
    font-size: 12px !important;
}

label.LoginTitle.text-center {
    color: black !important;
    padding-bottom: 20px;
    text-align: center !important;
    font-size: 25px;
}

.carousel-inner.paymentCarousel.CarouselFilters.mx-auto, .filterScroll.carousel-inner.paymentCarousel.CarouselFilters.m-auto.carousel.slide > .carousel-inner {
    overflow: initial !important;
}

.filterScroll.carousel-inner.paymentCarousel.CarouselFilters.m-auto.carousel.slide {
    overflow: initial !important;
}

.carousel.slide.carousel-multi-item.filterScroll {
    width: 85% !important;
}

.w60p {
    width: 60% !important;
}

.w80p {
    width: 80% !important;
}

input.filterDate.e-control.e-datepicker.e-lib.e-input.e-keyboard {
    background: #f5f6fa !important;
    border: unset !important;
    color: #889098 !important;
    border-radius: 5px !important;
    margin-left: 10px;
    height: 28px;
    min-width: 70px !important;
}

span.e-input-group.e-control-wrapper.filterDateWrapper.e-date-wrapper {
    width: 120px !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: transparent !important;
}

input.ml-3.mb-3.w100.p10.FilterInput.form-control-lg.form-control {
    margin-right: 10px !important;
}

a.carousel-control-prev {
    justify-content: flex-start !important;
}

a.carousel-control-next {
    justify-content: flex-end !important;
}

.filterScroll.carousel-inner.paymentCarousel.CarouselFilters.m-auto.carousel.slide > .carousel-inner {
    width: 87% !important;
    margin: auto !important;
}

.MuiButtonBase-root.MuiExpansionPanelSummary-root.Mui-expanded {
    margin-top: 10px;
}

button.ParamtersBtn.btn.btn-secondary, button.ParamtersBtn.btn.btn-secondary:active, button.ParamtersBtn.btn.btn-secondary:focus {
    background: transparent;
    color: #889098 !important;
    border: unset !important;
    box-shadow: unset !important;
    outline: unset !important;
    width: 100%;
    text-align: left;
    border-radius: 5px !important;
}

    button.ParamtersBtn.btn.btn-secondary:hover {
        background: #f3f2f1 !important;
    }

svg.svg-inline--fa.fa-plus.fa-w-14.addParamterIcon {
    color: #00a0df !important;
}

a.carousel-control-next, a.carousel-control-prev {
    max-width: 30px !important;
}

label.d-block.mb-3.fw300.RegisterTextSquared {
    font-size: 45px !important;
    color: white !important;
    font-weight: 500 !important;
}

th.w60 {
    width: 60px !important;
}

div#LegalDisclaimer > div > label {
    display: inline;
}

i.fas.fa-users.clientsIcon {
    color: #49778D;
    background: transparent;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 18px;
    margin-right: 15px;
}

i.fas.fa-layer-group.onboardingIcon {
    color: #48458E;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 12px;
}

i.fas.fa-exchange-alt.traderIcon {
    color: #712845;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 16px;
}

i.fas.fa-handshake.partnersIcon {
    color: #438866;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
}

i.fas.fa-users-cog.MAMIcon {
    color: #91672E;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
}

i.fas.fa-laptop-code.BackofficeIcon {
    color: #1F61AD;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
}

i.fas.fa-gifts.BonusIcon {
    color: #A06162;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 15px;
}

i.fas.fa-balance-scale-left.dynamicIcon {
    color: #6B69A3;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
    font-family: "Font Awesome 5 Pro" !important;
}

i.fas.fa-chalkboard-teacher.reportsIcon {
    color: #AA6AB2;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 15px;
}

i.fas.fa-chart-pie.analyticsIcon {
    color: #236946;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 8px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 16px;
}

i.fas.fa-history.logsIcon {
    color: #A5613C;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 16px;
}

i.fas.fa-clipboard.notesIcon {
    color: #4E6D96;
    background: transparent;
    padding: 9px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 16px;
}

i.fas.fa-tasks.tasksIcon {
    color: #7E3E3E;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 16px;
}

svg.MuiSvgIcon-root.dashboardIcon {
    font-size: 24px;
}

span.dashboardIcon {
    color: #256746;
    background: transparent;
    padding-left: 7px;
    padding-right: 3px;
    padding-top: 8px;
    padding-bottom: 9px;
    border-radius: 4px;
    text-align: center !important;
    margin-right: 16px;
}

i.fas.fa-cog.portalIcons, i.fas.fa-envelope-open-text.portalIcons {
    color: #00A0DF;
    background: transparent;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 14px;
}

i.fas.fa-cog.portalIcons2, i.fas.fa-envelope-open-text.portalIcons2 {
    color: #00A0DF;
    background: transparent;
    padding: 8px;
    padding-left: 13px;
    padding-right: 10px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 14px;
}

ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
    padding: 0 0px !important;
}

/*selected menu*/
li.ant-menu-submenu.ant-menu-submenu-inline.ClientsSubMenu.ant-menu-submenu-open.ant-menu-submenu-selected {
    color: #49778D !important;
}

li.ant-menu-item.MenuItemsSub.ClientsMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.ClientsMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #49778D !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.ClientsSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #49778D !important;
}

li.ant-menu-item.MenuItemsSub.OnBoardingSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.OnBoardingSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #48458E !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.OnBoardingSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #48458E !important;
}

li.ant-menu-item.MenuItemsSub.TraderSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.TraderSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #712845 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.TraderSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #712845 !important;
}

li.ant-menu-item.MenuItemsSub.IBAdminSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.IBAdminSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #438866 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.IBAdminSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #438866 !important;
}

li.ant-menu-item.MenuItemsSub.MAMSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.MAMSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #91672E !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.MAMSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #91672E !important;
}

li.ant-menu-item.MenuItemsSub.BackOfficeSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.BackOfficeSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #1F61AD !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.BackOfficeSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #1F61AD !important;
}

li.ant-menu-item.MenuItemsSub.BonusSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.BonusSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #A06162 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.BonusSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #A06162 !important;
}

li.ant-menu-item.MenuItemsSub.DynamicMarginSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.DynamicMarginSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #6B69A3 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.DynamicMarginSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #6B69A3 !important;
}

li.ant-menu-item.MenuItemsSub.ReportsSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.ReportsSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #AA6AB2 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.ReportsSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #AA6AB2 !important;
}

li.ant-menu-item.MenuItemsSub.AnalyticsSubMenu.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.AnalyticsSubMenu.ant-menu-item-selected > a > span.titleMenu {
    color: #236946 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.AnalyticsSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #236946 !important;
}

li.ant-menu-item.LogsAdminMenuTitle.ant-menu-item-selected > a.titleMenu {
    color: #A5613C !important;
}

li.ant-menu-item.NotesMenuTitle.ant-menu-item-selected > a.titleMenu {
    color: #4E6D96 !important;
}

li.ant-menu-item.TasksMenuTitle.ant-menu-item-selected > a.titleMenu {
    color: #7E3E3E !important;
}

li.ant-menu-item.DashboardMenuTitle.ant-menu-item-selected > a.titleMenu {
    color: #256746 !important;
}

i.fas.fa-circle.circleSelected, i.fas.fa-circle.circleSelectedSettings, i.fas.fa-circle.circleSelectedClient {
    padding-left: 15px;
    color: transparent;
    font-size: 13px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected > a > span.titleMenu > i.fas.fa-circle.circleSelected {
    color: inherit;
}

li.ant-menu-item.MenuItemsSub.OnBoardingMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.OnBoardingMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #48458E !important;
}

li.ant-menu-item.MenuItemsSub.TraderMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.TraderMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #712845 !important;
}

li.ant-menu-item.MenuItemsSub.PartnersMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.PartnersMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #438866 !important;
}

li.ant-menu-item.MenuItemsSub.MAMMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.MAMMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #91672E !important;
}

li.ant-menu-item.MenuItemsSub.BackOfficeMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.BackOfficeMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #1F61AD !important;
}

li.ant-menu-item.MenuItemsSub.BonusMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.BonusMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #A06162 !important;
}

li.ant-menu-item.MenuItemsSub.DynamicMarginMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.DynamicMarginMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #6B69A3 !important;
}

li.ant-menu-item.MenuItemsSub.ReportsMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.ReportsMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #AA6AB2 !important;
}

li.ant-menu-item.MenuItemsSub.AnalyticsMenuTitle.ant-menu-item-selected, li.ant-menu-item.MenuItemsSub.AnalyticsMenuTitle.ant-menu-item-selected > a > span.titleMenu {
    color: #236946 !important;
}

.ant-menu-dark .ant-menu-submenu-selected {
    color: inherit !important;
}

i.fas.fa-chevron-double-left {
    color: #889098 !important;
    font-size: 16px !important;
    font-family: "Font Awesome 5 Pro" !important;
}

ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > li.ant-menu-item,
ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > li.ant-menu-submenu.ant-menu-submenu-vertical {
    margin-bottom: 5px !important;
}

    ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical > li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
        padding-top: 10px;
        padding-bottom: 5px;
    }

.truncate {
    /*max-width: 500px;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*client menu section*/
/*Dashboard*/
/*li.ant-menu-item.DashboardClientMenu.ant-menu-item-selected {
    background: transparent !important;
}*/

li.ant-menu-item.DashboardClientMenu.ant-menu-item-selected > a.titleMenu > span.titleMenu.titleClienMenu {
    color: #256746 !important;
}

    li.ant-menu-item.DashboardClientMenu.ant-menu-item-selected > a.titleMenu > span.titleMenu.titleClienMenu > span.dashboardIconClient > svg.MuiSvgIcon-root.DashboardClientIcon {
        color: #256746;
        font-size: 24px !important;
    }

li.ant-menu-item.DashboardClientMenu > a.titleMenu > span.titleMenu.titleClienMenu > span.dashboardIconClient > svg.MuiSvgIcon-root.DashboardClientIcon {
    font-size: 24px;
}

li.ant-menu-item.DashboardClientMenu.ant-menu-item-selected > a.titleMenu > span.titleMenu.titleClienMenu > span.dashboardIconClient > svg.MuiSvgIcon-root.DashboardClientIcon {
    color: #256746;
    font-size: 24px !important;
    margin-right: unset !important;
}

li.ant-menu-item.DashboardClientMenu > a.titleMenu > span.titleMenu.titleClienMenu > span.dashboardIconClient {
    color: #256746;
    background: transparent;
    padding-left: 7px;
    padding-right: 3px;
    padding-top: 8px;
    padding-bottom: 9px;
    border-radius: 4px;
    text-align: center !important;
    margin-right: 16px;
    margin-left: -10px;
}
/*----------------------------------------------------*/
/*My Wallet*/
li.ant-menu-item.MyWalletSubMenu.ant-menu-item-selected > a.titleMenu > span.titleMenu.titleClienMenu {
    color: #48458E !important;
}

li.ant-menu-item.MyWalletSubMenu > a.titleMenu > span.titleMenu.titleClienMenu > i.MyWalletIcon.fas.fa-wallet, i.MyWalletIcon.fas.fa-wallet {
    color: #48458E;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 12px;
    margin-left: -10px;
}

li.ant-menu-item.MyWalletSubMenu > a.titleMenu > span.titleMenu.titleClienMenu > i.MyWalletIcon.fas.fa-wallet, i.MyWalletIcon.fas.fa-wallet {
    font-size: 18px;
}
/*----------------------------------------------------*/
/*Copier*/
li.ant-menu-item.CopiersSubMenu.ant-menu-item-selected > a.titleMenu > span.titleMenu.titleClienMenu {
    color: #1F61AD !important;
}

li.ant-menu-item.CopiersSubMenu > a.titleMenu > span.titleMenu.titleClienMenu > i.CopiersIcon.fas.fa-comment-dollar, i.CopiersIcon.fas.fa-comment-dollar {
    color: #1F61AD;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
    margin-left: -10px;
}

li.ant-menu-item.CopiersSubMenu > a.titleMenu > span.titleMenu.titleClienMenu > i.CopiersIcon.fas.fa-comment-dollar, i.CopiersIcon.fas.fa-comment-dollar {
    font-size: 18px;
}
/*----------------------------------------------------*/
/*Leaderboard*/
li.ant-menu-item.LeaderBoardSubMenu.ant-menu-item-selected > a.titleMenu > span.titleMenu.titleClienMenu {
    color: #A06162 !important;
}

li.ant-menu-item.LeaderBoardSubMenu > a.titleMenu > span.titleMenu.titleClienMenu > i.LeaderBoardIcon.fas.fa-satellite-dish, i.LeaderBoardIcon.fas.fa-satellite-dish {
    color: #A06162;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 15px;
    margin-left: -10px;
}

li.ant-menu-item.LeaderBoardSubMenu > a.titleMenu > span.titleMenu.titleClienMenu > i.LeaderBoardIcon.fas.fa-satellite-dish, i.LeaderBoardIcon.fas.fa-satellite-dish {
    font-size: 18px;
}
/*-----------------------------------------------------*/
/*My Accounts*/
li.ant-menu-submenu.ant-menu-submenu-inline.MyAccountsSubMenu.ant-menu-submenu-open.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #49778D !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.MyAccountsSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #49778D !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.MyAccountsSubMenu > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu > i.MyAccountsIcon.fas.fa-user,
i.MyAccountsIcon.fas.fa-user {
    color: #49778D;
    background: transparent;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 15px;
    margin-left: -10px;
}
/*li.ant-menu-submenu.ant-menu-submenu-inline.MyAccountsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected {
    background: transparent !important;
}*/
li.ant-menu-submenu.ant-menu-submenu-inline.MyAccountsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a {
    color: #49778D !important;
}

    li.ant-menu-submenu.ant-menu-submenu-inline.MyAccountsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a > span.titleMenu.titleClienMenu > i.fas.fa-circle.circleSelectedClient {
        color: inherit !important;
    }
/*-----------------------------------------------------*/
/*Onboarding*/
li.ant-menu-submenu.ant-menu-submenu-inline.OnboardingSubMenu.ant-menu-submenu-open.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #712845 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.OnboardingSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #712845 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.OnboardingSubMenu > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu > i.OnboardingIcon.fas.fa-layer-group,
i.OnboardingIcon.fas.fa-layer-group {
    color: #712845;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 16px;
    margin-left: -10px;
}

/*li.ant-menu-submenu.ant-menu-submenu-inline.OnboardingSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected {
    background: transparent !important;
}*/

li.ant-menu-submenu.ant-menu-submenu-inline.OnboardingSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a {
    color: #712845 !important;
}

    li.ant-menu-submenu.ant-menu-submenu-inline.OnboardingSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a > span.titleMenu.titleClienMenu > i.fas.fa-circle.circleSelectedClient {
        color: inherit !important;
    }
/*-----------------------------------------------------*/
/*IB Client*/
li.ant-menu-submenu.ant-menu-submenu-inline.IBClientSubMenu.ant-menu-submenu-open.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #438866 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.IBClientSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #438866 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.IBClientSubMenu > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu > i.IBClientIcon.fas.fa-handshake,
i.IBClientIcon.fas.fa-handshake {
    color: #438866;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
    margin-left: -10px;
}

/*li.ant-menu-submenu.ant-menu-submenu-inline.IBClientSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected {
    background: transparent !important;
}*/

li.ant-menu-submenu.ant-menu-submenu-inline.IBClientSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a {
    color: #438866 !important;
}

    li.ant-menu-submenu.ant-menu-submenu-inline.IBClientSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a > span.titleMenu.titleClienMenu > i.fas.fa-circle.circleSelectedClient {
        color: inherit !important;
    }
/*-----------------------------------------------------*/
/*Portfolio*/
li.ant-menu-submenu.ant-menu-submenu-inline.PortfolioSubMenu.ant-menu-submenu-open.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #91672E !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.PortfolioSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #91672E !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.PortfolioSubMenu > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu > i.PortfolioIcon.fas.fa-folder-open,
i.PortfolioIcon.fas.fa-folder-open {
    color: #91672E;
    background: transparent;
    padding: 9px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
    margin-left: -10px;
}
/*
li.ant-menu-submenu.ant-menu-submenu-inline.PortfolioSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected {
    background: transparent !important;
}*/

li.ant-menu-submenu.ant-menu-submenu-inline.PortfolioSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a {
    color: #91672E !important;
}

    li.ant-menu-submenu.ant-menu-submenu-inline.PortfolioSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a > span.titleMenu.titleClienMenu > i.fas.fa-circle.circleSelectedClient {
        color: inherit !important;
    }
/*-----------------------------------------------------*/
/*Analytics*/
li.ant-menu-submenu.ant-menu-submenu-inline.AnalyticsSubMenu.ant-menu-submenu-open.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #6B69A3 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.AnalyticsSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #6B69A3 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.AnalyticsSubMenu > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu > i.AnalyticsIcon.fas.fa-chart-pie,
i.AnalyticsIcon.fas.fa-chart-pie {
    color: #6B69A3;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 8px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 11px;
    margin-left: -10px;
}

/*li.ant-menu-submenu.ant-menu-submenu-inline.AnalyticsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected {
    background: transparent !important;
}*/

li.ant-menu-submenu.ant-menu-submenu-inline.AnalyticsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a {
    color: #6B69A3 !important;
}

    li.ant-menu-submenu.ant-menu-submenu-inline.AnalyticsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a > span.titleMenu.titleClienMenu > i.fas.fa-circle.circleSelectedClient {
        color: inherit !important;
    }
/*-----------------------------------------------------*/
/*Logs*/
li.ant-menu-submenu.ant-menu-submenu-inline.LogsSubMenu.ant-menu-submenu-open.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #AA6AB2 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.LogsSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu {
    color: #AA6AB2 !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline.LogsSubMenu > .ant-menu-submenu-title > span > span.titleMenu.titleClienMenu > i.LogsIcon.fas.fa-history,
i.LogsIcon.fas.fa-history {
    color: #AA6AB2;
    background: transparent;
    padding: 9px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 15px;
    margin-left: -10px;
}
/*
li.ant-menu-submenu.ant-menu-submenu-inline.LogsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected {
    background: transparent !important;
}*/

li.ant-menu-submenu.ant-menu-submenu-inline.LogsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a {
    color: #AA6AB2 !important;
}

    li.ant-menu-submenu.ant-menu-submenu-inline.LogsSubMenu.ant-menu-submenu-selected > ul.ant-menu.ant-menu-sub.ant-menu-inline > li.ant-menu-item.MenuItemsSub.ant-menu-item-selected > a > span.titleMenu.titleClienMenu > i.fas.fa-circle.circleSelectedClient {
        color: inherit !important;
    }

@supports (padding-top: env(safe-area-inset-top)) {
    body {
        --safe-area-inset-top: env(safe-area-inset-top);
        height: calc(100% + var(--safe-area-inset-top));
    }
}

@media (display-mode: fullscreen) {
    body {
        height: 100%;
    }
}

@media (display-mode: standalone) {
    body {
        height: 100%;
    }
}

@supports (padding-top: constant(safe-area-inset-top)) {
    body {
        padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    }
}

@media (display-mode: fullscreen) {
    body {
        padding: 0;
    }
}

@media (display-mode: standalone) {
    body {
        padding: 0;
    }
}

span.TextOurGrey.posFixed.mt-8p.cursor-pointer.ContactusColor:hover {
    background: #e7ecf2 !important;
    padding-right: 15px;
}

.fa-paste:before, .fa-clipboard:before {
    content: "\f328" !important;
}

.organizatPrimereact.p-component, .p-component * {
    width: initial;
}

body .p-organizationchart .p-organizationchart-line-down {
    background-color: #c8c8c800;
}

.insideIBChart {
    height: calc(100vh - 140px);
    overflow: hidden !important;
    overflow-y: scroll !important;
    width: 100% !important;
    overflow-x: scroll !important;
}

span.dashboardIconClient {
    color: #256746;
    background: transparent;
    padding-top: 8px;
    padding-bottom: 9px;
    border-radius: 4px;
    text-align: center !important;
    margin-right: 16px;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Reports\$Menu,
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Analytics\$Menu {
    overflow-y: auto !important;
    top: initial !important;
    position: relative !important;
}

@media (min-height: 100px) {
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Reports\$Menu,
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Analytics\$Menu {
        max-height: 50px !important;
    }
}

@media (min-height: 200px) {
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Reports\$Menu,
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Analytics\$Menu {
        max-height: 100px !important;
    }
}

@media (min-height: 400px) and (min-width: 800) {
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Reports\$Menu,
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Analytics\$Menu {
        max-height: 200px !important;
    }
}

@media (min-height: 700px) {
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Reports\$Menu,
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Analytics\$Menu {
        max-height: 250px !important;
        top: 0 !important;
    }
}

@media (min-height: 800px) {
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Reports\$Menu,
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop > ul#\/Analytics\$Menu {
        max-height: 300px !important;
    }
}

.e-daterangepick-mob-popup-wrap > .e-daterangepicker.e-popup.e-control.e-lib.e-device.e-popup-open > .e-date-range-container {
    height: 385px !important;
}

    .e-daterangepick-mob-popup-wrap > .e-daterangepicker.e-popup.e-control.e-lib.e-device.e-popup-open > .e-date-range-container > .e-calendar-container > .e-chart-scroll-container.e-content,
    .e-daterangepick-mob-popup-wrap > .e-daterangepicker.e-popup.e-control.e-lib.e-device.e-popup-open > .e-date-range-container > .e-calendar-container > .e-content {
        min-height: 294px !important;
    }

.organizationchart-demo {
    overflow-x: auto !important;
}
span.ant-checkbox-inner {
    margin-top: 4px !important;
}

.p-multiselect-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
    display: inline !important;
}
span.dateSession.ant-calendar-picker > div > input.ant-calendar-picker-input.ant-input {
    border-radius: 2px !important;
    height: 30px !important;
    /*width: 150px;*/
}

span.ant-time-picker.timeSession > input.ant-time-picker-input {
    height: 30px !important;
    margin-top: 1px;
    /*width: 150px;*/

}
.pull-left {
    float: left !important;
}
.tradingview-widget-container {
    height: 100vh !important;
}
.mw-500 {
    max-width: 500px !important;
}
.mw100p {
    max-width: 100% !important;
}
.text-white-Override {
    color: white !important;
}
.datepickerSyncFusionResponsive {
    /*width:100% !important;*/
    /*height:100% !important;*/
    overflow:auto !important;
}
img.imagelogoGold {
    width: 100% !important;
    height: inherit !important;
    /* max-height: 21px !important; */
    max-width: 160px !important;
    line-height: 40px !important;
}
.h275 {
    height: 275px !important;
}
.mainExternalNoScroll {
    overflow: hidden !important;
}
.react-select.cursor-pointer.LargeSelect.__single-value.css-1uccc91-singleValue {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
    .react-select.cursor-pointer.LargeSelect.__single-value.css-1uccc91-singleValue:hover {
        white-space: normal;
        overflow: visible;
        max-width: none;
    }
.thinScrollVertical::-webkit-scrollbar {
    width: 3px !important;
}

.displayOnSmallScreens {
    display: block !important;
}

@media (min-width: 765px) {
    .displayOnSmallScreens {
        display: none !important;
    }
}
.w450px {
    width: 450px !important;
}
/* need to set insideMAin into position relative so it works so add Ids ones set to relative */
#IBProfCogTable_wrapper > .dt-panelfooter.clearfix {
    position: relative;
    min-height: 77px;
}

img.imagelogoLimitMarkets {
    width: 160px !important;
    height: 111px !important; 
}